// TickerMaintenance.jsx
import React, { useState, useEffect } from 'react';
import api from '../functions/api';
import { Modal, Table, Button, notification } from 'antd';
import AddTicker from './AddTicker'; // Import your AddTicker component


const TickerMaintenance = () => {
  const [records, setRecords] = useState([]);
  const [loading, setLoading] = useState(false);
  const [isAddTickerModalVisible, setIsAddTickerModalVisible] = useState(false); // State to control AddTicker modal visibility

  const showAddTickerModal = () => {
    setIsAddTickerModalVisible(true); // Function to open the AddTicker modal
  };


  const onIgnoreClick = async (record) => {
    try {
      const response = await api.post('/process_Addrequests', { requestid: record.requestid });
      notification.success({
        message: 'Success',
        description: response.data.message || 'The record has been successfully updated.',
      });
      // Remove the record from the table view by filtering it out of the records array
      setRecords(prevRecords => prevRecords.filter(r => r.requestid !== record.requestid));
    } catch (error) {
      console.error('Failed to process the request:', error);
      notification.error({
        message: 'Error',
        description: 'There was an error processing the request.',
      });
    }
  };

  useEffect(() => {
    const fetchRecords = async () => {
      setLoading(true);
      try {
        // Assuming your server expects a POST request for this API call
        const response = await api.post('/get_Addrequests');
        setRecords(response.data);
        setLoading(false);
      } catch (error) {
        console.error('Failed to fetch records:', error);
        setLoading(false);
      }
    };

    fetchRecords();
  }, []);

  const columns = [
    {
      title: 'User ID',
      dataIndex: 'userid',
      key: 'userid',
    },
    {
      title: 'Company Name',
      dataIndex: 'companyname',
      key: 'companyname',
    },
    {
      title: 'Information',
      dataIndex: 'info',
      key: 'info',
    },
    {
      title: 'New Company',
      dataIndex: 'Newcompany',
      key: 'Newcompany',
    },
    {
      title: 'Requested Ticker To Add',
      dataIndex: 'requested_ticker_to_add',
      key: 'requested_ticker_to_add',
    },
    {
        title: 'Process',
        key: 'process',
        render: (text, record) => (
          <div>
            <Button 
              onClick={() => onIgnoreClick(record)}
              type="default"
              style={{ marginBottom: "8px" }} // Add some space between the buttons
            >
              Ignore
            </Button>
            <br />
            <Button 
              onClick={showAddTickerModal}
              type="primary"
            >
              Add
            </Button>
          </div>
        ),
      },

  ];

  return (
    <>
      <Table
        columns={columns}
        dataSource={records}
        loading={loading}
        rowKey="requestid"
      />
      <Modal
        title="Add Ticker"
        visible={isAddTickerModalVisible}
        onCancel={() => setIsAddTickerModalVisible(false)}
        footer={null} // Remove default footer buttons
      >
        <AddTicker closeModal={() => setIsAddTickerModalVisible(false)} />
      </Modal>
    </>
  );
};

export default TickerMaintenance;
