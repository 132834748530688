// src/redux/reducers/etfReducer.js
import { FETCH_ETF_SUCCESS, FETCH_ETF_FAILURE, CLEAR_ETF_DATA } from '../actions/etfActions';

const initialState = {
  etfData: [],
  distinctCurrencies: [],
  distinctCategories: [],
  distinctIssuers: [],
  error: null,
};

const etfReducer = (state = initialState, action) => {
  // console.log('Action Received: ', action);
  switch (action.type) {
    case FETCH_ETF_SUCCESS:
      console.log('Updating state with ETF data');
      return {
        ...state,
        etfData: action.payload.etfData,
        distinctCurrencies: action.payload.distinctCurrencies,
        distinctCategories: action.payload.distinctCategories,
        distinctIssuers: action.payload.distinctIssuers,
        error: null,
      };
    case FETCH_ETF_FAILURE:
      return {
        ...state,
        etfData: [],
        distinctCurrencies: [],
        distinctCategories: [],
        distinctIssuers: [],
        error: action.payload,
      };
    case CLEAR_ETF_DATA:
      return initialState;
    default:
      return state;
  }
};

export default etfReducer;


// // src/redux/reducers/etfReducer.js
// import { FETCH_ETF_SUCCESS, FETCH_ETF_FAILURE } from '../actions/etfActions';

// const initialState = {
//   etfData: [],
//   distinctCurrencies: [],
//   distinctCategories: [],
//   distinctIssuers: [],
//   error: null,
// };

// const etfReducer = (state = initialState, action) => {
//   console.log('Action Received: ', action);
//   switch (action.type) {
//     case FETCH_ETF_SUCCESS:
//       console.log('Updating state with ETF data');
//       return {
//         ...state,
//         etfData: action.payload.etfData,
//         distinctCurrencies: action.payload.distinctCurrencies,
//         distinctCategories: action.payload.distinctCategories,
//         distinctIssuers: action.payload.distinctIssuers,
//         error: null,
//       };
//     case FETCH_ETF_FAILURE:
//       return {
//         ...state,
//         etfData: [],
//         distinctCurrencies: [],
//         distinctCategories: [],
//         distinctIssuers: [],
//         error: action.payload,
//       };
//     default:
//       return state;
//   }
// };

// export default etfReducer;


// // src/redux/reducers/etfReducer.js

// // src/redux/reducers/etfReducer.js
// import { FETCH_ETF_SUCCESS, FETCH_ETF_FAILURE } from '../actions/etfActions';

// const initialState = {
//   etfData: [],
//   error: null,
// };

// const etfReducer = (state = initialState, action) => {
//   console.log('Action Received: ', action);
//   switch (action.type) {
//     case FETCH_ETF_SUCCESS:
//       console.log('Updating state with ETF data');
//       return {
//         ...state,
//         etfData: action.payload,
//         error: null,
//       };
//     case FETCH_ETF_FAILURE:
//       return {
//         ...state,
//         etfData: [],
//         error: action.payload,
//       };
//     default:
//       return state;
//   }
// };

// export default etfReducer;



