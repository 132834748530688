
import React, { useState, useEffect, useRef } from 'react';
import { fetchTableData } from '../functions/TableData';
import { Table, Tooltip } from 'antd';
import ModalFlex from './ModalFlex';
import SimpleExternalLinkNewWindow from '../functions/LinkFlex';

import '../index.css';
import '../CustomGridLayout.css';


const columnSlice = 3;
const rowslice = 3;
const tableSize = 'small';

function CustomTableForGridLayout({
  gridTitle,
  api,
  columnTitles,
  gridColumn1link,
  modalVisible,
  toggleModalVisible,
  fetchDataAndOpenModal,
  onDragHandleMouseDown, // Add this prop
}) {
    // const CustomHeaderWithDragHandle = () => (
    //   <div className="header">
    //     <span className="title">{gridTitle}</span>
    //     <span
    //       className="drag-handle" // Add a className for styling the drag handle
         
    //       onMouseDown={(e) => {
    //           isDraggingRef.current = true;
    //           document.body.classList.add('dragging');
    //           onDragHandleMouseDown(e);
    //         }}
    //     >
    //       &#x2630; {/* Unicode character for a 'drag handle' icon */}
    //     </span>
    //   </div>
    // );
    
  const [dataSource, setDataSource] = useState([]);
  const [fetched, setFetched] = useState(false);
  const isDraggingRef = useRef(false);

  function truncateText(text, maxLength) {
    if (text.length > maxLength) {
      return text.substring(0, maxLength) + '...';
    }
    return text;
  }

  const columns = columnTitles.map((title, index) => {
    const columnObj = {
      title,
      dataIndex: index.toString(),
      key: title,
    };
  
    if (index === 0 && gridColumn1link) {
      columnObj.render = (text, record) => {
        const displayText = truncateText(text, maxLengths[index]);
        const url = gridColumn1link === "search" ? "#!" : gridColumn1link;
  
        if (gridColumn1link === "search") {
          return (
            <Tooltip title={text}>
              <span
                className="link-value"
                onClick={(e) => handleTitleClick(e, displayText, gridColumn1link)}
              >
                {displayText}
              </span>
            </Tooltip>
          );
        } else {
          return (
            <Tooltip title={text}>
              <SimpleExternalLinkNewWindow
                clickedValue={displayText}
                className="link-value"
                url={url}
                onClick={(e) => handleTitleClick(e, displayText, gridColumn1link)}
              />
            </Tooltip>
          );
        }
      };
    }
  
    return columnObj;
  });

  useEffect(() => {
    if (!fetched) {
      fetchTableData(api).then((data) => {
        
        const receivedDataSource = data.slice(0);

        setDataSource(receivedDataSource);
        // console.log('New dataSource:', receivedDataSource);
        setFetched(true);
      });
    }
  }, [api, fetched, columns]);


  
  

  const handleModalCancel = () => {toggleModalVisible(); };


  const handleShowModalFlex = (e) => {
    // Only open the ModalFlex if the click target is the custom header
    if (e.target.classList.contains('custom-header')) {
      toggleModalVisible();
    }
  };
    
  const firstRowDataSource = dataSource.slice(0, rowslice);

  const customHeader = () => (
    <div className="custom-header-container">
      <Tooltip
        placement="topLeft"
        title={gridTitle}
        overlayClassName="custom-tooltip"
        overlayStyle={{ backgroundColor: '#1677ff' }}
        color="#1677ff"
        mouseEnterDelay={1.5}
      >
        <div
          onPointerDown={(e) => handleShowModalFlex(e)}
          // onPointerDown={handleShowModalFlex}
          className="custom-header"
          colSpan="3"
        >
          {truncateText(gridTitle, maxLengths[0])}
        </div>
      </Tooltip>
      <span
        className="drag-handle"
        onMouseDown={(e) => {
          isDraggingRef.current = true;
          document.body.classList.add('dragging');
          onDragHandleMouseDown(e);
        }}
      >
        &#x2630;
      </span>
    </div>
  );
  
  
  const maxLengths = [20, 20, 10];
  const columnWidths = ['15%', '50%', '35%'];


  const handleTitleClick = (e, displayText, gridColumn1link) => {
    e.stopPropagation();
  
    if (gridColumn1link === "search") {
      e.preventDefault();
  
      if (modalVisible) {
        toggleModalVisible(); // Close the old modal only if it's visible
      }
  
      fetchDataAndOpenModal(displayText);
    }
  };
  
   
  const gridColumns = columns.slice(0, columnSlice).map((column, index) => {
    if (index === 0) {
      return {
        dataIndex: index.toString(),
        title: customHeader,
        width: columnWidths[index],
        colSpan: 3,
        className: 'custom-table-row',
  
        render: (text, record) => {
          const displayText = truncateText(record[index], maxLengths[index]);
          const url = gridColumn1link === "search" ? "!#" : gridColumn1link;
  
          if (gridColumn1link === "search") {
            return (
              <Tooltip title={text}>
                <span
                  className="link-value"
                  onClick={(e) => handleTitleClick(e, displayText, gridColumn1link)}
                >
                  {displayText}
                </span>
              </Tooltip>
            );
          } else {
            return (
              <Tooltip title={text}>
                <SimpleExternalLinkNewWindow
                  clickedValue={displayText}
                  className="link-value"
                  url={url}
                  onClick={(e) => handleTitleClick(e, displayText, gridColumn1link)}
                />
              </Tooltip>
            );
          }
        },
      }; 
    } else {
      return {
        dataIndex: index.toString(),
        title: '', //Empty title for the other columns
        className: 'no-wrap custom-table-row',
        width: columnWidths[index],
        colSpan: 0, //Hide the other columns
        render: (text, record) => truncateText(record[index], maxLengths[index]),
      };
    }
  });
  
    
  return (
    <>
      {columns.length > 0 && (
        <Table
          dataSource={firstRowDataSource}
          columns={gridColumns}
          bordered={true}
          pagination={false}
          size={tableSize}
          className="custom-grid-table"
          onRow={(record) => {
         
              return {
               onClick: () => {
                // console.log('Row clicked:', record);
                 },
                 };
  }}
        />
      )}
      {modalVisible && (
      <ModalFlex
        open={modalVisible}
        onCancel={handleModalCancel}
        title={gridTitle}
        columns={columns}
        dataSource={dataSource}
      />
      )}
    </>
  );
}

export default CustomTableForGridLayout;
