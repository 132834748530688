// fetch_storePortfolioData.js
import api from '../functions/api';
import getUserId from '../functions/getUserId';

export default async function fetchFavoritesData(userId, token) {
  const response = await api.get(`/favorites_view/${userId}`, {
    headers: { Authorization: `Bearer ${token}` }
  });

  // console.log("RRRRRRRRRResponse.data in fetch_storeFAVORITESData: ", response.data);

  return response.data;
}
