import { Modal, Form, Input } from 'antd';
import React, { useState } from 'react';
import api from '../functions/api';
// import getUserId from '../functions/getUserId';
// import { message } from 'antd';


const Broker = ({ showBrokerModal, onClose, userId }) => {
// console.log("user ID passed to Broker.jsx", userId);
  const [form] = Form.useForm();
  const [submitting, setSubmitting] = useState(false);
  const token = localStorage.getItem('token');
//   console.log("Broker.jsx invoked");

  const handleOk = () => {
    form
      .validateFields()
      .then((values) => {
        setSubmitting(true);
        
        // Include userId in the form values sent to the server
        const valuesWithUserId = { ...values, userId };
  
        // send the form values to the backend
        if (!token) {
          console.error('Token not found');
          return;
        }
        api.post('/validate_DeGiro_credentials', valuesWithUserId, {
            headers: {
              Authorization: `Bearer ${token}`,
            },
        })
        
       
          .then(response => {
            // handle the response
            console.log(response.data);
          })
          .catch(error => {
            console.error('Error:', error);
          });
  
        form.resetFields();
        setSubmitting(false);
        onClose();
      })
      .catch((info) => {
        console.log('Validate Failed:', info);
      });
  };

  return (
    <Modal
      open={showBrokerModal}
      
      title="Please provide your De Giro broker credentials"
      onCancel={onClose}
      onOk={handleOk}
      confirmLoading={submitting}
    >
      <Form
        form={form}
        layout="vertical"
        name="broker_form"
      >
        <Form.Item
          name="username"
          label="Username"
          rules={[{ required: true, message: 'Please input your username!' }]}
        >
          <Input />
        </Form.Item>
        <Form.Item
          name="password"
          label="Password"
          rules={[{ required: true, message: 'Please input your password!' }]}
        >
          <Input.Password />
        </Form.Item>
      </Form>
    </Modal>
  );
};

export default Broker;

