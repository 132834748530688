// PortfolioAlertsPane.jsx
import React from 'react';
import { Table } from 'antd';

const PortfolioAlertsPane = ({ alerts }) => {
  const formatDate = (epoch) => {
    return new Date(epoch * 1000).toLocaleDateString("en-US"); // Adjust the locale as needed
  };

  const columns = [
    {
      title: 'Date',
      dataIndex: 'epoch',
      key: 'date',
      render: epoch => formatDate(epoch),
    },
    {
        title: 'Ticker',
        dataIndex: 'ticker',
        key: 'ticker',
      },


    {
      title: 'T0 Value',
      dataIndex: 't0value',
      key: 't0value',
    },
    {
      title: 'Tnow Value',
      dataIndex: 'tnowvalue',
      key: 'tnowvalue',
    },
    {
      title: 'Alert Value',
      dataIndex: 'alertvalue',
      key: 'alertvalue',
    },
    {
      title: 'Alert Type',
      dataIndex: 'alert_type',
      key: 'alert_type',
    }
  ];

  return <Table dataSource={alerts} columns={columns} pagination={false} />;
};

export default PortfolioAlertsPane;
