///Timestamp.js
import React, { useState, useEffect } from 'react';

function CurrentDateTime() {
    const [currentDateTime, setCurrentDateTime] = useState('');
  
    useEffect(() => {
      const intervalId = setInterval(() => {
        const now = new Date();
        const formattedDateTime = `${now.toLocaleDateString()} ${now.toLocaleTimeString()}`;
        setCurrentDateTime(formattedDateTime);
      }, 1000);
  
      return () => clearInterval(intervalId);
    }, []);
  
    return currentDateTime  }
  
  export default CurrentDateTime;