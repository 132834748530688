//DataAlertPreferences.jsx
import React, { useEffect, useState, useImperativeHandle, forwardRef } from 'react';
import { useSelector } from 'react-redux';
import { Checkbox, Button } from 'antd';
import styles from '../DataAlertPreferences.module.css'; // Adjust the path as needed
import api from '../functions/api';
import DatafieldThreshold from './Datafield_Threshold';

const DataAlertPreferences = forwardRef(({ preferencesByCategory, userId }, ref) => {
    
    const [isModalVisible, setIsModalVisible] = useState(false);
    const [currentThreshold, setCurrentThreshold] = useState(null);
    const [selectedDataFieldId, setSelectedDataFieldId] = useState(null);
    const [selectedPreferences, setSelectedPreferences] = useState({});
    const [currentCategory, setCurrentCategory] = useState(null);


    const showModal = (dataFieldId, categoryName, threshold) => {
        const parameter = parametersData.DP_dataset_json.find(p => p.ID === dataFieldId);
        
        // Check if 'Values' exist or if 'Negative' and 'Positive' are numbers and not equal.
        if (parameter && (parameter.Values || (!isNaN(parameter.Negative) && !isNaN(parameter.Positive) && parameter.Negative !== parameter.Positive))) {
            setSelectedDataFieldId(dataFieldId);
            setCurrentCategory(categoryName);
            setCurrentThreshold(selectedPreferences[dataFieldId]?.[categoryName]?.DF_threshold || threshold);
            setIsModalVisible(true);
        } else {
            // Optionally, provide feedback to the user that setting a threshold is not available
            alert("No threshold values available for this data field.");
        }
        console.log(`showModal - dataFieldId: ${dataFieldId}, categoryName: ${categoryName}, threshold: ${threshold}`);
    };
    
    const handleModalClose = () => {
        setIsModalVisible(false);
    };

    const handleSaveThreshold = (categoryId, newThreshold) => {
        setSelectedPreferences(prev => {
            const updated = { ...prev };
            if (!updated[selectedDataFieldId]) {
                updated[selectedDataFieldId] = {};
            }
            updated[selectedDataFieldId][categoryId] = {
                ...updated[selectedDataFieldId][categoryId],
                isSelected: true,
                DF_threshold: newThreshold,
            };
            return updated;
        });
    };
     
    const categoryOptions = useSelector(state =>
        ['All Stock Categories'].concat(
            state.portfolio.data.category_options
                .filter(opt => opt[0] !== 'Undefined')
                .map(opt => opt[0])
        )
    );

    // Access DP_dataset_json from Redux store
    const parametersData = useSelector(state => state?.portfolio?.parametersData);
    // console.log('Parameters Data:', parametersData);
  
    // Function to get the display text for negative and positive values
    const getDisplayValues = (dataFieldId) => {
        if (parametersData && Array.isArray(parametersData.DP_dataset_json)) {
            const parameter = parametersData.DP_dataset_json.find(p => p.ID === dataFieldId);
    
            if (parameter) {
                return (
                    <>
                        <span style={{ color: 'red' }}>neg: {parameter.Negative || 'N/A'}</span>
                        <span style={{ color: 'green' }}> pos: {parameter.Positive || 'N/A'}</span>
                    </>
                );
            }
        }
        return <span>neg: N/A pos: N/A</span>;
    };
    
    

    const datafieldAlertPrefs = useSelector(state => state.portfolio.data.datafield_alert_prefs);


    useEffect(() => {
        const newUserPrefs = {};
    
        datafieldAlertPrefs.forEach(pref => {
            const dataFieldId = pref.DataFieldID;
            const categoryId = pref.catid.toString(); // Ensure this is a string if your keys are strings
            const categoryName = pref.CategoryName;
    
            if (!newUserPrefs[dataFieldId]) {
                newUserPrefs[dataFieldId] = {};
            }
    
            // Set isSelected to true for any retrieved record
            newUserPrefs[dataFieldId][categoryName] = {
                isSelected: true,
                DF_threshold: pref.DF_threshold, // DF_threshold could be null, but isSelected will be true regardless
            };
        });
    
        setSelectedPreferences(newUserPrefs);
    }, [datafieldAlertPrefs]);
    
    
    const handleCheckboxChange = (dataFieldId, categoryId, isChecked) => {
        setSelectedPreferences(prev => {
            const newPreferences = { ...prev };
            const categoryPreferences = newPreferences[dataFieldId] || {};
    
            if (categoryId === 'all') {
                // If 'All Stock Categories' is changed, update all categories
                categoryOptions.forEach(category => {
                    categoryPreferences[category] = {
                        isSelected: isChecked,
                        DF_threshold: categoryPreferences[category]?.DF_threshold || null
                    };
                });
            } else {
                // Update only the specific category
                categoryPreferences[categoryId] = {
                    isSelected: isChecked,
                    DF_threshold: categoryPreferences[categoryId]?.DF_threshold || null
                };
            }
    
            newPreferences[dataFieldId] = categoryPreferences;
            
            // Log the entire new state for the data field
            console.log(`After handleCheckboxChange - Full State for DataFieldID ${dataFieldId}:`, newPreferences[dataFieldId]);
            
            return newPreferences;
        });
    };
    
    
    
    
    useEffect(() => {
        console.log("Re-render after selectedPreferences update:", selectedPreferences);
      }, [selectedPreferences]);
      


    const savePPreferences = async () => {
    console.log('Attempting to save data alert preferences');
    
    if (!userId) {
        console.error('No user ID found, user must be logged in to save preferences.');
        return;
    }
    
    console.log('Selected Preferences at save:', JSON.stringify(selectedPreferences, null, 2));

    const dataToSend = Object.entries(selectedPreferences).reduce((acc, [dataFieldId, categories]) => {
        Object.entries(categories).forEach(([categoryId, { isSelected, DF_threshold }]) => {
            if (isSelected) {
                acc.push({
                    UserID: userId,
                    DataFieldID: dataFieldId,
                    catid: categoryId,
                    isSelected: isSelected,
                    DF_threshold: DF_threshold || null // Send null if there's no threshold
                });
            }
        });
        return acc;
    }, []);

    console.log(`Payload to send: ${JSON.stringify(dataToSend, null, 2)}`);
    console.log('savePPreferences - final selectedPreferences before sending:', selectedPreferences);
    console.log('savePPreferences - payload to send:', dataToSend);
    

    try {
        const response = await api.post(`/save_field_alert_prefs/${userId}`, dataToSend);
        if (response.status === 200) {
            console.log('Preferences saved successfully');
        } else {
            console.error('Failed to save preferences with status:', response.status);
        }
    } catch (error) {
        console.error('Error saving preferences:', error);
    }
};

    useImperativeHandle(ref, () => ({
        savePPreferences
    }));
    
    return (
        <div className={styles.preferencesContainer}>
            <Button onClick={savePPreferences} type="primary" className={styles.saveButton}>
                Save
            </Button>
            {Object.entries(preferencesByCategory).map(([categoryName, preferences]) => (
            <table key={categoryName} className={styles.categoryTable}>
                <thead>
                    <tr>
                        <th>Data Field</th>
                        <th>All Stock Categories</th>
                        {categoryOptions.slice(1).map(category => (
                            <th key={category}>{category}</th>
                        ))}
                    </tr>
                </thead>
        <tbody>
            {preferences.map(preference => {
                const alertPref = datafieldAlertPrefs.find(ap => ap.DataFieldID === preference.dataFieldId);
                const isCategorySelected = selectedPreferences[preference.dataFieldId]?.['All Stock Categories'];
                const thresholdText = (selectedPreferences[preference.dataFieldId]?.DF_threshold !== undefined)
                        ? `T: ${selectedPreferences[preference.dataFieldId].DF_threshold}`
                        : (alertPref && alertPref.DF_threshold !== null) ? `T: ${alertPref.DF_threshold}` : 'T: N/A';

                return (
                    <tr key={preference.dataFieldId}>
                        <td>
                            {preference.dataField}
                            <div className={styles.additionalInfo}>
                                {getDisplayValues(preference.dataFieldId)}
                            </div>
                        </td>
                        <td className={styles.checkboxCell}>
                            <Checkbox
                                checked={isCategorySelected || false}
                                
                                onChange={
                                    e => handleCheckboxChange(preference.dataFieldId, 'all', e.target.checked)}
                            
                            />
                            {isCategorySelected && (
                                <div 
                                    className={styles.thresholdText}
                                    onClick={() => showModal(preference.dataFieldId, alertPref ? alertPref.DF_threshold : null)}
                                    style={{ cursor: 'pointer' }}
                                >
                                    {alertPref ? `T: ${alertPref.DF_threshold}` : 'T: N/A'}
                                    {/* {thresholdText} */}
                                </div>
                            )}
                        </td>
                        {categoryOptions.slice(1).map(category => {
                                const categoryData = selectedPreferences[preference.dataFieldId]?.[category];
                                const thresholdText = categoryData?.isSelected ? `T: ${categoryData.DF_threshold || 'N/A'}` : 'T: N/A';
                                const isIndividualCategorySelected = categoryData?.isSelected;
                                

                                return (
                                    <td className={styles.checkboxCell} key={category}>
                                        <Checkbox
                                            checked={isIndividualCategorySelected || false}
                                            onChange={e => handleCheckboxChange(preference.dataFieldId, category, e.target.checked)}
                                        />
                                        {isIndividualCategorySelected && (
                                            <div 
                                                className={styles.thresholdText}
                                                onClick={() => showModal(preference.dataFieldId, category, categoryData?.DF_threshold)}
                                                style={{ cursor: 'pointer' }}
                                            >
                                                {thresholdText}
                                            </div>
                                        )}
                                    </td>
                                );
                            })}

                    </tr>
                );
            })}
        </tbody>
    </table>
))}

            <Button onClick={savePPreferences} type="primary" className={styles.saveButton}>
                Save
            </Button>
            <DatafieldThreshold
                visible={isModalVisible}
                onClose={handleModalClose}
                onSave={handleSaveThreshold}
                currentThreshold={currentThreshold}
                selectedDataFieldId={selectedDataFieldId}
                currentCategory={currentCategory} 
                parametersData={parametersData}
            />
        </div>
    );
    
    
});

export default DataAlertPreferences;


