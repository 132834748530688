// ESG_ETFDetailsContent.jsx
// ESG_ETFDetailsContent.jsx

import React from 'react';
import ESG_Ticker_List from './ESG_Ticker_List'; // Import the ESG_Ticker_List component
import ESG_ETF_Headlines from './ESG_ETF_Headlines'; // Import the new ESG_ETF_Headlines component

function ESG_ETFDetailsContent({ etfData, etfEsgHighlights }) {
  console.log("ESG_ETFDetailsContent triggered with etfData: ", etfData);
  console.log("ESG_ETFDetailsContent triggered with etfEsgHighlights: ", etfEsgHighlights);

  // Extract ETFs_found_in_not_found_dataset from etfData
  const ETFsNotFound = etfData.ETFs_found_in_not_found_dataset || [];
  
  // Extract UnknownThingsFound from etfData
  const unknownData = etfData.UnknownThingsFound || [];

  return (
    <div id="ESG_ETFDetailsContent">
      {/* Display the ESG headlines */}
      <ESG_ETF_Headlines etfEsgHighlights={etfEsgHighlights} />

      <h3>ESG Highlights of ETF Holdings</h3>
      {/* Pass the holdings data and additional datasets to the ESG_Ticker_List component */}
      <ESG_Ticker_List
        tickersData={etfData.holdings}
        notFoundTickersData={ETFsNotFound}  // Pass the ETFs not found dataset
        unknownData={unknownData} // Pass the UnknownThingsFound dataset
      />
    </div>
  );
}

export default ESG_ETFDetailsContent;

