// authReducer.js

const authInitialState = {
  isLoggedIn: false,
  userId: null,
  userName: null,
};

const authReducer = (state = authInitialState, action) => {

  // console.log('Action received:', action);


  switch (action.type) {
    case 'CLEAR_USER_DATA':
      return authInitialState;

      case 'LOGIN':
          return {
              ...state,
              isLoggedIn: true,
              userId: action.payload.ID,
              userName: action.payload.userName,
          };
      case 'LOGOUT':
        localStorage.removeItem('token');
          return {
              ...state,
              isLoggedIn: false,
              userId: null,
              userName: null,
          };
      default:
          return state;
  }
};

  
  export default authReducer;
  