/// FavoritesView.jsx
import React, { useState, useEffect } from 'react';
import { Button, message, Tooltip, Tabs } from 'antd'; // import the message component
import FavoritesModal from './FavoritesModal'; // Fix import
import api from '../functions/api';
import getUserId from '../functions/getUserId';
import ThreeTablesModal from "./SearchResultModal";

const FavoritesView = ({ disabled }) => {
  const [favvisible, setFavVisible] = useState(false);
  const [favoritesData, setFavoritesData] = useState([]); // Fix undefined state
  const [tableColumns, setTableColumns] = useState([]);
  const token = localStorage.getItem('token');
  const userId = getUserId(); // Get the current user ID
  const [fieldMetadata, setFieldMetadata] = useState({});
  const [performanceData, setPerformanceData] = useState({});
  const [bperformanceData, setBerformanceData] = useState({});


  const fetchFavoritesData = async (userId) => {
    try {
      const response = await api.get(`/favorites_view/${userId}`, {
        headers: { Authorization: `Bearer ${token}` }
      });
  
      // console.log("HTTP response:", response);
      
      // data is now a property of response.data
      const data = response.data.data;
      const pdata= response.data.performance_data;
      const bpdata= response.data.benchmark_performance;
      // console.log("FavoritePERFORMANCEdata from favorites view:", pdata);
      // console.log("BenchmarkPERFORMANCEdata from favorites view:", bpdata);
      setPerformanceData(pdata);
      setBerformanceData(bpdata);
      // metadata is now a property of response.data
      const metadata = response.data.metadata;
      setFieldMetadata(response.data.metadata);
      // console.log("Fieldmetadata:", fieldMetadata)


      // console.log("metadata:", metadata);
  
      const fixedColumns = [
        { title: 'Ticker', dataIndex: 'Ticker', key: 'Ticker' },
        { title: 'Company', dataIndex: 'Company', key: 'Company' },
      ];
  
      const dynamicColumns = Object.keys(metadata).map((key) => {
        // Exclude the fixed columns and the id column.
        if (!fixedColumns.find((column) => column.key === key) && key !== 'id') {
          return {
            title: (
              <Tooltip 
                title={metadata[key].Tooltip}
                overlayClassName="custom-tooltip"
                overlayStyle={{ backgroundColor: '#1677ff' }}
                color="#1677ff"
                mouseEnterDelay={1.5}
              >
                {metadata[key].Label}
              </Tooltip>
            ),
            // title: metadata[key].Label,
            dataIndex: `${key}.Value`, // Access the 'Value' key in the nested object.
            key: key,
            render: 
            (text, record) => 
            <Tooltip 
            title={metadata[key].Tooltip}
            overlayClassName="custom-tooltip"
            overlayStyle={{ backgroundColor: '#1677ff' }}
            color="#1677ff"
            mouseEnterDelay={1.5}
            >
            {/* <Tooltip title={metadata[key].Tooltip}> */}
            {record[key].Value}</Tooltip>,

            // render: (text, record) => <Tooltip title={metadata[key].Tooltip}>{record[key][key].Value}</Tooltip>,
          };
        }
        return null;
      }).filter(column => column !== null);
  
      setFavoritesData(data);
      setTableColumns([...fixedColumns, ...dynamicColumns]);
  
      return data;
    } catch (error) {
      console.error("Error fetching data:", error);
      return [];
    }
  };
  
  
  const handleButtonClick = async () => {
    const token = localStorage.getItem('token');
    // console.log("1 favvisible:",favvisible);
    // console.log("usertoken:",token); 
    if (token) {
      const fetchedData = await fetchFavoritesData(userId);  // Wait for the fetch to complete
      if (fetchedData.length === 0) {  // Check the fetched data directly
        message.info('You have no favourite stocks selected yet', 10); // Display a message when there are no favorites
        setFavVisible(true); 
        // console.log("2 favvisible:",favvisible);// Display the modal even when there are no favorites
      }
    } else {
      // Show the message when the user is not logged in
      message.warning('You need to log in to start using the Favorites functionality');
    }
  };
  

  useEffect(() => {
    if (favoritesData.length > 0) {
      setFavVisible(true);
    }
  }, [favoritesData]);

  return (
    <>
    
    <Tooltip title="Select favorites and create custom tables. Define and receive alerts on your Favorites." mouseEnterDelay={1.5} placement="bottom">
      <Button 
      disabled={disabled}
      type="primary" onClick={handleButtonClick}>
        Favorites
      </Button>
      </Tooltip>
      <FavoritesModal
        favvisible={favvisible}
        onClose={() => setFavVisible(false)}
        favoritesData={favoritesData}
        fixedColumns={tableColumns.slice(0, 2)}
        tableColumns={tableColumns}
        userId={userId}
        fieldMetadata={fieldMetadata}
        pdata={performanceData}
        bpdata={bperformanceData}
      />
      
    </>
  );
  
};

export default FavoritesView;
