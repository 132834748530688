// AddRequest.jsx

import React, { useState } from 'react';
import { Modal, Button, Input, message } from 'antd';
import api from '../functions/api';
import getUserId from '../functions/getUserId';

const AddRequest = ({ searchValue, handleClearMessage, visible }) => {
  const [additionalInfo, setAdditionalInfo] = useState('');

  const handleInputChange = (event) => {
    setAdditionalInfo(event.target.value);
  };

  const requestToAdd = async () => {
    try {
      const response = await api.post('/add_request', {
        userid: getUserId(),
        companyname: searchValue,
        info: additionalInfo
      });

      if (response.data.message === 'Request added successfully') {
        message.success('Request submitted successfully');
        handleClearMessage();
        setAdditionalInfo('');
      } else {
        message.error('There was an error submitting your request');
      }
    } catch (error) {
      console.error('Error submitting request:', error);
      message.error('There was an error submitting your request');
    }
  };

  return (
    <Modal
      visible={visible}
      title={`Unrecognized Stock Name: "${searchValue}"`}
      onCancel={handleClearMessage}
      footer={[
        <Button key="back" onClick={handleClearMessage}>
          Close
        </Button>,
        <Button key="submit" type="primary" onClick={requestToAdd}>
          Request to add
        </Button>,
      ]}
    >
      <p>We do not recognize this name, but we can try to find it for you and add it to our database.</p>
      <p>You can add some information about the stock in the form below, like a website or a description.</p>
      <Input 
        placeholder="Add additional info about the stock here..." 
        onChange={handleInputChange}
        value={additionalInfo}
      />
    </Modal>
  );
};

export default AddRequest;


// // AddRequest.jsx
// import React, { useState } from 'react';
// import { Button, Modal, Input, message } from 'antd';
// import api from '../functions/api';
// import getUserId from '../functions/getUserId';

// const AddRequest = ({ searchValue, handleClearMessage }) => {
//   const [additionalInfo, setAdditionalInfo] = useState('');

//   const handleInputChange = (e) => {
//     setAdditionalInfo(e.target.value);
//   };

//   const requestToAdd = async () => {
//     try {
//       const response = await api.post('/add_request', {
//         userid: getUserId(),  // Ensure you have a getUserId function
//         companyname: searchValue,
//         info: additionalInfo
//       });

//       if (response.data.message === 'Request added successfully') {
//         message.success('Request submitted successfully');
//         handleClearMessage();
//         setAdditionalInfo('');
//       } else {
//         message.error('There was an error submitting your request');
//       }
//     } catch (error) {
//       console.error('Error submitting request:', error);
//       message.error('There was an error submitting your request');
//     }
//   };

//   return (
//     <Modal
//       visible={true}
//       title={`Unrecognized Stock Name: "${searchValue}"`}
//       onCancel={handleClearMessage}
//       footer={[
//         <Button key="back" onClick={handleClearMessage}>
//           Close
//         </Button>,
//         <Button key="submit" type="primary" onClick={requestToAdd}>
//           Request to add
//         </Button>,
//       ]}
//     >
//       <p>We do not recognize this name, but we can try to find it for you and add it to our database.</p>
//       <p>You can add some information about the stock in the form below, like a website or a description.</p>
//       <Input 
//         placeholder="Add additional info about the stock here..." 
//         onChange={handleInputChange}
//         value={additionalInfo}
//       />
//     </Modal>
//   );
// };

// export default AddRequest;
