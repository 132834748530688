// api.js

import axios from 'axios';

const api = axios.create({
  baseURL: process.env.REACT_APP_API_BASE_URL, // here you use the environment variable
});

api.interceptors.request.use((config) => {
  if (!config.auth) {
  // if (!config.noAuth) {
    const token = localStorage.getItem('token');
    // console.log("Token from localStorage:", token); // Debugging line
    config.headers.Authorization = token ? `Bearer ${token}` : '';
  }
  return config;
});

api.interceptors.response.use(
  response => response,
  error => {
    console.log('API request error:', error); // Debugging line
    return Promise.reject(error);
  }
);


export default api;

