//getUserId


import jwtDecode from 'jwt-decode';

const getUserId = () => {
  const token = localStorage.getItem('token');
  if (!token) return null;

  try {
    const decodedToken = jwtDecode(token);
    // console.log(decodedToken);

    // console.log("userId from getUserId:", decodedToken.userId);
    return decodedToken.userId;
  } catch (e) {
    console.error("Invalid token:", e);
    return null;
  }
};

export default getUserId;
