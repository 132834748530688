import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { Modal, Button, Collapse, Table } from 'antd';
import { getColorForValue } from '../functions/ESG_ColorForValue';
import '../css/ESG_MainTable.css'; // Import custom CSS
import ESG_Check_Explainer_Rating_Agencies from './ESG_Check_Explainer_Rating_Agencies';  

const { Panel } = Collapse;

function ESG_Check_Explainer_Sources() {
  const [isExplainerVisible, setIsExplainerVisible] = useState(false);
  const dataparametersSet = useSelector(state => state.portfolio?.parametersData?.DP_dataset_json);

  // console.log("dataparametersSet: ", dataparametersSet);

  const showExplainer = () => {
    setIsExplainerVisible(true);
  };

  const handleExplainerOk = () => {
    setIsExplainerVisible(false);
  };

  const handleExplainerCancel = () => {
    setIsExplainerVisible(false);
  };

  // Filter and sort data
  const groupedData = Array.isArray(dataparametersSet)
    ? dataparametersSet
        .filter(
          (item) =>
            item.esg_detail_order !== null &&
            item.esg_detail_order !== undefined &&
            item.esg_detail_order !== '0.00'
        )
        .sort((a, b) => parseFloat(a.esg_detail_order) - parseFloat(b.esg_detail_order))
        .reduce((result, item) => {
          (result[item.source] = result[item.source] || []).push(item);
          return result;
        }, {})
    : {};

  // Flatten the grouped and sorted data
  const flattenedData = Object.entries(groupedData).flatMap(([source, items]) => {
    return items.map((item, index) => ({
      key: `${source}-${index}`,
      ...item,
    }));
  });

  // Define table columns with conditional coloring
  const columns = [
    {
      title: 'ESG datafield',
      dataIndex: 'Label',
      key: 'Label',
    },
    {
      title: 'Source',
      dataIndex: 'source',
      key: 'source',
    },
    {
      title: 'Description',
      dataIndex: 'Tooltip',
      key: 'Tooltip',
    },

    {
        title: 'Green',
        dataIndex: 'Green',
        key: 'Green',
        render: (value, record) => {
          if (value === null || value === undefined) {
            return <span>{value}</span>;
          }
        
          const dataFieldMapping = dataparametersSet.reduce((acc, item) => {
            acc[item.DataField] = item;
            return acc;
          }, {});
        
          // Extract the first value if there are multiple parts separated by commas
          const firstValue = value.split(',')[0].trim();
        
          const adjustedValue = firstValue.includes('-') ? firstValue.split('-')[0].trim() : firstValue;
          const color = getColorForValue(record.DataField, adjustedValue, dataFieldMapping);
          return <span className={`cell-${color}`}>{value}</span>;
        },
        
      },
      {
        title: 'Light Green',
        dataIndex: 'Lightgreen',
        key: 'Lightgreen',
        render: (value, record) => {
          if (value === null || value === undefined) {
            return <span>{value}</span>;
          }
        
          const dataFieldMapping = dataparametersSet.reduce((acc, item) => {
            acc[item.DataField] = item;
            return acc;
          }, {});
        
          // Extract the first value if there are multiple parts separated by commas
          const firstValue = value.split(',')[0].trim();
        
          const adjustedValue = firstValue.includes('-') ? firstValue.split('-')[0].trim() : firstValue;
          const color = getColorForValue(record.DataField, adjustedValue, dataFieldMapping);
          return <span className={`cell-${color}`}>{value}</span>;
        },
        
      },
      {
        title: 'Yellow',
        dataIndex: 'Yellow',
        key: 'Yellow',
        render: (value, record) => {
          if (value === null || value === undefined) {
            return <span>{value}</span>;
          }
        
          const dataFieldMapping = dataparametersSet.reduce((acc, item) => {
            acc[item.DataField] = item;
            return acc;
          }, {});
        
          // Extract the first value if there are multiple parts separated by commas
          const firstValue = value.split(',')[0].trim();
        
          const adjustedValue = firstValue.includes('-') ? firstValue.split('-')[0].trim() : firstValue;
          const color = getColorForValue(record.DataField, adjustedValue, dataFieldMapping);
          return <span className={`cell-${color}`}>{value}</span>;
        },
        
      },
      {
        title: 'Orange',
        dataIndex: 'Orange',
        key: 'Orange',
        render: (value, record) => {
          if (value === null || value === undefined) {
            return <span>{value}</span>;
          }
        
          const dataFieldMapping = dataparametersSet.reduce((acc, item) => {
            acc[item.DataField] = item;
            return acc;
          }, {});
        
          // Extract the first value if there are multiple parts separated by commas
          const firstValue = value.split(',')[0].trim();
        
          const adjustedValue = firstValue.includes('-') ? firstValue.split('-')[0].trim() : firstValue;
          const color = getColorForValue(record.DataField, adjustedValue, dataFieldMapping);
          return <span className={`cell-${color}`}>{value}</span>;
        },
        
      },
      {
        title: 'Red',
        dataIndex: 'Red',
        key: 'Red',
        render: (value, record) => {
          if (value === null || value === undefined) {
            return <span>{value}</span>;
          }
        
          const dataFieldMapping = dataparametersSet.reduce((acc, item) => {
            acc[item.DataField] = item;
            return acc;
          }, {});
        
          // Extract the first value if there are multiple parts separated by commas
          const firstValue = value.split(',')[0].trim();
        
          const adjustedValue = firstValue.includes('-') ? firstValue.split('-')[0].trim() : firstValue;
          const color = getColorForValue(record.DataField, adjustedValue, dataFieldMapping);
          return <span className={`cell-${color}`}>{value}</span>;
        },
        
      },
      
      
   
  ];

  return (
    <>
      {/* // Centrally align the explainer link */}
      <div style={{ textAlign: 'center', marginBottom: '20px' }}>
        <a href="#" onClick={showExplainer}>
          Explainer: ESG ratings
        </a>
      </div>

      <Modal
        title="ESG Ratings Explained"
        open={isExplainerVisible}
        onOk={handleExplainerOk}
        onCancel={handleExplainerCancel}
        width="80%"
        footer={[
          <Button key="back" onClick={handleExplainerCancel}>
            Close
          </Button>,
          <Button key="submit" type="primary" onClick={handleExplainerOk}>
            OK
          </Button>,
        ]}
      >
        <Collapse>
          <Panel header="What are ESG Ratings?" key="1">
            <div>
              <p>
                ESG (Environmental, Social, and Governance) ratings assess the sustainability and ethical impact of a company or a fund. These ratings help investors understand how a company is managing risks related to environmental, social, and governance factors.
              </p>
              <h4>Environmental Factors</h4>
              <p>
                Environmental factors consider a company’s impact on the planet, including carbon emissions, waste management, resource efficiency, and energy use.
              </p>
              <h4>Social Factors</h4>
              <p>
                Social factors assess how a company manages relationships with employees, suppliers, customers, and communities. This includes labor practices, product safety, data privacy, and community relations.
              </p>
              <h4>Governance Factors</h4>
              <p>
                Governance factors evaluate a company’s leadership, executive pay, audits, internal controls, shareholder rights, and transparency.
              </p>
            </div>
          </Panel>
          <Panel header="Rating agencies" key="2">
            <ESG_Check_Explainer_Rating_Agencies />  {/* Render the new component */}
          </Panel>
          <Panel header="Rating datafields & values" key="3">
            <Table
              columns={columns}
              dataSource={flattenedData}
              pagination={false}
              rowKey="key"
            />
          </Panel>
        </Collapse>
      </Modal>
    </>
  );
}

export default ESG_Check_Explainer_Sources;
