//BrokerAccessModal.jsx

import React, { useState, useEffect } from 'react';
import { Form, Input, Button, Spin, message, Modal } from 'antd';
import api from '../functions/api';
import getUserId from '../functions/getUserId';
import { useSelector, useDispatch } from 'react-redux';
import { getPortfolioData, getFavoritesData } from '../redux/reducers/portfolioReducer';
import useLocalStorage from '../functions/useLocalStorage';

const BrokerAccessModal = ({ isVisible, onClose }) => {
    const [selectedBroker, setSelectedBroker] = useState(null);
    const [isSaxoAvailable, setIsSaxoAvailable] = useState(false);
    const [isDeGiroAvailable, setIsDeGiroAvailable] = useState(false);
    const [authorizationTrigger, setAuthorizationTrigger] = useState(false);
    const dispatch = useDispatch();
    const [token, setToken] = useLocalStorage('token', null);

    useEffect(() => {
        console.log("isVisible:", isVisible, "selectedBroker:", selectedBroker);
        if (isVisible || selectedBroker|| authorizationTrigger) {
            const userId = getUserId();
            console.log("brokercheckavailable use effect run")
            api.post('/Broker_checkifauthorisationavailable', { userId })
               .then(response => {
                     console.log("Broker check if authorisation available response:", response.data);
                   setIsSaxoAvailable(response.data.saxo);
                   setIsDeGiroAvailable(response.data.degiro);
                   if (authorizationTrigger) setAuthorizationTrigger(false);
               })
               .catch(error => {
                   console.error("Failed to check broker authorization:", error);
               });
        }
    }, [isVisible, selectedBroker, authorizationTrigger]);
    

    const handleBrokerSelection = (broker) => {
        console.log("handleBrokerSelection called with:", broker);
        // setAuthorizationTrigger(true);
        setSelectedBroker(broker);
    };

    const renderLoginForm = () => {
        switch (selectedBroker) {
            case 'DeGiro':
                return <DeGiroLoginForm dispatch={dispatch} token={token} />;
            case 'Saxo':
                return <SaxoLoginForm dispatch={dispatch} token={token} setAuthorizationTrigger={setAuthorizationTrigger} isSaxoAvailable={isSaxoAvailable} />;
            default:
                return null;
        }
    };
    
    

    return (
        <Modal
            title="Select Your Broker"
            open={isVisible} 
            onCancel={onClose}
            footer={[
                <Button key="cancel" onClick={onClose}>
                    Cancel
                </Button>,
                <Button key="ok" type="primary" onClick={onClose}>
                    OK
                </Button>,
               
            ]}
             >
              <Button disabled={isDeGiroAvailable} onClick={() => handleBrokerSelection('DeGiro')}>DeGiro</Button>
            <Button disabled={isSaxoAvailable} onClick={() => handleBrokerSelection('Saxo')}>Saxo</Button>
            <p>if inactive than according to our database you already have authorized the application to your broker account</p>
            {/* <Button onClick={() => handleBrokerSelection('DeGiro')}>DeGiro</Button>
            <Button onClick={() => handleBrokerSelection('Saxo')}>Saxo</Button> */}
            {renderLoginForm()}
        </Modal>

    );
};

const DeGiroLoginForm = ({ dispatch, token }) => {
    return (
        <Form>
            <h3>DeGiro Login</h3>
            <p>under construction</p>
            <Form.Item name="username" rules={[{ required: true, message: 'Please input your Username!' }]}>
                <Input placeholder="Username" />
            </Form.Item>
            <Form.Item name="password" rules={[{ required: true, message: 'Please input your Password!' }]}>
                <Input.Password placeholder="Password" />
            </Form.Item>
            <Form.Item>
                <Button type="primary" htmlType="submit">
                    Authorize
                </Button>
            </Form.Item>
        </Form>
    );
};

const SaxoLoginForm = ({ dispatch, token, setAuthorizationTrigger, isSaxoAvailable }) => {

// const SaxoLoginForm = ({ setAuthorizationTrigger }) => {
    const [loading, setLoading] = useState(false);
    const handleSaxoAuthorization = async (values) => {
        
        try {
            
            setLoading(true);
            const userId = getUserId();
            const { username, password } = values;
            console.log("Saxo authorisation running")
            const response = await api.post('/Initial_Saxo_Authorisation', { userId, username, password });
            
            if (response.data) {
                message.success("Authorization successful");
                setAuthorizationTrigger(true);
                const token = localStorage.getItem('token');
                console.log("Dispatch with token:", token);
                dispatch(getPortfolioData(token));
            } else {
                message.error("Authorization failed");
            }
            
        } catch (error) {
            message.error("Error during authorization: " + error.message);
        } finally {
            setLoading(false);
        }
    };
    

    return (
        <Spin spinning={loading}>
        <Form onFinish={handleSaxoAuthorization}>
            <h3>Saxo Account Authorisation</h3>
            <p>When submitting your credentials for your Saxo Go account, the application will log on to your account. Then this application will be authorized to fetch your portfoliodata. At any given time you can disable that authorization within your Saxo Go account. Your password credential will be encrypted for storage</p>
            <Form.Item name="username" rules={[{ required: true, message: 'Please input your Username!' }]}>
                <Input placeholder="Username" />
            </Form.Item>
            <Form.Item name="password" rules={[{ required: true, message: 'Please input your Password!' }]}>
                <Input.Password placeholder="Password" />
            </Form.Item>
            <Form.Item>
            <Button type="primary" htmlType="submit" disabled={isSaxoAvailable}>
                {/* <Button type="primary" htmlType="submit"> */}
                    Authorize
                </Button>
            </Form.Item>
        </Form>
        {isSaxoAvailable && (
                <p>
                    You have authorized the application to your Saxo account. You can view your Saxo portfolio with the <strong>Portfolio</strong> button.
                </p>
            )}
        </Spin>
    );
};


export default BrokerAccessModal;

