/// REACT store.js
import { configureStore, combineReducers } from '@reduxjs/toolkit';
import { persistStore, persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage'; // Use local storage
import authReducer from './reducers/authReducer';
import widgetReducer from './reducers/widgetReducer';
import demoModeReducer from './reducers/demoModeReducer';
import debugModeReducer from './reducers/debugModeReducer';
import portfolioReducer, { getPortfolioData, getDataParameters, getPortfolioESGData, getFavoritesESGData, setFilteredTickers, setFilteredTickersF, favoritesReducer  } from './reducers/portfolioReducer';
import etfReducer from './reducers/etfReducer';
import { fetchEtfData } from './actions/etfActions'; // Import fetchEtfData
// import pfalertReducer from './reducers/pfalertReducer';

// Redux Persist Configuration
const persistConfig = {
  key: 'root',
  storage,
};

const rootReducer = combineReducers({
  etf: etfReducer,
  portfolio: portfolioReducer,
  auth: authReducer,
  favorites: favoritesReducer,
  widget: widgetReducer,
  // pfalert: pfalertReducer,
  demoMode: demoModeReducer,
  debugMode: debugModeReducer
});

const persistedReducer = persistReducer(persistConfig, rootReducer);

const store = configureStore({
  reducer: persistedReducer,
  middleware: (getDefaultMiddleware) => getDefaultMiddleware({
    serializableCheck: false,
  }),
});

const persistor = persistStore(store);

// store.subscribe(() => console.log('Current state:', store.getState()));

export { getPortfolioData, getDataParameters, getPortfolioESGData, getFavoritesESGData, setFilteredTickers, setFilteredTickersF, fetchEtfData, persistor };

export default store;


