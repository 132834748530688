//  Alerts.jsx
import React, { useState, useEffect } from 'react';
import { Modal, Button, Tabs } from 'antd';
import api from '../functions/api';
import getUserId from '../functions/getUserId';
import PortfolioAlertsPane from './PortfolioAlertsPane';
import MotleyFoolAlertsPane from './MotleyFoolAlertsPane'; // Make sure this component is created

const { TabPane } = Tabs;

const Alerts = ({ disabled }) => {
  const [isVisible, setIsVisible] = useState(false);
  const [portfolioAlerts, setPortfolioAlerts] = useState([]);
  const [motleyFoolAlerts, setMotleyFoolAlerts] = useState([]);

  const showModal = () => {
    setIsVisible(true);
  };

  const handleClose = () => {
    setIsVisible(false);
  };

  const fetchPortfolioAlerts = async () => {
    const userId = getUserId();
    try {
      const response = await api.post('/get_alerts_archive', { userId });
      setPortfolioAlerts(response.data);
    } catch (error) {
      console.error('Error fetching portfolio alerts:', error);
    }
  };

  const fetchMotleyFoolAlerts = async () => {
    const userId = getUserId();
    try {
      const response = await api.post('/get_MF_data', { userId });
      setMotleyFoolAlerts(response.data);
    } catch (error) {
      console.error('Error fetching motley fool alerts:', error);
    }
  };

  useEffect(() => {
    if (isVisible) {
      fetchPortfolioAlerts();
      fetchMotleyFoolAlerts();
    }
  }, [isVisible]);

  return (
    <>
      <Button type="primary" onClick={showModal} disabled={disabled}>
        Alerts
      </Button>
      <Modal
        title="Alerts"
        visible={isVisible}
        onCancel={handleClose}
        width={800}
        footer={[
          <Button key="ok" type="primary" onClick={handleClose}>
            OK
          </Button>,
        ]}
      >
        <Tabs defaultActiveKey="1">
          <TabPane tab="Portfolio Alerts" key="1">
            <PortfolioAlertsPane alerts={portfolioAlerts} />
          </TabPane>
          <TabPane tab="Motley Fool Alerts" key="2">
            <MotleyFoolAlertsPane alerts={motleyFoolAlerts} />
          </TabPane>
        </Tabs>
      </Modal>
    </>
  );
};

export default Alerts;
