
import React, { useState, useEffect } from 'react';
import { useDropzone } from 'react-dropzone';
import { Spin, message, Button } from 'antd';
import api from '../functions/api';
import { useSelector } from 'react-redux';
import SearchBox2 from '../functions/Search_Generic.js';
import SearchBoxETF from '../functions/Search_Generic_ETF.js';
import ESG_Check_Explainer_Sources from './ESG_Check_Explainer.jsx';
import PdfModal from './PdfModal';  // Import the PdfModal component


function ESG_Input({ 
  setIsLoading, 
  setDataSet, 
  setMetadata, 
  isLoading, 
  setNotFoundDataset, 
  setHoldingsData, 
  setResponseData, 
  setInputType, 
  setEtfEsgHighlights, 
  setETFsFoundInNotFoundDataset, 
  setUnknownThingsFoundInNotFoundDataset 
}) {
  
  const dataParameters = useSelector((state) => state.portfolio.dataParameters);
  const [stockSummary, setStockSummary] = useState({});
  const [etfSummaries, setEtfSummaries] = useState({});
  const [isPdfModalVisible, setPdfModalVisible] = useState(false); // State for modal visibility
  const [currentPdf, setCurrentPdf] = useState(''); // State for current PDF file
  const [pdfTitle, setPdfTitle] = useState(''); // State for the PDF title

  const [isStockLoading, setIsStockLoading] = useState(false);
  const [isEtfLoading, setIsEtfLoading] = useState(false);


  // Function to open the PDF modal
  const showPdfModal = (pdfFile, title) => {
    console.log('Showing PDF Modal for:', pdfFile);  // Debug log
    setCurrentPdf(pdfFile);
    setPdfTitle(title);
    setPdfModalVisible(true);
  };

  const handleSelectedETFTicker = async (ticker, type) => {
    if (!ticker) return;
    console.log("Selected Ticker:", ticker);

    setIsEtfLoading(true);
    // setIsLoading(true);  // Set loading to true at the start
    setDataSet([]);
    setInputType(type);
    setMetadata([]);
    setNotFoundDataset([]);
    setHoldingsData([]);
    setResponseData({});
    setStockSummary({});
    setEtfSummaries({});
    setETFsFoundInNotFoundDataset([]);  
    setUnknownThingsFoundInNotFoundDataset([]); 
  
    try {
      const response = await api.post(`/single_ETF_esg_view?ticker=${ticker}`, null, {
        headers: {
          'Content-Type': 'application/json',
        },
        noAuth: true,
      });
  
      console.log("Response from single ETF/ESG api ", response);

      const etfData = response.data.esg_data_holdings.holdings; 
      const metadata = response.data.etf_name; 
      const ETFsFound = response.data.ETFs_found_in_not_found_dataset;  
      const UnknownThingsFound = response.data.Unknown_things_found_in_not_found_dataset;  

      console.log("ETFsFound IN ESG_INPUT", ETFsFound );

      setDataSet(etfData); 
      setMetadata(metadata); 
      setResponseData(response.data);
      setETFsFoundInNotFoundDataset(ETFsFound);  
      setUnknownThingsFoundInNotFoundDataset(UnknownThingsFound);  

      const Etf_Esg_Highlights = {
        total_all_green_lightgreen: response.data.esg_data_holdings.total_all_green_lightgreen,
        total_with_complete_data: response.data.esg_data_holdings.total_with_complete_data,
        total_with_issues: response.data.esg_data_holdings.total_with_issues,
        total_with_overall_ratings_complete: response.data.esg_data_holdings.total_with_overall_ratings_complete,
        total_with_overall_ratings_green_lightgreen: response.data.esg_data_holdings.total_with_overall_ratings_green_lightgreen,
        total_with_ratings_not_green_lightgreen: response.data.esg_data_holdings.total_with_ratings_not_green_lightgreen,
        total_with_any_param_issues: response.data.esg_data_holdings.total_with_any_param_issues,
        total_with_red_issues_in_ratings: response.data.esg_data_holdings.total_with_red_issues_in_ratings,
        total_with_red_issues_in_any_param: response.data.esg_data_holdings.total_with_red_issues_in_any_param,
        percent_complete_data: response.data.esg_data_holdings.percent_complete_data,
        percent_all_green_lightgreen: response.data.esg_data_holdings.percent_all_green_lightgreen,
        percent_with_issues: response.data.esg_data_holdings.percent_with_issues,
        percent_overall_ratings_complete: response.data.esg_data_holdings.percent_overall_ratings_complete,
        percent_overall_ratings_green_lightgreen: response.data.esg_data_holdings.percent_overall_ratings_green_lightgreen,
        percent_ratings_not_green_lightgreen: response.data.esg_data_holdings.percent_ratings_not_green_lightgreen,
        percent_any_param_issue: response.data.esg_data_holdings.percent_any_param_issue,
        percent_red_issue_in_ratings: response.data.esg_data_holdings.percent_red_issue_in_ratings,
        percent_red_issue_any_param: response.data.esg_data_holdings.percent_red_issue_any_param,
        total_holding_percent: response.data.esg_data_holdings.total_holding_percent
      };

      setEtfEsgHighlights({ ...Etf_Esg_Highlights });

    } catch (error) {
      if (error.response) {
        message.error(error.response.data.result, 2.5);
      } else {
        console.log("Error:", error);
        message.error('An unknown error occurred', 2.5);
      }
    } finally {
      setIsEtfLoading(false);
      // setIsLoading(false);  // Set loading to false at the end
    }
  };

  const handleSelectedTicker = async (ticker, type) => {
    if (!ticker) return;
    console.log("Selected Ticker:", ticker);
    
    setIsStockLoading(true);
    // setIsLoading(true);  // Set loading to true at the start
    setDataSet([]);
    setInputType(type);
    setMetadata([]);
    setNotFoundDataset([]);
    setHoldingsData([]);
    setResponseData({});
    setStockSummary({});
    setEtfSummaries({});

    try {
      const response = await api.post(`/single_ticker_esg_view?ticker=${ticker}`, null, {
        headers: {
          'Content-Type': 'application/json',
        },
        noAuth: true,
      });

      console.log('received response for single ticker esg api search', response);
      console.log('Received ESG dataset:', response.data.data);
      console.log('Received metadata:', response.data.metadata);
      setDataSet(response.data.data);
      setMetadata(response.data.metadata);
      setResponseData(response.data);

    } catch (error) {
      if (error.response) {
        message.error(error.response.data.result, 2.5);
      } else {
        console.log("Error:", error);
        message.error('An unknown error occurred', 2.5);
      }
    } finally {
      setIsStockLoading(false);
      // setIsLoading(false);  // Set loading to false at the end
    }
  };

  const onDropHandler = async (acceptedFiles) => {
    console.log('Setting isLoading to true');
    setIsLoading(true); 

    setInputType('file'); 

    setDataSet([]);
    setMetadata([]);
    setNotFoundDataset([]);
    setHoldingsData([]);
    setResponseData({});
    setStockSummary({});
    setEtfSummaries({});

    const file = acceptedFiles[0];
    const formData = new FormData();
    formData.append('file', file);

    try {
      const response = await api.post('/upload', formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      });
      setResponseData(response.data);
      setDataSet(response.data.data);
      setMetadata(response.data.metadata);
      setNotFoundDataset(response.data.not_found_dataset || []);
      setHoldingsData(response.data.esg_dataset_etfs_holdings_potential_etfs_from_not_found_dataset);

      console.log("esg_dataset_etfs_holdings_potential_etfs_from_not_found_dataset", response.data.esg_dataset_etfs_holdings_potential_etfs_from_not_found_dataset)
      console.log("response from analyze filee ", response);

      const Etf_Esg_Highlights = {
        total_all_green_lightgreen: response.data.esg_data_holdings.total_all_green_lightgreen,
        total_with_complete_data: response.data.esg_data_holdings.total_with_complete_data,
        total_with_issues: response.data.esg_data_holdings.total_with_issues,
        total_with_overall_ratings_complete: response.data.esg_data_holdings.total_with_overall_ratings_complete,
        total_with_overall_ratings_green_lightgreen: response.data.esg_data_holdings.total_with_overall_ratings_green_lightgreen,
        total_with_ratings_not_green_lightgreen: response.data.esg_data_holdings.total_with_ratings_not_green_lightgreen,
        total_with_any_param_issues: response.data.esg_data_holdings.total_with_any_param_issues,
        total_with_red_issues_in_ratings: response.data.esg_data_holdings.total_with_red_issues_in_ratings,
        total_with_red_issues_in_any_param: response.data.esg_data_holdings.total_with_red_issues_in_any_param,
        percent_complete_data: response.data.esg_data_holdings.percent_complete_data,
        percent_all_green_lightgreen: response.data.esg_data_holdings.percent_all_green_lightgreen,
        percent_with_issues: response.data.esg_data_holdings.percent_with_issues,
        percent_overall_ratings_complete: response.data.esg_data_holdings.percent_overall_ratings_complete,
        percent_overall_ratings_green_lightgreen: response.data.esg_data_holdings.percent_overall_ratings_green_lightgreen,
        percent_ratings_not_green_lightgreen: response.data.esg_data_holdings.percent_ratings_not_green_lightgreen,
        percent_any_param_issue: response.data.esg_data_holdings.percent_any_param_issue,
        percent_red_issue_in_ratings: response.data.esg_data_holdings.percent_red_issue_in_ratings,
        percent_red_issue_any_param: response.data.esg_data_holdings.percent_red_issue_any_param,
        total_holding_percent: response.data.esg_data_holdings.total_holding_percent
      };

      setEtfEsgHighlights({ ...Etf_Esg_Highlights });

    } catch (error) {
      if (error.response) {
        message.error(error.response.data.result, 2.5);
      } else {
        console.log("Error2:", error);
        // message.error('An unknown error occurred', 2.5);
      }
    } finally {
      setIsLoading(false);
    }
  };

  const { getRootProps, getInputProps } = useDropzone({ onDrop: onDropHandler });

  return (
    <>
      <p>
        Either search ESG data for a list of companies by uploading a file, Or use one of the search boxes to find ESG data for a single company or an ETF.
      </p>
  
      <div className="esg-container">
        {/* Row 1: Stock Search and Text */}
        <div className="esg-row">
          <div className="esg-column-horizontal">
            <div className="company-search">
              <p className="section-header">Check a company</p>
              <p>Enter a ticker or company name to get ESG ratings:</p>
              <SearchBox2 onSelectTicker={(ticker) => handleSelectedTicker(ticker, 'stock')} />
              {isStockLoading && (
              // {isLoading && (
                <div className="is-loading">
                  <Spin />
                  <div className="file-analyzing-message">
                    Loading data, please wait...
                  </div>
                </div>
              )}
            </div>
            <div className="company-info">
              <p>Check A single Company</p>
              {/* Show Example Button */}
              <Button 
                onClick={() => showPdfModal('/ESG_data_on_Alphabet_Inc.pdf', 'Example ESG view of a single stock')} 
                className="show-example"
              >
                Show Example
              </Button>
            </div>
          </div>
        </div>
  
        {/* Row 2: Text and ETF Search */}
        <div className="esg-row">
          <div className="esg-column-horizontal">
            <div className="etf-info">
              <p>Would you like to check an ETF?</p>
              <p>Here you can find the ETF and check the ESG ratings of its main holdings</p>
              {/* Show Example Button */}
              <Button 
                onClick={() => showPdfModal('/ESG_overview_for_ETF.pdf', 'Example ESG view of an ETF and its prime holdings')} 
                className="show-example"
              >
                Show Example
              </Button>
          
            </div>
            <div className="etf-search">
              <p className="section-header">Check an ETF</p>
              <p>Enter a ticker or company name to see ETF holdings and their ESG ratings:</p>
              <SearchBoxETF onSelectTicker={(ticker) => handleSelectedETFTicker(ticker, 'etf')} />
              {isEtfLoading && (
              // {isLoading && (
                <div className="is-loading">
                  <Spin />
                  <div className="file-analyzing-message">
                    Loading data, please wait...
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
  
        {/* Row 3: File Upload and Text */}
        <div className="esg-row">
          <div className="esg-column-horizontal">
            <div {...getRootProps({ className: 'dropzone' })}>
              <p className="section-header">Check your portfolio</p>
              <input {...getInputProps()} />
              <p>Drag & drop a file here, or click to select one</p>
              <p>Only files with .csv, .xlsx, and .xls are accepted</p>
              {isLoading && (
                <div className="is-loading">
                  <Spin />
                  <div className="file-analyzing-message">
                    File successfully uploaded. Analyzing your file and your data. This can take a minute...
                  </div>
                </div>
              )}
            </div>
            <div className="portfolio-info">
              <p>Would you check a selected list of stocks? For instance your Portfolio?</p>
              <p>Drop your file with the list and it will find the assets and their ratings for you</p>
              {/* Show Example Button */}
              <Button 
                onClick={() => showPdfModal('/ESG_data_on_uploaded_file.pdf', 'Example ESG view based on the analysis of the contents of an uploaded file')} 
                className="show-example"
              >
                Show Example
              </Button>
            </div>
          </div>
        </div>
      </div>
  
      {/* Explainer Section */}
      <ESG_Check_Explainer_Sources />

      <PdfModal
    visible={isPdfModalVisible}
    onClose={() => setPdfModalVisible(false)}
    pdfFile={currentPdf}
    title={pdfTitle}
/>


    </>
  );
  
}

export default ESG_Input;
