///index.js

import { Provider } from 'react-redux';
import { PersistGate } from 'redux-persist/integration/react';
import store, { persistor } from './redux/store'; // Update the path if necessary
import React, { useState, useEffect, useRef } from 'react';
import ReactDOM from 'react-dom/client';

import SearchBox from './functions/Search';
import TableGrid from './components/Tablegrid';
import MarketVectors from './components/MarketsVectors';
import FlexGrid from './components/FlexGrid';
import PortfolioView from './components/PortfolioView';
import FavoritesView from './components/FavoritesView';
import Filter from './components/Filter';
import ETF_screener from './components/ETF_screener'; 

import User from './components/Users'; // import User component
import Account from './components/Account';  // import Account component
import getUserId from './functions/getUserId';
import api from './functions/api';
import { message } from 'antd';
import ESGCheck from './components/ESGCheck';
import whyDidYouRender from '@welldone-software/why-did-you-render';
import AboutModal from './components/AboutModal'; 
import { useSelector, useDispatch } from 'react-redux';

import { logIn, logOut, clearUserData, clearPortfolioData, startTokenExpiryCheck  } from './redux/actions';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import PrivacyPolicy from './components/PrivacyPolicy'; // Import your Privacy Policy component
import Support from './components/Support'; // Import your Privacy Policy component
import { getPortfolioData, getFavoritesData } from './redux/reducers/portfolioReducer';
import useLocalStorage from './functions/useLocalStorage';
import Alerts from './components/Alerts';
import ESGChecker from './components/ESGChecker.jsx';

import WidgetAreaController from './functions/Widget_area_controller';
import Ask from './components/Ask';
import InvestmentGoals from './components/Investment_Goals';

if (process.env.NODE_ENV === 'development') {
  whyDidYouRender(React);
}


const rootElement = document.getElementById('root');

const MainApp = () => {

  const dispatch = useDispatch();
  const userLoggedIn = useSelector((state) => state.auth.isLoggedIn);
  // console.log("User Logged In?", userLoggedIn) // Note the state.auth.isLoggedIn based on your reducer setup.
  const demoMode = useSelector(state => state.demoMode.isDemoMode);
  const portfolioData = useSelector((state) => state.portfolio.data);
  const portfolioTopline = useSelector((state) => state.portfolio.data?.portfolio_topline);

  // Check if user is logged in and portfolio data is available
  const isPortfolioDataAvailable = userLoggedIn && portfolioTopline?.length > 0;
  // Render the Portfolio button conditionally
  const renderPortfolioButton = () => {
    if (userLoggedIn && isPortfolioDataAvailable) {
      return <PortfolioView disabled={!userLoggedIn} userId={userId} />;
    } else {
      return null; // Or you can render a disabled button or any other UI element
    }
  };

  
  const [showExpiredMessage, setShowExpiredMessage] = useState(false);

  const [isUpdatingPortfolio, setIsUpdatingPortfolio] = useState(false);
  const [portfolioUpdateMessage, setPortfolioUpdateMessage] = useState('');


  const [userId, setUserId] = useState(null);
  const [token, setToken] = useLocalStorage('token', null);
  const tokenRef = useRef(localStorage.getItem('token'));

  const updatePortfolio = async () => {
    setIsUpdatingPortfolio(true);
    setPortfolioUpdateMessage('---Your Portfolio is being updated---');
  
    try {
      const response = await api.post('/Update_Portfolio_upon_request', { Userid: getUserId() });
      setPortfolioUpdateMessage('---Portfolio Updated---');
      dispatch(getPortfolioData(token));
      dispatch(getFavoritesData(token));
      // Keep the message for 3 seconds before clearing it
      setTimeout(() => {
        setPortfolioUpdateMessage('');
        setIsUpdatingPortfolio(false);
      }, 3000); // Adjust the duration (in milliseconds) as needed
    } catch (error) {
      setPortfolioUpdateMessage('Error updating portfolio.');
      setIsUpdatingPortfolio(false);
    }
  };
  
  // useEffect(() => {
  //   // Call the API when the user logs in
  //   if (userLoggedIn) {
  //     updatePortfolio();
  //   }
  // }, [userLoggedIn]);

  useEffect(() => {
    // const intervalId = startTokenExpiryCheck(dispatch);
    const intervalId = startTokenExpiryCheck(dispatch, setShowExpiredMessage);
    
    return () => clearInterval(intervalId); // Cleanup on component unmount
  }, [dispatch]);

  return (
    <React.StrictMode>
      <Router>
        <Routes>
          <Route path="/" element={
            <div className="main-container">
              <div className="responsive-container">
                <h1>Investments Dashboard</h1>
                
                <div className="top-right-button">
                  <AboutModal />
                  <Ask disabled={!userLoggedIn}/>
                  {demoMode && <span style={{ color: 'red', fontSize: '10px', marginLeft: '10px' }}>Demo Mode</span>}
                </div>
                
                <div className="search-box-container">
                  <SearchBox onSearch={(value) => {
                    if (getUserId()) {
                      // console.log("VALUE", value);
                    }
                  }} 
                  disabled={!userLoggedIn}
                  />
                  <div className="portfolio-button">
                    <Filter disabled={!userLoggedIn} />
                  </div>
                  <div className="portfolio-button">
                    <ETF_screener  disabled={!userLoggedIn} />
                  </div>
                  <div className="portfolio-button">
                    <FavoritesView disabled={!userLoggedIn} />
                  </div>
                  <div className="portfolio-button">
                      {renderPortfolioButton()}
                    </div>
                  <div className="portfolio-button">
                      <InvestmentGoals disabled={!userLoggedIn}/> {/* Add the new Investment Goals button */}
                  </div>

                  {/* <div className="portfolio-button">
                    <PortfolioView disabled={!userLoggedIn} userId={userId} />
                  </div> */}
                  <div className="portfolio-button">
                    <Alerts disabled={!userLoggedIn} userId={userId} />
                  </div>
                  <div className="portfolio-button">
                    <ESGCheck />
                  </div>
                  <div className="user-greeting-container">
                    <Account />
                    {showExpiredMessage && !userLoggedIn && <span>Your session has expired. Please log in to continue.</span>}
                  </div>
                </div>
                <div className="table-grid-search-container">
                  Your widgets
                  <WidgetAreaController onWidgetSelect={(index) => {
                    // Handle widget selection here
                    // console.log(`Widget hotspot ${index} clicked`);
                    // Possible implementation:
                    // - Open a modal to select the widget to be placed
                    // - Place a selected widget into the chosen hotspot
                  }} />

                  {/* <MarketVectors /> */}
                  {/* <FlexGrid /> */}
                </div>
              </div>
            </div>
          } />
          {/* Other Routes */}
          <Route path="/privacy-policy" element={<PrivacyPolicy />} />
          <Route path="/support" element={<Support />} />
          <Route path="/esgcheck" element={<ESGChecker />} />
        </Routes>
      </Router>
    </React.StrictMode>
  );
  
};


ReactDOM.createRoot(rootElement).render(
  <Provider store={store}>
    <PersistGate loading={null} persistor={persistor}>
      <MainApp />
    </PersistGate>
  </Provider>
);

  