///fetchPortfolio_ESG_data.js
import api from '../functions/api';
//this function should receive a list of tickers
//it should then  call this api: @app.route('/api/pf_esg_view', methods=['POST'])
//it will get back : 'return jsonify(result="ESG data for portfolio ESG view", data=dataset, metadata=metadata), 200'

export const fetchPortfolioESGData = async (tickers) => {
    try {
      const response = await api.post('/pf_esg_view', { tickers }); // Replace with actual payload format if different
      if (response.status === 200) {
        // console.log("EEEEESSSSSSGGGGGGresponse.data ESG_data", response.data);    
        return response.data; // { result, data, metadata }
        
      }
      throw new Error('Failed to fetch ESG data');
    } catch (error) {
      console.error(error);
      return null;
    }
  };