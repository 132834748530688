// AboutModal.js
import React, { useState } from 'react';
import { Button, Modal } from 'antd';

const AboutModal = () => {
  const [isModalVisible, setIsModalVisible] = useState(false);

  const showModal = () => {
    setIsModalVisible(true);
  };

  const handleOk = () => {
    setIsModalVisible(false);
  };

  return (
    <>
      <Button type="primary" onClick={showModal}>
        What is this place about?
      </Button>
      <Modal 
      title="About This Place" 
      open={isModalVisible} 
      onOk={handleOk} 
      onCancel={handleOk}
      width={'80%'}
      >
  <p>What is this place all about?</p>

  <p>Let’s first address this ‘ESG-Check’</p>
  <p>About a growing number of stocks the ESG ratings are collected from various sources.</p>
  <p>This function enables you to analyze the viability of your portfolio or stocks in general.</p>
  <p>There is NO need to register or log in for this!!  Because, as we are all aware, we need to spend our money wisely and not only manage our finance, but also community interests.</p>
  
  <p>Then there are some functionalities that need registration and login.</p>

  <p>Not only ESG data but also traditional data on fundamentals, technicals and ratings are collected.</p>
  <p>The filter function enables you to select (groups of) stock(s) that adhere to filter criteria of your own liking.</p>
  <p>This might trigger your interest in certain stock.</p>

  <p>The Favorites function provides custom information and alert services.</p>
  <p>Create overviews based on datafields of your preference. Create alerts based on your personal tresholds.</p>

  <p>In case this is too overwhelming, you can also simply use the search functionality. If the stock of your interest is in the database, it will return all available data. Clicking on data will open a new browser tab to the source.</p>
  <p>When a stock is not found, a window will open up enabling you to request the stock added to the database. In due time your request will be fulfilled.</p>

  <p>Now, what’s inside Portfolio?</p>
  <p>For the time being, this is just for clients of De Giro. The public api is enabling the download of actual portfolio data. It will provide you Performance and ESG oversight. Moreover, it enables custom categorization into various classes of investments.  Is Microsoft as investment asset to be treated the same way as a new technology company with a non-existing PE ratio? Perhaps not.</p>
  <p>Within these various classes you can define specific alerts. Perhaps to sell, or harvest a bit, or to accumulate.</p>
  <p>And against what benchmarks? You may want to define it all yourself.</p>

  <p>When logged in, your username is the place to manage all your preferences.</p>

  <p>We do not like flipping pages. As you may have noticed, the navigation is very simple : Modals popping up and being clicked away. You will always know where you are.</p>

  <p>Costs?  What costs?</p>
  <p>Instead, we are interested in feedback to improve.</p>

  <p>Thanks for your interest in reading this.</p>
  
  <p>Stay tuned for improvements.</p>
</Modal>

    </>
  );
};

export default AboutModal;
