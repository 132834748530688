//search.js
import React, { useState, useRef, useEffect } from 'react';

import { AutoComplete, Input, Space, Button, Modal, Tooltip} from 'antd';
import { CloseCircleOutlined } from '@ant-design/icons'; // Import the CloseCircleOutlined icon
import axios from 'axios';
import ThreeTablesModal from '../components/SearchResultModal';
import getUserId from '../functions/getUserId';
import { message } from 'antd';
import api from '../functions/api';

const SearchBox = ({ disabled, onSearch }) => {
  const [searchValue, setSearchValue] = useState('');
  const [showModal, setShowModal] = useState(false);
  const [stockValue, setStockValue] = useState('');
  const [tablesData, setTablesData] = useState([]);
  const [options, setOptions] = useState([]);
  const [showMessage, setShowMessage] = useState(false);  // Add this
  const searchInputRef = useRef(null);
  const token = localStorage.getItem('token');
  const [additionalInfo, setAdditionalInfo] = useState('');
  // If not already done, consider managing the input display value separately.
// This ensures the input box reflects user typing without triggering API calls.
  const [inputDisplayValue, setInputDisplayValue] = useState('');


  const [metadata, setMetadata] = useState({});
  
  // console.log("search rendered"); 
  // console.log("disabled:", disabled); 

  const handleInputChange = (event) => {
    setAdditionalInfo(event.target.value);
  };


  const handleClear = () => {
    setSearchValue('');
    setOptions([]);
    };

  const handleSearch = async (value) => {
      // console.log('handleSearch is called');
      // console.log("Ref object:", searchInputRef.current);

      // setSearchValue(value);
      if (!value) {
        console.log('Value is empty!');
        return;
      }
      if (!token) {
        message.warning('You need to log in to start using the Search functionality');
        return;
      }
  
      // Fetch suggestions here
      try {
        const response = await api.get(`/search_suggestions?query=${value}`);
        const autoCompleteOptions = response.data.map((suggestion) => ({
          value: suggestion,
        }));
        setOptions(autoCompleteOptions);
        // console.log("Ref object:", searchInputRef.current);
        searchInputRef.current.focus();
        if (typeof onSearch === 'function') {
          onSearch(value);
        }
      } catch (error) {
        console.error('Error fetching search suggestions:', error);
      }
    };



  const handleInputSearch = async (value) => {
    // Here, handle the situation when the user hits the magnifying glass (or presses enter)
    try {
      // Call handleSelect if enter is pressed or magnifying glass is clicked
      if (value) {
        handleSelect(value);
      }
    } catch (error) {
      console.error('Error fetching stock data:', error);
    }
  };

  const handleSelect = async (value) => {
    const ticker = value.split(' - ')[0]; // Extract the ticker from the value
    setSearchValue(ticker);
    if (!ticker) {
      console.log('Ticker is empty!');
      // setShowMessage(true);
      return;
    }
    try {
    
      const baseUrl = process.env.REACT_APP_API_BASE_URL;
      const response = await api.get(`/stock_data?symbol=${ticker}`);
 

      setStockValue(response.data.stock);
      setTablesData(response.data.tables);
      // console.log('Tables data:', response.data.tables);
      setMetadata(response.data.metadata);
      setShowModal(true);
    } catch (error) {
      console.error('Error fetching stock data:', error);
      // If the request fails, show the message
      setShowMessage(true);
    }
  };

  const requestToAdd = async () => {
    try {
      const response = await api.post('/add_request', {
        userid: getUserId(),
        companyname: searchValue,
        info: additionalInfo
      });
  
      if (response.data.message === 'Request added successfully') {
        message.success('Request submitted successfully');
        handleClearMessage();
        setAdditionalInfo('');
      } else {
        message.error('There was an error submitting your request');
      }
    } catch (error) {
      console.error('Error submitting request:', error);
      message.error('There was an error submitting your request');
    }
  };
  

  const handleCloseModal = () => {
    setShowModal(false);
  };

  const handleClearMessage = () => {
    setShowMessage(false);
    setSearchValue('');
  };

  return (
    <Space direction="vertical">
    <Tooltip title="Search for stocks and retrieve data about fundamentals, technicals, ESG and more" mouseEnterDelay={1.5} placement="top">
      <AutoComplete
        options={options}
        onSelect={handleSelect}
        onSearch={handleSearch}
        value={inputDisplayValue}
        // value={searchValue} 
        disabled={disabled}
      >
      {/* {console.log('AutoComplete disabled:', disabled)} */}
    
        <Input.Search
          value={inputDisplayValue}
          onChange={(e) => setInputDisplayValue(e.target.value)}

          ref={searchInputRef}
          placeholder="ticker or stockname"
          enterButton
          onSearch={handleInputSearch} // separate onSearch function for Input.Search
          
          disabled={disabled}
          onFocus={() => {
              if (!token) {
              message.warning('You need to log in to start using the Search functionality');
                }
                }}
          suffix={
            searchValue && (
              <CloseCircleOutlined
                style={{ cursor: 'pointer' }}
                onClick={handleClear}
              />
            )
          }
        />
        
      </AutoComplete>
      </Tooltip>
      {showMessage && (
        <Modal
        open={true}
        title={`Unrecognized Stock Name: "${searchValue}"`}
        onCancel={handleClearMessage}
        footer={[
          <Button key="back" onClick={handleClearMessage}>
            Close
          </Button>,
          <Button key="submit" type="primary" onClick={requestToAdd}>
            Request to add
          </Button>,
        ]}
      >
        <p>We do not recognize this name, but we can try to find it for you and add it to our database.</p>
        <p>You can add some information about the stock in the form below, like a website or a description.</p>
        <Input 
          placeholder="Add additional info about the stock here..." 
          onChange={handleInputChange}
          value={additionalInfo}
        />
      </Modal>
      )}
      <ThreeTablesModal
        tickerValue={searchValue}
        stockValue={stockValue}
        tablesData={tablesData}
        open={showModal}
        onClose={handleCloseModal}
        userId={getUserId()}
        metadata={metadata}
      />
    </Space>
  );
 
};

export default SearchBox;

