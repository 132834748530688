////SearcResultModal.jsx
import React, { useEffect, useRef, useState } from 'react';
import { Button, Modal, Table, Checkbox, message, Tooltip } from 'antd';
import '../index.css';
import SimpleExternalLinkNewWindow from '../functions/LinkFlex';
import api from '../functions/api';
import { useSelector, useDispatch } from 'react-redux';
import SMAGraph from './SMAGraph';
import PositionDetails from './PositionDetails'; // Import the new component
import ESG_DetailsContent from './ESG_DetailsContent';
import Stockinfo_TablesContent from './Stockinfo_TablesContent';

const transpose = (matrix) => {
  return matrix[0].map((_, colIndex) => matrix.map((row) => row[colIndex]));
};

const ThreeTablesModal = ({ tickerValue, open, onClose, userId }) => {

  // console.log("in search resultmodal: ", tickerValue, open, onClose, userId)

  const demoMode = useSelector(state => state.demoMode.isDemoMode);
  const maybeBlur = (text) => demoMode ? <span className="blur-text">{text}</span> : text;
  const [transactionData, setTransactionData] = useState([]);
  const pfDataAlerts = useSelector((state) => state?.portfolio?.data?.pf_data_alert);
  const pfDataAlertForTicker = pfDataAlerts && Array.isArray(pfDataAlerts)
    ? pfDataAlerts.find(alert => alert[2].toLowerCase() === tickerValue.toLowerCase())
    : null;
  const portfolioData = useSelector((state) => state?.portfolio?.data?.portfolio) || [];
  const isTickerInPortfolio = portfolioData && Array.isArray(portfolioData)
    ? portfolioData.some((item) => {
        const itemTicker = item?.Ticker;
        return itemTicker && tickerValue && itemTicker.toLowerCase() === tickerValue.toLowerCase();
      })
    : false;
  const [isFavorite, setIsFavorite] = useState(false);
  const [favoriteChanged, setFavoriteChanged] = useState(false);
  const [stockData, setStockData] = useState(null);
  const [stockValue, setStockValue] = useState(null);  // New
  const [tablesData, setTablesData] = useState(null);  // New
  const [metadata, setMetadata] = useState(null);  // New
  const [alertsData, setAlertsData] = useState([]);  // New state variable for alerts
  const [esgDetails, setEsgDetails] = useState(null);


  const stored_pfalertData = useSelector((state) => state?.portfolio?.data?.pf_alert);
  const stored_favalertData = useSelector((state) => state?.favorites?.data?.favorites_alerts);
  // Find the specific alert for the current ticker
  const tickerAlert = stored_pfalertData ? stored_pfalertData.find(alert => alert.ticker === tickerValue) : null;
  // Assuming each entry in favorites_alerts is an array where the first element is the ticker and the second is the text
  const favAlert = stored_favalertData ? stored_favalertData.find(alert => alert.ticker === tickerValue) : null;
  // console.log("favalert", favAlert)
  const [smaGraphData, setSMAGraphData] = useState(null);
  const [isTableDataLoading, setIsTableDataLoading] = useState(true);
  const [hasValidData, setHasValidData] = useState(true);
  const DP_dataset_json = useSelector(state => state?.portfolio?.parametersData?.DP_dataset_json);

  // Filter out the data for the specific ticker value
  const tickerPortfolioData = portfolioData.find((item) => 
  item?.Ticker && tickerValue && item.Ticker.toLowerCase() === tickerValue.toLowerCase()
);

  const processTransactionData = (transactionData) => {
    return transactionData.map(transaction => ({
      date: new Date(transaction.date).toISOString().split('T')[0], // Convert to 'YYYY-MM-DD' format
      price: transaction.price,
      type: transaction.buysell,
      quantity: transaction.quantity
    }));
  };
  
  const fetchRef = useRef({ tickerValue: '', isTickerInPortfolio: false });
  const transactions = useSelector(state => state.portfolio?.data?.transactions || []);


  useEffect(() => {
    // Check if the tickerValue or isTickerInPortfolio has changed meaningfully before fetching
    if (isTickerInPortfolio &&
        (fetchRef.current.tickerValue !== tickerValue || fetchRef.current.isTickerInPortfolio !== isTickerInPortfolio)) {
      // Ensure both values are trimmed and compared in the same case (e.g., uppercase)
      const filteredTransactions = transactions.filter(transaction => 
        transaction.ticker && transaction.ticker.trim().toUpperCase() === tickerValue.trim().toUpperCase()
      );
  
      const formattedTransactionData = processTransactionData(filteredTransactions);
      setTransactionData(formattedTransactionData);
  
      // Update ref with the current values to prevent unnecessary fetches in the future
      fetchRef.current.tickerValue = tickerValue;
      fetchRef.current.isTickerInPortfolio = isTickerInPortfolio;
    }
  }, [transactions, tickerValue, isTickerInPortfolio]);
  


  useEffect(() => {
    const fetchStockData = async () => {
      if (!tickerValue) {
        // console.log('Ticker value is empty. Skipping data fetch.');
        return;
      }
  
      try {
        const stockResponse = await api.get("/stock_data", { params: { symbol: tickerValue } });
  
        if (stockResponse.status === 404) {
          // This means the ticker is not in the database
          message.error('This ticker is not yet in our database. You may submit a request to have it added.');
          setHasValidData(false);
        } else if (stockResponse.status === 200 && stockResponse.data) {
          // Proceed if data is found
          // console.log("Response ", stockResponse)
          setStockData(stockResponse.data);
          setStockValue(stockResponse.data.stock);
          setTablesData(stockResponse.data.tables);
          setMetadata(stockResponse.data.metadata);
          setAlertsData(stockResponse.data.alerts);
          setHasValidData(true);
          setEsgDetails(stockResponse.data.esgDetails);
  
          // Now fetch SMA graph data only after successfully fetching stock data
          const graphResponse = await api.post("/get_SMA_graph", { ticker: tickerValue });
          if (graphResponse.data && graphResponse.data.graph_data) {
            const { graph_data, currency } = graphResponse.data;
            const formattedData = graph_data.dates.map((date, index) => ({
              date,
              price: graph_data.prices[index],
              smaShort: graph_data.sma_short[index],
              smaLong: graph_data.sma_long[index]
            }));
  
            setSMAGraphData({ data: formattedData, currency });
          } else {
            console.error("Invalid format of response data for SMA graph", graphResponse.data);
            setSMAGraphData({ data: [], currency: 'USD' });
          }
        } else {
          // Handle other unsuccessful responses
          message.error('Failed to fetch data for the ticker.');
          setHasValidData(false);
        }
      } catch (error) {
        console.error("Error fetching stock data: ", error);
        // message.error('An error occurred while fetching data.');
        setHasValidData(false);
      }
    };
  
    fetchStockData();
  }, [tickerValue]);
  

  
  useEffect(() => {
    const checkFavorite = async () => {
      const response = await api.post(`/check_favorites/${userId}`, { ticker: tickerValue });
      setIsFavorite(response.data.isFavorite);
    };

    checkFavorite();
  }, [userId, tickerValue]);

  const handleCancel = () => {
    onClose();
  };

  const handleValueClick = async (record) => {
    console.log("fieldname ", record.originalField);
  
    try {
      const response = await api.get('/external_link', {
        params: { ticker: tickerValue, field_name: record.originalField },
      });
  
      // Check if the response contains a valid URL
      if (response.data && response.data.url) {
        window.open(response.data.url, '_blank');
      } else {
        console.warn('No valid URL returned from the API.');
      }
    } catch (error) {
      console.error('Error fetching URL:', error);
    }
  };
  
  

  const handleFavoriteChange = () => {
    setIsFavorite(!isFavorite);
    setFavoriteChanged(true);
  };

  const handleSaveFavorite = async () => {
    if (!favoriteChanged) return; 

    const response = await api.post(`/change_favorites/${userId}`, [{ ticker: tickerValue, isFavorite: isFavorite }]);
    message.success(response.data.message, 3);
    setFavoriteChanged(false); 
  };

  //
  
  // const DD_dataset_json = useSelector(state => state?.portfolio?.parametersData?.DD_dataset_json);
  const [DD_dataset_json, setDD_dataset_json] = useState([]);

useEffect(() => {
  const fetchDataDeltaForTicker = async () => {
    try {
      const response = await api.post('/Data_Delta_load_for_ticker', { ticker: tickerValue });
      if (response.status === 200) {
        setDD_dataset_json(response.data.DD_dataset_json);
      } else {
        message.error('Failed to fetch Data Delta for the ticker.');
      }
    } catch (error) {
      console.error('Error fetching DD_dataset_json:', error);
      message.error('An error occurred while fetching Data Delta.');
    }
  };

  if (tickerValue) {
    fetchDataDeltaForTicker();
  }
}, [tickerValue]);


  // console.log("DD_dataset_json!!", DD_dataset_json)

  const prepareTableData = (data, tickerValue, DD_dataset_json, DP_dataset_json) => {
    if (!data) {
      return { dataSource: [] };
    }
  
    const transposedData = transpose(data);
  
    const findLatestTrendForField = (tickerValue, field, DD_dataset_json) => {
      if (!Array.isArray(DD_dataset_json)) {
        console.log("no DD_dataset_json")
        return null;
      }
  
      const normalizedField = field.replace(/\s+/g, '_').toLowerCase();
  
      const matchingEntries = DD_dataset_json.filter(entry =>
        entry.ticker === tickerValue && entry.datafield.toLowerCase() === normalizedField
      );
  
      const sortedEntries = matchingEntries.sort((a, b) => b.epoch - a.epoch);
  
      return sortedEntries.length > 0 ? {
        trend: sortedEntries[0].trend,
        oldvalue: sortedEntries[0].oldvalue
      } : null;
    };
  
    const getLabelForField = (field, DP_dataset_json) => {
      if (!DP_dataset_json || !Array.isArray(DP_dataset_json)) {
        return field; // Return the original field if DP_dataset_json is not available
      }
    
      const normalizedField = field.replace(/\s+/g, '_').toLowerCase();
      const entry = DP_dataset_json.find(e => e.DataField.toLowerCase() === normalizedField);
    
      return entry ? entry.Label : field; // Return the label if found, otherwise return the original field
    };
    
  
    const dataSource = transposedData.map((row, index) => {
      const field = row[0].replace(/_/g, ' ');
      const label = getLabelForField(row[0], DP_dataset_json);
      let value = null;
      let trend = findLatestTrendForField(tickerValue, field, DD_dataset_json);
      let epoch = null;
  
      if (row[1] && Array.isArray(row[1])) {
        value = row[1][0] ? row[1][0].toString().replace(/_/g, ' ') : null;
        epoch = row[1][1];
      } else {
        value = row[1] ? row[1].toString().replace(/_/g, ' ') : null;
      }
  
      return {
        key: index,
        field: label,
        originalField: row[0], // Ensure originalField is stored
        // originalField: field, // Add original field here
        value: value,
        trend: trend,
        epoch: epoch,
      };
    });
    // console.log('Prepared Data Source:', dataSource);
    return { dataSource };
  };
  
  
  const tableTitle = (title) => <div className="table-title">{title.replace(/_/g, ' ')}</div>;
  
  const formatDate = (epoch) => {
    if (epoch === null) {
      return <span style={{ color: 'red', fontSize: '6px' }}>?</span>;
    }
  
    const epochDate = new Date(epoch * 1000);
    const currentDate = new Date();
    const oneDayInMs = 86400000;
  
    if (currentDate - epochDate > oneDayInMs) {
      const formattedDate = epochDate.toLocaleDateString('en-GB', {
        day: '2-digit',
        month: 'short',
        year: '2-digit'
      }).replace(/ /g, '');
      return <span style={{ color: 'red', fontSize: '6px' }}>{formattedDate.toLowerCase()}</span>;
    }
  
    return null;
  };
  
  
  return (
    <Modal
      title={
        <div>
          <Checkbox checked={isFavorite} onChange={handleFavoriteChange} />
          {`Data for ${stockValue} (ticker '${tickerValue}')`}
          {isTickerInPortfolio && <span> - Part of your Portfolio!</span>}
        </div>
      }
      open={open}
      onCancel={handleCancel}
      footer={[
        <Button className="ok-button" onClick={handleCancel}>
          OK
        </Button>,
        <Button
          className="save-button"
          type="primary"
          disabled={!favoriteChanged}
          onClick={handleSaveFavorite}
        >
          Save
        </Button>,
      ]}
      width="90%"
    >

{hasValidData ? (
      // Render the normal content if we have valid data
      <>

{ smaGraphData && <SMAGraph data={smaGraphData.data} currency={smaGraphData.currency} transactions={transactionData} /> }


       {/* Conditionally render the "Position Details" table if the ticker is in the portfolio */}
       {isTickerInPortfolio && tickerPortfolioData && (
            <div style={{ textAlign: 'center', marginTop: '20px' }}>
              <h2 style={{ margin: 0 }}>Position Details</h2>
              <PositionDetails tickerPortfolioData={tickerPortfolioData} maybeBlur={maybeBlur} />
            </div>
          )}

          {/* Render the new TablesContent component */}
          <Stockinfo_TablesContent
            tablesData={tablesData}
            tickerValue={tickerValue}
            DD_dataset_json={DD_dataset_json}
            DP_dataset_json={DP_dataset_json}
            metadata={metadata}
            handleValueClick={handleValueClick}
          />



{esgDetails && (
      <div className="esg-details">
        <h3>ESG Details</h3>
        <ESG_DetailsContent ticker={tickerValue} details={esgDetails} />
      </div>
    )}


       {/* Display pf_alert_txt if it exists for this ticker */}
      {tickerAlert && (
        <div className="portfolio-alert">
          <h3>Portfolio Alert for {tickerValue}</h3>
          <p>{tickerAlert.pf_alert_txt}</p>
        </div>
      )}

      {/* Display portfolio data alert if it exists for this ticker */}
      {pfDataAlertForTicker && (
        <div className="data-alert">
          <h3>Portfolio Data Alert for {tickerValue}</h3>
          <p>{pfDataAlertForTicker[4]}</p>
        </div>
      )}


      {favAlert && (
        <div className="alerts-container">
          <h3>Favorites Alert for {tickerValue}</h3>
          <p>{favAlert.text}</p>
        </div>
      )}  

      <div className="alerts-container">
        <h3>Recent changes!</h3>
        <ul>
          {alertsData && alertsData.map((alert, index) => (
            <li key={index}>{alert}</li>
           ))}
          </ul>
      </div>

      </>
    ) : (
      // Optionally render a message or empty state here if no valid data
      <div>No valid data available for this ticker.</div>
    )}

    </Modal>
  );
};

export default ThreeTablesModal;

