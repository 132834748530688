import React, { useState, useEffect, useCallback } from 'react';

import { Checkbox, Button, Modal, Table, Select, Input, Tooltip, Collapse } from 'antd';

import api from '../functions/api';
import getUserId from '../functions/getUserId';
import { message } from 'antd';
import { CloseOutlined } from '@ant-design/icons';
import debounce from 'lodash/debounce';
import FilterModal from './FilterModal';

const Filter = ({ disabled }) => {
    const [isModalVisible, setIsModalVisible] = useState(false);
    const [filterData, setFilterData] = useState([]);
    const [multiFilterCount, setMultiFilterCount] = useState(0);
  
    const [tableKey, setTableKey] = useState(0);
    const { Option } = Select;
    const [filteredCompanies, setFilteredCompanies] = useState([]);
    const [companiesAndTickers, setCompaniesAndTickers] = useState([]);
    const [userId, setUserId] = useState(null);
    const [favoriteCompanies, setFavorites] = useState([]);
    const delay = 500;
    const { Column } = Table;
    const { Panel } = Collapse;
    const [canAddToWidgets, setCanAddToWidgets] = useState(false);

    useEffect(() => {
      let activeDatafields = 0;
      let activeTresholds = 0;
    
      // Iterate through filterData to count active datafields and tresholds
      filterData.forEach(category => {
        category.filters.forEach(filter => {
          if (filter.sendToBackend && filter.treshold !== undefined && filter.treshold !== null) {
            activeDatafields++;
            activeTresholds++;
          }
        });
      });
    
      // Update canAddToWidgets based on the active counts
      setCanAddToWidgets(activeDatafields > 0 && activeTresholds > 0);
    }, [filterData]); // Depend on filterData so it updates whenever filterData changes
    
    
    const handleAddToWidgets = async () => {
      console.log('Adding filter to widgets...');
    
      // Prepare the payload
      let datafields = [];
      let treshold_values = [];
    
      // Collect all datafields and tresholds for which a treshold is set
      filterData.forEach((category) => {
        category.filters.forEach((filter) => {
          if (filter.sendToBackend && filter.treshold !== undefined && filter.treshold !== null) {
            datafields.push(filter.DataField);
            treshold_values.push(filter.treshold);
          }
        });
      });
    
      const userid = getUserId(); // Use the getUserId function to get the current user's ID
    
      const payload = {
        userid, // Assuming the backend expects 'userid' in the payload
        datafields,
        treshold_values,
      };
    
      console.log("userid ", userid)
      console.log("datafields", datafields)
      console.log("th_values", treshold_values)

      try {
        // const response = await api.post('/store_filter_widget', payload); // Send the prepared payload
        const response = await api.post('/store_filter_widget', {
          userid: getUserId(), // Assuming this correctly returns an integer
          datafields: datafields,
          treshold_values: treshold_values,
        });

        const data = await response.data; // Assuming `api.post` resolves with the response data directly
    
        console.log("response fron store filter widget backend", data); // Handle the response accordingly
        message.success('Widget added successfully!'); // Show success message
      } catch (error) {
        console.error('Error adding filter to widgets:', error);
        message.error('Failed to add widget. Please try again.'); // Show error message
      }
    };
    
    
    // Fetch favorites whenever user id or filtered companies change
    useEffect(() => {
        if (userId && filteredCompanies.length) {
            updateFavorites();
        }
    }, [userId, filteredCompanies]);

    const updateFavorites = async () => {
        try {
            const response = await api.post(`/favorites/${userId}`);
            setFavorites(response.data); // assuming your server responds with an array of favorite tickers
        } catch (error) {
            console.error("Failed to fetch favorites:", error);
        }
    };

    const formatFilterData = (data) => {
      const grouped = data.reduce((result, item) => {
        (result[item['Category']] = result[item['Category']] || []).push(item);
        return result;
      }, {});
  
      const formatted = Object.keys(grouped).map((category, index) => ({
        key: `group-${index}`,
        category,
        filters: grouped[category],
      }));
  
      return formatted;
    };

    const executeMultiFilter = useCallback(async () => {
        let datafields = [];
        let treshold_values = [];
      
        // Collect all datafields and tresholds for which a treshold is set
        filterData.forEach((category) => {
          category.filters.forEach((filter) => {
            if (filter.sendToBackend && filter.treshold !== undefined && filter.treshold !== null) {
              datafields.push(filter.DataField);
              treshold_values.push(filter.treshold);
            }
          });
        });
      
        // Only execute multi filter if we have at least one threshold and datafield
        if (treshold_values.length > 0 && datafields.length > 0) {
          try {
            const response = await api.post('/multi_filter_execution', {
              treshold_values,
              datafields,
            });
      
            // Add error handling for response data
            if (response.data && Array.isArray(response.data) && response.data.length === 2) {
              const [count, companiesAndTickers] = response.data;
      
              // Create a Set of favorite tickers for efficient lookup
              const favoriteTickers = new Set(favoriteCompanies);
      
              const companiesWithFavoriteInfo = companiesAndTickers.map((company) => {
                const isFavorite = company.is_favorite || favoriteCompanies.includes(company.ticker);
                // console.log(`Multi filter Ticker: ${company.ticker}, is_favorite: ${company.is_favorite}, isFavorite: ${isFavorite}`);
                return {
                  ...company,
                  is_favorite: isFavorite,
                };
              });
              
            //   console.log('Favorite Companies:', favoriteCompanies);


              setMultiFilterCount(count);
              setFilteredCompanies(companiesWithFavoriteInfo);
            //   console.log("Number of companies adhering to all thresholds: ", count);
            } else {
              throw new Error("Response data is not as expected");
            }
          } catch (error) {
            console.error("Failed to execute multi filter:", error);
          }
        } else {
          // If there are no thresholds or datafields, we can set multiFilterCount to 0
          setMultiFilterCount(0);
        }
      }, [filterData, favoriteCompanies]);
 
useEffect(() => {
        executeMultiFilter();
    }, [filterData]); // Only run when filterData changes

const showModal = async () => {
    if (!getUserId()) {
        message.warning('You need to log in to start using the Filter functions');
        return;
      }
    try {
      const response = await api.get('/show_filter_options');

       // Before formatting the data, add the 'sendToBackend' property to each filter
    const dataWithFlags = response.data.map(filter => ({
        ...filter, // This spreads the existing filter properties
        treshold: undefined,
        count: undefined,
        sendToBackend: false,
      }));

      const formattedData = formatFilterData(response.data);
      setFilterData(formattedData);
    } catch (error) {
      console.error("Failed to fetch filter options:", error);
    }
    setIsModalVisible(true);
  };

  const handleOk = () => {
    setIsModalVisible(false);
  };

  const handleCancel = () => {
    setIsModalVisible(false);
  };

  const debouncedApiCall = debounce(async (value, record, callback) => {
    try {
      const response = await api.post('/single_filter_execution', {
        treshold_value: value,
        datafield: record.DataField
      });
      const count = response.data[0];
      callback(count, null);
    } catch (error) {
      callback(null, error);
    }
  }, delay);
  
  const handleTresholdChange = (value, record) => {
    const newData = JSON.parse(JSON.stringify(filterData));
    const categoryIndex = newData.findIndex(category => category.filters.find(filter => filter.DataField === record.DataField));
    const filterIndex = newData[categoryIndex].filters.findIndex(filter => filter.DataField === record.DataField);
  
    newData[categoryIndex].filters[filterIndex].treshold = value;
    if (value !== "") {
      debouncedApiCall(value, record, (count, error) => {
        if (error) {
          console.error("Failed to get the count:", error);
        } else {
          newData[categoryIndex].filters[filterIndex].count = count;
          newData[categoryIndex].filters[filterIndex].sendToBackend = true;
        }
        setFilterData(newData);
      });
    } else {
      newData[categoryIndex].filters[filterIndex].sendToBackend = false;
      setFilterData(newData);
    }
  };
    
  const handleTresholdReset = (record) => {
    // Deep clone the data to avoid mutation issues
    const newData = JSON.parse(JSON.stringify(filterData));

    // Identify the category of the current filter
    const categoryIndex = newData.findIndex(category => category.filters.find(filter => filter.DataField === record.DataField));
    const filterIndex = newData[categoryIndex].filters.findIndex(filter => filter.DataField === record.DataField);

    // Reset the count, treshold for the filter in the state, and set sendToBackend to false
    newData[categoryIndex].filters[filterIndex].treshold = "";
    newData[categoryIndex].filters[filterIndex].count = undefined;
    newData[categoryIndex].filters[filterIndex].sendToBackend = false;
  
    setFilterData(newData);
};

  const resetAllTresholds = () => {
    // Deep clone the data to avoid mutation issues
    const newData = JSON.parse(JSON.stringify(filterData));
  
    // Reset all tresholds and counts
    newData.forEach(category => {
      category.filters.forEach(filter => {
        filter.count = undefined;
        filter.treshold = undefined;
      });
    });
  
    setFilterData(newData);
    setFilteredCompanies([]); // This will reset the filtered companies
    setMultiFilterCount(0); // Reset the count too
    setTableKey(prevKey => prevKey + 1);
  };
  
  async function checkFavorite(userId, ticker) {
    const data = { ticker }; // assuming your server expects JSON { ticker: <ticker> }
    const response = await api.post(`/check_favorites/${userId}`, data);
    return response.data.isFavorite; // assuming your server responds with JSON { isFavorite: <boolean> }
}


return (
    <>
    <Tooltip title="Apply Filters to the datafields as tresholds to select stocks" mouseEnterDelay={1.5} placement="bottom">
      <Button 
        disabled={disabled}
        type="primary" onClick={showModal}>
        Filter
      </Button>
      </Tooltip>
      <FilterModal 
        isModalVisible={isModalVisible} 
        handleOk={handleOk} 
        handleCancel={handleCancel} 
        filterData={filterData} 
        handleTresholdChange={handleTresholdChange}
        handleTresholdReset={handleTresholdReset} 
        resetAllTresholds={resetAllTresholds} 
        multiFilterCount={multiFilterCount} 
        filteredCompanies={filteredCompanies} 
        handleAddToWidgets={handleAddToWidgets}
        canAddToWidgets={canAddToWidgets}
      />
    </>
  );

};

export default Filter;

