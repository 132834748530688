// REACT widgetReducer.js

const initialState = {
    widgetPreferences: [] // Assuming the initial state structure for widget preferences
  };
  
  const widgetReducer = (state = initialState, action) => {
    switch (action.type) {
      case 'UPDATE_WIDGET_CONTENT':
        const { userId, widgetNumber, newContentId } = action.payload;
        return {
          ...state,
          widgetPreferences: state.widgetPreferences.map(preference =>
            preference.userid === userId && preference.widget_number === widgetNumber
              ? { ...preference, widgetcontentid: newContentId }
              : preference
          )
        };
  
      case 'SET_WIDGET_PREFERENCES':
        return {
          ...state,
          widgetPreferences: action.payload
        };
  
      default:
        return state;
    }
  };
  
  export default widgetReducer;
  