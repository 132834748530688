// fetch_storePortfolioData.js
import api from '../functions/api';
import getUserId from '../functions/getUserId';

export default async function fetchPortfolioData(userId, token) {
  const response = await api.get(`/portfolio_view/${userId}`, {
    headers: { Authorization: `Bearer ${token}` }
  });

  console.log("RRRRRRRRRResponse.data in fetch_storePortfolioData: ", response.data);

  return response.data;
}
