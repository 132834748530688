
//storeSize.js

import store from '../redux/store';

export function getStoreSize() {
  const state = store.getState();
  const serializedState = JSON.stringify(state);
  return serializedState.length * 2; // Each character in a JSON string is 16 bits (2 bytes)
}

  