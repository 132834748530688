import React, { useEffect, useState } from 'react';
import '../index.css';
import { Table, Collapse, Tooltip } from 'antd';

const { Panel } = Collapse;

const fetchData = async (endpoint) => {
    const baseUrl = process.env.REACT_APP_API_BASE_URL;
    const url = `${baseUrl}${endpoint}`;
  
    try {
      const response = await fetch(url);
      const data = await response.json();
      // console.log('Fetched data:', data);
      return data;
    } catch (error) {
      console.error('Error fetching data:', error);
      return [];
    }
  };
  

const processTableData = (dataArray, tableName, columnNames) => {
    const columns = columnNames.map((name, index) => ({
      title: name.replace(/_/g, ' '),
      dataIndex: `value${index + 1}`,
      key: `value${index + 1}`,
    }));
  
    const dataSource = dataArray.map((row, index) => ({
      key: index,
      ...columnNames.reduce((obj, name, i) => {
        obj[`value${i + 1}`] = row[i].replace(/_/g, ' ');
        return obj;
      }, {}),
    }));
  
    return { tableName: tableName.replace(/_/g, ' '), columns, dataSource };
  };
 

const MarketVectors = () => {
  const [marketsData, setMarketsData] = useState([]);
  const [vectorsData, setVectorsData] = useState([]);


  useEffect(() => {
    fetchData('/markets_update').then((data) =>
      setMarketsData(processTableData(data.data, data.table_name, data.columns)),
    );
    fetchData('/vectors_update').then((data) =>
      setVectorsData(processTableData(data.data, data.table_name, data.columns)),
    );
  }, []);


  const tooltipTitle = 'Selected data on markets and sentiments';



  return (
    <div className="market-vectors-container">
      <Collapse size="small">
        <Panel
          header={
            <div className="custom-element-height">
              <Tooltip
                placement="topLeft"
                title={tooltipTitle}
                overlayClassName="custom-tooltip"
                overlayStyle={{ backgroundColor: '#1677ff' }}
                color="#1677ff"
                mouseEnterDelay={1.5}
              >
                <span style={{ fontWeight: 'bold', fontSize: '10px' }}>{marketsData.tableName}</span>
                <span style={{ fontWeight: 'bold', fontSize: '10px', marginLeft: '10px' }}>{vectorsData.tableName}</span>
              </Tooltip>
            </div>
          }
          key="1"
        >
        {/* Add table components here */}
        <Table
          columns={marketsData.columns}
          dataSource={marketsData.dataSource}
          pagination={false}
          bordered
          size="small"
          style={{ display: 'inline-table', marginRight: '10px' }}
        />
        <Table
          columns={vectorsData.columns}
          dataSource={vectorsData.dataSource}
          pagination={false}
          bordered
          size="small"
          style={{ display: 'inline-table' }}
        />
      </Panel>
    </Collapse>
  </div>
);

};


export default MarketVectors;
