import React from 'react';
import { Modal, Button } from 'antd';  // Import Button from Ant Design
import { Worker, Viewer } from '@react-pdf-viewer/core';
import '@react-pdf-viewer/core/lib/styles/index.css';

const PdfModal = ({ visible, onClose, pdfFile, title }) => {
    console.log("pdfmodal triggered with file", pdfFile, title);

    return (
        <Modal
            visible={visible}
            onCancel={onClose}
            footer={[
                <Button key="ok" onClick={onClose} type="primary">
                    OK
                </Button>,
            ]}
            width="90vw"  // Set width to 90% of viewport width
            centered
            title={<strong>{title}</strong>}  // Set the title with bold text
        >
            <div style={{ height: '90vh' }}> {/* Set height to 90% of viewport height */}
                <Worker workerUrl={`https://unpkg.com/pdfjs-dist@3.11.174/build/pdf.worker.min.js`}>
                    <Viewer fileUrl={pdfFile} />
                </Worker>
            </div>
        </Modal>
    );
};

export default PdfModal;
