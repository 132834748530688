import React from 'react';

const SimpleExternalLinkNewWindow = ({ clickedValue, url, onClick }) => {
  if (url) {
    return (
      <a
        href={url}
        target="_blank"
        rel="noopener noreferrer"
        style={{ textDecoration: 'none' }}
        onClick={onClick}
      >
        {clickedValue}
      </a>
    );
  } else {
    return (
      <span
       
        onClick={() => console.log('Since the value of URL is none, no new window is opened')}
        style={{ textDecoration: 'none', cursor: 'pointer' }}
      >
        {clickedValue}
      </span>
    );
  }
};

export default SimpleExternalLinkNewWindow;

