import React from 'react';
import PieChartComponent from './PieCharts'; // Ensure this path is correct

const ESG_ETF_Headlines = ({ etfEsgHighlights }) => {

  console.log("etfEsgHighlights in ESG ETF Headlines", etfEsgHighlights);

  // Helper function to round to two decimal places
  const roundToTwoDecimals = (num) => Math.round(num * 100) / 100;

  const labelFontSize = 12;
  const titleFontSize = 12;

  // Color schemes
  const positiveColor = '#00C49F'; // Green
  const negativeColor = '#FF8042'; // Red
  const neutralColor = '#d3d3d3'; // Grey for unknown

  // Calculate the unknown part as 100% minus the total_holding_percent
  const unknownPercent = roundToTwoDecimals(100 - (etfEsgHighlights.total_holding_percent || 0) * 100);

  // Prepare the data for each pie chart, including the unknown part
  const greenLightgreenData = [
    { name: 'All Green/Lightgreen', value: roundToTwoDecimals((etfEsgHighlights.percent_all_green_lightgreen || 0) * 100) },
    { name: 'Not All Green/Lightgreen', value: roundToTwoDecimals(100 - (etfEsgHighlights.percent_all_green_lightgreen || 0) * 100 - unknownPercent) },
    { name: 'Unknown', value: unknownPercent }
  ];

  const completeData = [
    { name: 'Complete Data', value: roundToTwoDecimals((etfEsgHighlights.percent_complete_data || 0) * 100) },
    { name: 'Incomplete Data', value: roundToTwoDecimals(100 - (etfEsgHighlights.percent_complete_data || 0) * 100 - unknownPercent) },
    { name: 'Unknown', value: unknownPercent }
  ];

  const issuesData = [
    { name: 'With Issues', value: roundToTwoDecimals((etfEsgHighlights.percent_with_issues || 0) * 100) },
    { name: 'Without Issues', value: roundToTwoDecimals(100 - (etfEsgHighlights.percent_with_issues || 0) * 100 - unknownPercent) },
    { name: 'Unknown', value: unknownPercent }
  ];

  const ratingsGreenLightgreenData = [
    { name: 'Key Ratings Green/Lightgreen', value: roundToTwoDecimals((etfEsgHighlights.percent_overall_ratings_green_lightgreen || 0) * 100) },
    { name: 'Key Ratings Not Green/Lightgreen', value: roundToTwoDecimals(100 - (etfEsgHighlights.percent_overall_ratings_green_lightgreen || 0) * 100 - unknownPercent) },
    { name: 'Unknown', value: unknownPercent }
  ];

  const redIssuesInRatingsData = [
    { name: 'Red Issues in Ratings', value: roundToTwoDecimals((etfEsgHighlights.percent_red_issue_in_ratings || 0) * 100) },
    { name: 'No Red Issues in Ratings', value: roundToTwoDecimals(100 - (etfEsgHighlights.percent_red_issue_in_ratings || 0) * 100 - unknownPercent) },
    { name: 'Unknown', value: unknownPercent }
  ];

  const redIssuesInAnyParamData = [
    { name: 'Red Issue(s) in ESG parameter(s)', value: roundToTwoDecimals((etfEsgHighlights.percent_red_issue_any_param || 0) * 100) },
    { name: 'No Red Issue in parameters', value: roundToTwoDecimals(100 - (etfEsgHighlights.percent_red_issue_any_param || 0) * 100 - unknownPercent) },
    { name: 'Unknown', value: unknownPercent }
  ];

  return (
    <div>
      <h3>ETF ESG Highlights based on {roundToTwoDecimals((etfEsgHighlights.total_holding_percent || 0) * 100)}% of known holdings</h3>
      
      <h3>Total holdings % of ETF where holdings..</h3>
      <div style={{ display: 'flex', justifyContent: 'space-around', flexWrap: 'wrap' }}>

        <PieChartComponent 
          data={completeData} 
          title=".. have full ESG data available" 
          width={150} height={150} outerRadius={50} 
          titleFontSize={titleFontSize}
          labelFontSize={labelFontSize}
          colors={[positiveColor, negativeColor, neutralColor]} // Apply color scheme including neutral
        />

        <PieChartComponent 
          data={greenLightgreenData} 
          title=".. are Green/Lightgreen for full ESG dataset" 
          width={150} height={150} outerRadius={50} 
          titleFontSize={titleFontSize}
          labelFontSize={labelFontSize} 
          colors={[positiveColor, negativeColor, neutralColor]} // Apply color scheme including neutral
        />

        <PieChartComponent 
          data={ratingsGreenLightgreenData} 
          title="..have all key ratings Green/Lightgreen" 
          width={150} height={150} outerRadius={50} 
          titleFontSize={titleFontSize}
          labelFontSize={labelFontSize}
          colors={[positiveColor, negativeColor, neutralColor]} // Apply color scheme including neutral
        />

        <PieChartComponent 
          data={issuesData} 
          title="..have ratings worse than Lightgreen" 
          width={150} height={150} outerRadius={50} 
          titleFontSize={titleFontSize}
          labelFontSize={labelFontSize}
          colors={[negativeColor, positiveColor, neutralColor]} // Inverse color scheme with neutral
        />

        <PieChartComponent 
          data={redIssuesInRatingsData} 
          title=".. have Red issues in key ratings" 
          width={150} height={150} outerRadius={50} 
          titleFontSize={titleFontSize}
          labelFontSize={labelFontSize}
          colors={[negativeColor, positiveColor, neutralColor]} // Inverse color scheme with neutral
        />

        <PieChartComponent 
          data={redIssuesInAnyParamData} 
          title="..have Red Issues in any of the ratings %" 
          width={150} height={150} outerRadius={50} 
          titleFontSize={titleFontSize}
          labelFontSize={labelFontSize}
          colors={[negativeColor, positiveColor, neutralColor]} // Inverse color scheme with neutral
        />

      </div>
    </div>
  );
};

export default ESG_ETF_Headlines;

// import React from 'react';
// import PieChartComponent from './PieCharts'; // Ensure this path is correct

// const ESG_ETF_Headlines = ({ etfEsgHighlights }) => {

//   console.log("etfEsgHighlights in ESG ETF Headlines", etfEsgHighlights);

//   // Helper function to round to two decimal places
//   const roundToTwoDecimals = (num) => Math.round(num * 100) / 100;

//   const labelFontSize = 12;
//   const titleFontSize = 12;

//   // Color schemes
//   const positiveColor = '#00C49F'; // Green
//   const negativeColor = '#FF8042'; // Red
//   const neutralColor = '#d3d3d3'; // Grey
//   //neutral color: grey

//   // Function to calculate the unknown part
//   const calculateUnknownPart = (values) => {
//     const totalKnown = values.reduce((acc, val) => acc + val, 0);
//     return roundToTwoDecimals(100 - totalKnown);
//   };

//   //for the data preparation we need a 'unknown' part represented by a neutral color in the piechart.
//   //first calculate the sum of percentages of the holdings (*100)
//   //the difference between 100% and that sum is the unknown part
//   //then for instance 'Not All Green/Lightgreen'= 100-etfEsgHighlights.percent_all_green_lightgreen - unknown
//   // Prepare the data for each pie chart and multiply percentages by 100
//  // Prepare the data for each pie chart and include the unknown part
//  const greenLightgreenData = [
//   { name: 'All Green/Lightgreen', value: roundToTwoDecimals((etfEsgHighlights.percent_all_green_lightgreen || 0) * 100) },
//   { name: 'Not All Green/Lightgreen', value: roundToTwoDecimals(100 - (etfEsgHighlights.percent_all_green_lightgreen || 0) * 100) }
// ];
// greenLightgreenData.push({ name: 'Unknown', value: calculateUnknownPart(greenLightgreenData.map(item => item.value)) });

// const completeData = [
//   { name: 'Complete Data', value: roundToTwoDecimals((etfEsgHighlights.percent_complete_data || 0) * 100) },
//   { name: 'Incomplete Data', value: roundToTwoDecimals(100 - (etfEsgHighlights.percent_complete_data || 0) * 100) }
// ];
// completeData.push({ name: 'Unknown', value: calculateUnknownPart(completeData.map(item => item.value)) });

// const issuesData = [
//   { name: 'With Issues', value: roundToTwoDecimals((etfEsgHighlights.percent_with_issues || 0) * 100) },
//   { name: 'Without Issues', value: roundToTwoDecimals(100 - (etfEsgHighlights.percent_with_issues || 0) * 100) }
// ];
// issuesData.push({ name: 'Unknown', value: calculateUnknownPart(issuesData.map(item => item.value)) });

// const ratingsGreenLightgreenData = [
//   { name: 'Key Ratings Green/Lightgreen', value: roundToTwoDecimals((etfEsgHighlights.percent_overall_ratings_green_lightgreen || 0) * 100) },
//   { name: 'Key Ratings Not Green/Lightgreen', value: roundToTwoDecimals(100 - (etfEsgHighlights.percent_overall_ratings_green_lightgreen || 0) * 100) }
// ];
// ratingsGreenLightgreenData.push({ name: 'Unknown', value: calculateUnknownPart(ratingsGreenLightgreenData.map(item => item.value)) });

// const redIssuesInRatingsData = [
//   { name: 'Red Issues in Ratings', value: roundToTwoDecimals((etfEsgHighlights.percent_red_issue_in_ratings || 0) * 100) },
//   { name: 'No Red Issues in Ratings', value: roundToTwoDecimals(100 - (etfEsgHighlights.percent_red_issue_in_ratings || 0) * 100) }
// ];
// redIssuesInRatingsData.push({ name: 'Unknown', value: calculateUnknownPart(redIssuesInRatingsData.map(item => item.value)) });

// const redIssuesInAnyParamData = [
//   { name: 'Red Issues in Any Parameter', value: roundToTwoDecimals((etfEsgHighlights.percent_red_issue_any_param || 0) * 100) },
//   { name: 'No Red Issues in Any Parameter', value: roundToTwoDecimals(100 - (etfEsgHighlights.percent_red_issue_any_param || 0) * 100) }
// ];
// redIssuesInAnyParamData.push({ name: 'Unknown', value: calculateUnknownPart(redIssuesInAnyParamData.map(item => item.value)) });


//   return (
//     <div>
//       <h3>ETF ESG Highlights</h3>
      
//       <h3>Total holdings % of ETF where holdings..</h3>
//       <div style={{ display: 'flex', justifyContent: 'space-around', flexWrap: 'wrap' }}>

//       <PieChartComponent 
//           data={greenLightgreenData} 
//           title=".. are Green/Lightgreen for full ESG dataset" 
//           width={150} height={150} outerRadius={50} 
//           titleFontSize={titleFontSize}
//           labelFontSize={labelFontSize} 
//           colors={[positiveColor, negativeColor, neutralColor]} // Apply color scheme including neutral
//         />

//         <PieChartComponent 
//           data={completeData} 
//           title=".. have full ESG data available" 
//           width={150} height={150} outerRadius={50} 
//           titleFontSize={titleFontSize}
//           labelFontSize={labelFontSize}
//           colors={[positiveColor, negativeColor, neutralColor]} // Apply color scheme including neutral
//         />

//         <PieChartComponent 
//           data={ratingsGreenLightgreenData} 
//           title="..have key ratings Green/Lightgreen" 
//           width={150} height={150} outerRadius={50} 
//           titleFontSize={titleFontSize}
//           labelFontSize={labelFontSize}
//           colors={[positiveColor, negativeColor, neutralColor]} // Apply color scheme including neutral
//         />

//         <PieChartComponent 
//           data={issuesData} 
//           title="..have ratings worse than Lightgreen" 
//           width={150} height={150} outerRadius={50} 
//           titleFontSize={titleFontSize}
//           labelFontSize={labelFontSize}
//           colors={[negativeColor, positiveColor, neutralColor]} // Inverse color scheme with neutral
//         />

//         <PieChartComponent 
//           data={redIssuesInRatingsData} 
//           title=".. have Red issues in key ratings" 
//           width={150} height={150} outerRadius={50} 
//           titleFontSize={titleFontSize}
//           labelFontSize={labelFontSize}
//           colors={[negativeColor, positiveColor, neutralColor]} // Inverse color scheme with neutral
//         />

//         <PieChartComponent 
//           data={redIssuesInAnyParamData} 
//           title="..have Red Issues in any of the ratings %" 
//           width={150} height={150} outerRadius={50} 
//           titleFontSize={titleFontSize}
//           labelFontSize={labelFontSize}
//           colors={[negativeColor, positiveColor, neutralColor]} // Inverse color scheme with neutral
//         />

//       </div>
//     </div>
//   );
// };

// export default ESG_ETF_Headlines;

// // // ESG_ETF_Headlines.jsx
// // import React from 'react';
// // import PieChartComponent from './PieCharts'; // Ensure this path is correct

// // const ESG_ETF_Headlines = ({ etfEsgHighlights }) => {

// //   console.log("etfEsgHighlights in ESG ETF Headlines", etfEsgHighlights);

// //   // Prepare the data for each pie chart and multiply percentages by 100
// // // Helper function to round to two decimal places
// // const roundToTwoDecimals = (num) => Math.round(num * 100) / 100;

// // const labelFontSize=12
// // const titleFontSize=12

// // // Color schemes
// // const positiveColor = '#00C49F'; // Green
// // const negativeColor = '#FF8042'; // Red


// // // Prepare the data for each pie chart and multiply percentages by 100
// // const greenLightgreenData = [
// //   { name: 'All Green/Lightgreen', value: roundToTwoDecimals((etfEsgHighlights.percent_all_green_lightgreen || 0) * 100) },
// //   { name: 'Not All Green/Lightgreen', value: roundToTwoDecimals(100 - (etfEsgHighlights.percent_all_green_lightgreen || 0) * 100) }
// // //positive : percent_all_green_lightgreen

// // ];

// // const completeData = [
// //   { name: 'Complete Data', value: roundToTwoDecimals((etfEsgHighlights.percent_complete_data || 0) * 100) },
// //   { name: 'Incomplete Data', value: roundToTwoDecimals(100 - (etfEsgHighlights.percent_complete_data || 0) * 100) }
// // //positive : percent_complete_data
// // ];

// // const issuesData = [
// //   { name: 'With Issues', value: roundToTwoDecimals((etfEsgHighlights.percent_with_issues || 0) * 100) },
// //   { name: 'Without Issues', value: roundToTwoDecimals(100 - (etfEsgHighlights.percent_with_issues || 0) * 100) }
// // //positive : percent WITHOUT issues
// // ];

// // const ratingsGreenLightgreenData = [
// //   { name: 'Key Ratings Green/Lightgreen', value: roundToTwoDecimals((etfEsgHighlights.percent_overall_ratings_green_lightgreen || 0) * 100) },
// //   { name: 'Key Ratings Not Green/Lightgreen', value: roundToTwoDecimals(100 - (etfEsgHighlights.percent_overall_ratings_green_lightgreen || 0) * 100) }
// // //positive : percent_overall_ratings_green_lightgreen
// // ];

// // const redIssuesInRatingsData = [
// //   { name: 'Red Issues in Ratings', value: roundToTwoDecimals((etfEsgHighlights.percent_red_issue_in_ratings || 0) * 100) },
// //   { name: 'No Red Issues in Ratings', value: roundToTwoDecimals(100 - (etfEsgHighlights.percent_red_issue_in_ratings || 0) * 100) }
// // //positive : percent WITHOUT red issues
// // ];

// // const redIssuesInAnyParamData = [
// //   { name: 'Red Issues in Any Parameter', value: roundToTwoDecimals((etfEsgHighlights.percent_red_issue_any_param || 0) * 100) },
// //   { name: 'No Red Issues in Any Parameter', value: roundToTwoDecimals(100 - (etfEsgHighlights.percent_red_issue_any_param || 0) * 100) }
// // //positive : percent WITHOUT red issues
// // ];



// //   return (
// //     <div>
// //       <h3>ETF ESG Highlights</h3>
// //       {/* <ul>
// //         <li>Total All Green/Lightgreen: {etfEsgHighlights.total_all_green_lightgreen}</li>
// //         <li>Total All Green/Lightgreen %: {((etfEsgHighlights.percent_all_green_lightgreen || 0) * 100).toFixed(2)}%</li>
// //         <li>Holdings with complete data from sources: {etfEsgHighlights.total_with_complete_data}</li>
// //         <li>Holdings with complete data %: {((etfEsgHighlights.percent_complete_data || 0) * 100).toFixed(2)}%</li>
// //         <li>Total With Issues: {etfEsgHighlights.total_with_issues}</li>
// //         <li>Total With Issues %: {((etfEsgHighlights.percent_with_issues || 0) * 100).toFixed(2)}%</li>
// //         <li>Holdings with all key ESG ratings available : {etfEsgHighlights.total_with_overall_ratings_complete}</li>
// //         <li>Holdings with all key ESG ratings available %: {((etfEsgHighlights.percent_overall_ratings_complete || 0) * 100).toFixed(2)}%</li>
// //         <li>Holdings with all key ESG ratings Green/Lightgreen: {etfEsgHighlights.total_with_overall_ratings_green_lightgreen}</li>
// //         <li>Holdings with all key ESG ratings Green/Lightgreen %: {((etfEsgHighlights.percent_overall_ratings_green_lightgreen || 0) * 100).toFixed(2)}%</li>
// //         <li>Total With Ratings Not Green/Lightgreen: {etfEsgHighlights.total_with_ratings_not_green_lightgreen}</li>
// //         <li>Total With Ratings Not Green/Lightgreen %: {((etfEsgHighlights.percent_ratings_not_green_lightgreen || 0) * 100).toFixed(2)}%</li>
// //         <li>Total With Issues in Any Parameter: {etfEsgHighlights.total_with_any_param_issues}</li>
// //         <li>Total With Issues in Any Parameter %: {((etfEsgHighlights.percent_any_param_issue || 0) * 100).toFixed(2)}%</li>
// //         <li>Total With Red Issues in Ratings: {etfEsgHighlights.total_with_red_issues_in_ratings}</li>
// //         <li>Total With Red Issues in Ratings %: {((etfEsgHighlights.percent_red_issue_in_ratings || 0) * 100).toFixed(2)}%</li>
// //         <li>Total With Red Issues in Any Parameter: {etfEsgHighlights.total_with_red_issues_in_any_param}</li>
// //         <li>Total With Red Issues in Any Parameter %: {((etfEsgHighlights.percent_red_issue_any_param || 0) * 100).toFixed(2)}%</li>
// //         <li>Total Holding Percent: {((etfEsgHighlights.total_holding_percent || 0) * 100).toFixed(2)}%</li>
// //       </ul> */}

// //       <h3>Total holdings % of ETF where holdings..</h3>
// //       {/* Pie Charts for Visual Representation */}
// //       <div style={{ display: 'flex', justifyContent: 'space-around', flexWrap: 'wrap' }}>
// //       <PieChartComponent data={greenLightgreenData} title=".. .. are Green/Lightgreen for full ESG dataset" 
// //       width={150} height={150} outerRadius={50} 
// //       titleFontSize={titleFontSize}
// //       labelFontSize={labelFontSize} 
// //       />

// //       <PieChartComponent data={completeData} title=".. have full ESG data available" width={150} height={150} outerRadius={50} 
// //       titleFontSize={titleFontSize}
// //       labelFontSize={labelFontSize}/>


// //       <PieChartComponent data={ratingsGreenLightgreenData} title="..have key ratings Green/Lightgreen" width={150} height={150} outerRadius={50} 
// //       titleFontSize={titleFontSize}
// //       labelFontSize={labelFontSize}/>

      
      
// //       <PieChartComponent data={issuesData} title="..have ratings worse than Lightgreen" width={150} height={150} outerRadius={50} 
// //       titleFontSize={titleFontSize}
// //       labelFontSize={labelFontSize}/>
     
// //       <PieChartComponent data={redIssuesInRatingsData} title=".. have Red issues in key ratings" width={150} height={150} outerRadius={50} 
// //       titleFontSize={titleFontSize}
// //       labelFontSize={labelFontSize}/>
// //       <PieChartComponent data={redIssuesInAnyParamData} title="..have Red Issues in any of the ratings %" width={150} height={150} outerRadius={50} 
// //       titleFontSize={titleFontSize}
// //       labelFontSize={labelFontSize}/>

// //     </div>
// //     </div>
// //   );
// // };

// // export default ESG_ETF_Headlines;
