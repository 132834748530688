import React from 'react';
import { Table, Tooltip } from 'antd';
import SimpleExternalLinkNewWindow from '../functions/LinkFlex';

const transpose = (matrix) => {
  return matrix[0].map((_, colIndex) => matrix.map((row) => row[colIndex]));
};

const Stockinfo_TablesContent = ({ tablesData, tickerValue, DD_dataset_json, DP_dataset_json, metadata, handleValueClick }) => {
  
    console.log("Tablesdata ", tablesData)

    const prepareTableData = (data, tickerValue, DD_dataset_json, DP_dataset_json) => {
    if (!data) {
      return { dataSource: [] };
    }

    const transposedData = transpose(data);

    const findLatestTrendForField = (tickerValue, field, DD_dataset_json) => {
      if (!Array.isArray(DD_dataset_json)) {
        console.log("no DD_dataset_json");
        return null;
      }

      const normalizedField = field.replace(/\s+/g, '_').toLowerCase();

      const matchingEntries = DD_dataset_json.filter(
        (entry) => entry.ticker === tickerValue && entry.datafield.toLowerCase() === normalizedField
      );

      const sortedEntries = matchingEntries.sort((a, b) => b.epoch - a.epoch);

      return sortedEntries.length > 0
        ? {
            trend: sortedEntries[0].trend,
            oldvalue: sortedEntries[0].oldvalue,
          }
        : null;
    };

    const getLabelForField = (field, DP_dataset_json) => {
      if (!DP_dataset_json || !Array.isArray(DP_dataset_json)) {
        return field; // Return the original field if DP_dataset_json is not available
      }

      const normalizedField = field.replace(/\s+/g, '_').toLowerCase();
      const entry = DP_dataset_json.find((e) => e.DataField.toLowerCase() === normalizedField);

      return entry ? entry.Label : field; // Return the label if found, otherwise return the original field
    };

    const dataSource = transposedData.map((row, index) => {
      const field = row[0].replace(/_/g, ' ');
      const label = getLabelForField(row[0], DP_dataset_json);
      let value = null;
      let trend = findLatestTrendForField(tickerValue, field, DD_dataset_json);
      let epoch = null;

      if (row[1] && Array.isArray(row[1])) {
        value = row[1][0] ? row[1][0].toString().replace(/_/g, ' ') : null;
        epoch = row[1][1];
      } else {
        value = row[1] ? row[1].toString().replace(/_/g, ' ') : null;
      }

      return {
        key: index,
        field: label,
        originalField: row[0], // Ensure originalField is stored
        value: value,
        trend: trend,
        epoch: epoch,
      };
    });

    return { dataSource };
  };

  const tableTitle = (title) => <div className="table-title">{title.replace(/_/g, ' ')}</div>;

  const formatDate = (epoch) => {
    if (epoch === null) {
      return <span style={{ color: 'red', fontSize: '6px' }}>?</span>;
    }

    const epochDate = new Date(epoch * 1000);
    const currentDate = new Date();
    const oneDayInMs = 86400000;

    if (currentDate - epochDate > oneDayInMs) {
      const formattedDate = epochDate
        .toLocaleDateString('en-GB', {
          day: '2-digit',
          month: 'short',
          year: '2-digit',
        })
        .replace(/ /g, '');
      return <span style={{ color: 'red', fontSize: '6px' }}>{formattedDate.toLowerCase()}</span>;
    }

    return null;
  };

  return (
    <div className="tables-container">
      {tablesData &&
        tablesData.map((tableData, index) => {
          const { dataSource } = prepareTableData(tableData.data, tickerValue, DD_dataset_json, DP_dataset_json);

          const columns = [
            {
              title: 'Field',
              dataIndex: 'field',
              key: 'field',
            },
            {
              title: 'Value',
              dataIndex: 'value',
              key: 'value',
              render: (value, record) => {
                const fieldName = record.originalField.replace(/\s+/g, '_').toLowerCase();
                let fieldMetadata = null;
              
                for (let key in metadata) {
                  if (key.toLowerCase() === fieldName) {
                    fieldMetadata = metadata[key];
                    break;
                  }
                }
              
                const dateComponent = formatDate(record.epoch);
              
                const trendComponent = record.trend ? (
                  record.trend.trend === 'positive' ? (
                    <span style={{ color: 'green' }}> ↑</span>
                  ) : record.trend.trend === 'negative' ? (
                    <span style={{ color: 'red' }}> ↓</span>
                  ) : null
                ) : null;
              
                const valueColor = record.trend
                  ? record.trend.trend === 'positive'
                    ? 'green'
                    : record.trend.trend === 'negative'
                    ? 'red'
                    : 'inherit'
                  : 'inherit';
              
                const tooltipText = fieldMetadata
                  ? `${fieldMetadata.tooltip}${trendComponent ? `. Previous value: ${record.trend.oldvalue}` : ''}`
                  : '';
              
                return (
                  <Tooltip title={tooltipText}>
                    <div style={{ position: 'relative', color: valueColor }}>
                      <SimpleExternalLinkNewWindow clickedValue={value} onClick={() => handleValueClick(record)} />
                      <span>{trendComponent}</span>
                    </div>
                  </Tooltip>
                );
              },
            
            },
          ];

          return (
            <div key={index} className="table-wrapper">
  <h3>{tableData.table_name.replace(/_/g, ' ')}</h3>
  <Table
    columns={columns}
    dataSource={dataSource}
    pagination={false}
    bordered
    size="small"
    showHeader={false}
    onRow={(record) => ({
      onClick: () => {
        handleValueClick(record);
      },
    })}
  />
</div>

          );
        })}
    </div>
  );
};

export default Stockinfo_TablesContent;
