// PF_Analysis.jsx
import React from 'react';
import { useSelector } from 'react-redux';
import PieChartComponent from './PieCharts';
import '../PF_Analysis.css';

const PF_Piecharts_Analysis = () => {
  const portfolioData = useSelector(state => state.portfolio.data);

  if (!portfolioData) {
    return null; // Return null or handle the case when portfolioData is null
  }

  const { asset_type_division, industry_division, risk_division, pe_division, currency_division, 
    category_userstatus, portfolio_topline, top_contributors } = portfolioData;

  console.log("top contributors list", top_contributors);

  const preprocessData = (data, column1Key = 'column1_name', column2Key = 'column2_name') => {
    return data.map(item => ({
      name: item[column1Key] !== null ? item[column1Key] : 'undefined', // Rename 'null' to 'undefined'
      value: parseFloat(item[column2Key]),
    }));
  };

  // Include 'cash' category in category_userstatus
  const cashPartOfPortfolio = parseFloat(portfolio_topline[0]?.cash_eur_part_of_portfolio);
  let updatedCategoryUserStatus = [...category_userstatus]; // Create a new array
  if (!isNaN(cashPartOfPortfolio)) {
    updatedCategoryUserStatus = [...updatedCategoryUserStatus, {
      category: 'Cash',
      part_of_portfolio: cashPartOfPortfolio.toString()
    }];
  }

  const categoryUserStatusData = preprocessData(updatedCategoryUserStatus, 'category', 'part_of_portfolio');
  const topContributorsData = preprocessData(top_contributors);

  return (
    <div className="grid-container">
      {top_contributors && <PieChartComponent data={topContributorsData} title="Top Contributors to Unrealized Profit" />}
      {updatedCategoryUserStatus && <PieChartComponent data={categoryUserStatusData} title="Division per Category" />}
      {asset_type_division && <PieChartComponent data={preprocessData(asset_type_division)} title="Division per Asset type" />}
      {industry_division && <PieChartComponent data={preprocessData(industry_division)} title="Industry Division" />}
      {risk_division && <PieChartComponent data={preprocessData(risk_division)} title="Risk Division" />}
      {pe_division && <PieChartComponent data={preprocessData(pe_division)} title="PE Division" />}
      {currency_division && <PieChartComponent data={preprocessData(currency_division)} title="Currency Division" />}
    </div>
  );
};

export default PF_Piecharts_Analysis;
