///AlertClauseDefinition.jsx
import React, { useState, useEffect } from 'react';
import { Table, Input, InputNumber, Form, notification, Button, Popconfirm } from 'antd';
import api from '../functions/api';

const EditableCell = ({
    editing,
    dataIndex,
    title,
    inputType,
    record,
    index,
    children,
    ...restProps
}) => {
    const inputNode = inputType === 'number' ? <InputNumber /> : <Input />;
    const rules = [];

    // Specify which fields are required
    if (['clause_field', 'clause_field_sourcetable', 'clause_operator'].includes(dataIndex)) {
        rules.push({
            required: true,
            message: `Please Input ${title}!`,
        });
    }

    return (
        <td {...restProps}>
            {editing ? (
                <Form.Item
                    name={dataIndex}
                    style={{ margin: 0 }}
                    rules={rules}
                >
                    {inputNode}
                </Form.Item>
            ) : (
                children
            )}
        </td>
    );
};

const AlertClauseDefinition = () => {
    const [form] = Form.useForm();
    const [data, setData] = useState([]);
    const [editingKey, setEditingKey] = useState('');

    const isEditing = (record) => record.key === editingKey;

    const edit = (record) => {
        form.setFieldsValue({
            ...record,
        });
        setEditingKey(record.key);
    };

    const cancel = () => {
        setEditingKey('');
    };

    const save = async key => {
        try {
            const row = await form.validateFields();
            const index = data.findIndex(item => key === item.key);

            if (index > -1) {
                const item = data[index];
                const updatedRow = { ...item, ...row };

                if (item.ID) {
                    // Existing record - Use PUT
                    await api.put(`/alert_clause_definition/${item.ID}`, updatedRow);
                    // Update the data array with the updated item
                    const newData = [...data.slice(0, index), updatedRow, ...data.slice(index + 1)];
                    setData(newData);
                } else {
                    // New record - Use POST
                    const result = await api.post('/alert_clause_definition', row);
                    updatedRow.ID = result.data.ID; // Assuming your backend returns the new ID
                    updatedRow.key = result.data.ID;
                    // Replace the temporary new entry with the confirmed new entry
                    const newData = [...data.slice(0, index), updatedRow, ...data.slice(index + 1)];
                    setData(newData);
                }

                setEditingKey('');
                notification.success({ message: 'Update successful' });
            }
        } catch (errInfo) {
            console.error('Validate Failed:', errInfo);
            notification.error({
                message: 'Update failed',
                description: errInfo.message,
            });
        }
    };

    const deleteClause = async key => {
        try {
            const item = data.find(item => item.key === key);
            if (item.ID) {
                await api.delete(`/alert_clause_definition/${item.ID}`);
                setData(data.filter(item => item.key !== key));
                notification.success({ message: 'Delete successful' });
            }
        } catch (errInfo) {
            console.error('Delete Failed:', errInfo);
            notification.error({
                message: 'Delete failed',
                description: errInfo.message,
            });
        }
    };

    const fetchAlertClauseData = async () => {
        const result = await api.get('/alert_clause_definition');
        // Process the data to include alerts
        const processedData = result.data.map(item => ({
            ...item,
            key: item.ID,
            alerts: item.alerts.join(', ') // Assuming alerts is an array of alert IDs
        }));
        setData(processedData);
    };

    useEffect(() => {
        fetchAlertClauseData();
    }, []);

    const columns = [
        {
            title: 'ID',
            dataIndex: 'ID',
            editable: false,  // Assuming you don't want the ID to be editable
            width: '2%',
        },
        {
            title: 'Clause Field',
            dataIndex: 'clause_field',
            editable: true,
            width: '3%',
        },
        {
            title: 'Source Table',
            dataIndex: 'clause_field_sourcetable',
            editable: true,
            width: '5%',
        },
        {
            title: 'Operator',
            dataIndex: 'clause_operator',
            editable: true,
            width: '2%',
        },
        {
            title: 'Threshold',
            dataIndex: 'threshold',
            editable: true,
            width: '5%',
        },
        {
            title: 'Threshold Field',
            dataIndex: 'threshold_field',
            editable: true,
            width: '5%',
        },
        {
            title: 'Threshold Source Table',
            dataIndex: 'threshold_sourcetable',
            editable: true,
            width: '5%',
        },
        {
            title: 'Clause Text',
            dataIndex: 'clause_txt',
            editable: true,
            width: 'auto'
        },
        {
            title: 'In Alert',
            dataIndex: 'alerts',
            editable: false,
            width: 'auto'
        },
        {
            title: 'Operation',
            width: '5%',
            dataIndex: 'operation',
            render: (_, record) => {
                const editable = isEditing(record);
                if (editable) {
                    return (
                        <span>
                            <a
                                href="#save"
                                onClick={() => save(record.key)}
                                style={{
                                    marginRight: 8,
                                }}
                            >
                                Save
                            </a>
                            <a href="#cancel" onClick={cancel}>Cancel</a>
                        </span>
                    );
                } else if (!record.alerts) {
                    return (
                        <Popconfirm title="Sure to delete?" onConfirm={() => deleteClause(record.key)}>
                            <a href="#delete">Delete</a>
                        </Popconfirm>
                    );
                } else {
                    return (
                        <a href="#edit" disabled={editingKey !== ''} onClick={() => edit(record)}>
                            Edit
                        </a>
                    );
                }
            },
            editable: false
        },
    ];

    const mergedColumns = columns.map((col) => {
        if (!col.editable) {
            return col;
        }
        return {
            ...col,
            onCell: (record) => ({
                record,
                inputType: col.dataIndex === 'clause_id' ? 'number' : 'text',
                dataIndex: col.dataIndex,
                title: col.title,
                editing: isEditing(record),
            }),
        };
    });

    const addNewRecord = () => {
        setData([...data, { key: 'new', ID: null, alerts: '' }]);
        setEditingKey('new');
    };

    return (
        <div>
            <Button onClick={addNewRecord} type="primary" style={{ marginBottom: 16 }}>
                Add New Record
            </Button>
            <Form form={form} component={false}>
                <Table
                    components={{ body: { cell: EditableCell } }}
                    bordered
                    dataSource={data}
                    columns={mergedColumns}
                    rowClassName="editable-row"
                    pagination={{ onChange: cancel }}
                    // scroll={{ x: 'max-content' }}
                />
            </Form>
        </div>
    );
};

export default AlertClauseDefinition;
