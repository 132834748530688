import React, { useState, useEffect } from 'react';
import { Button } from 'antd';
import html2canvas from 'html2canvas';
import jsPDF from 'jspdf';

function DownloadPDF({ contentId, filename, currentDateTime }) {

  const [isRendered, setIsRendered] = useState(false);

  useEffect(() => {
    setIsRendered(true);
  }, []);

  const downloadPDF = () => {
    if (!isRendered) return;  // Exit if component is not rendered yet

    const element = document.getElementById(contentId);
    
    if (element) {
      // Create a header element with title and date/time if provided
      const headerDiv = document.createElement('div');
      const titleElement = document.createElement('h1');
      
      // Check if currentDateTime is provided
      titleElement.textContent = currentDateTime 
        ? `${filename} - ${currentDateTime}` 
        : `${filename}`;
      
      titleElement.style.fontWeight = 'bold';
      titleElement.style.fontSize = '18px';
      titleElement.style.marginBottom = '12px';
      headerDiv.appendChild(titleElement);
      
      // Insert the header at the beginning of the element
      element.insertBefore(headerDiv, element.firstChild);
      
      const contentWidth = element.scrollWidth;
      const contentHeight = element.scrollHeight;
  
      html2canvas(element, {
        windowWidth: contentWidth,
        windowHeight: contentHeight,
        logging: true,
      })
      .then(canvas => {
        // Remove the dynamically created header after capturing the screenshot
        element.removeChild(headerDiv);
        
        const imgData = canvas.toDataURL('image/png');
        const pdf = new jsPDF({
          orientation: 'landscape',
          unit: 'pt',
          format: 'a4',
        });
  
        const leftMargin = 20;  // adjust as necessary
        const topMargin = 20;   // adjust as necessary

        const pdfWidth = pdf.internal.pageSize.getWidth() - 2 * leftMargin;
        const pdfHeight = pdf.internal.pageSize.getHeight() - 2 * topMargin;

        const ratio = Math.min(pdfWidth / contentWidth, pdfHeight / contentHeight);
   
        pdf.addImage(imgData, 'PNG', leftMargin, topMargin, contentWidth * ratio, contentHeight * ratio);
        
        pdf.save(`${filename}.pdf`);
      })
      .catch(error => {
        // Remove the dynamically created header if there's an error
        element.removeChild(headerDiv);
        console.error('Error creating PDF:', error);
      });
    } else {
      console.error('Element not found');
    }
  };

  return (
    <Button type="primary" onClick={downloadPDF} disabled={!isRendered}>Download PDF</Button>
  );
}

export default DownloadPDF;

