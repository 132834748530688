// AddTicker.jsx

import React, { useState } from 'react';
import { Input, Button, Space, notification } from 'antd';
import SearchBox2 from '../functions/Search_Generic';
import api from '../functions/api'; // Make sure this path is correct

const AddTicker = ({ closeModal }) => {
  const [companyName, setCompanyName] = useState('');
  const [selectedTicker, setSelectedTicker] = useState(''); // State to keep track of ticker checked for existence
  const [definitiveTicker, setDefinitiveTicker] = useState(''); // State for definitive ticker input

  const onSelectTicker = (ticker) => {
    setSelectedTicker(ticker);
  };

  const handleCompanyNameChange = (e) => {
    setCompanyName(e.target.value);
  };

  const handleDefinitiveTickerChange = (e) => {
    setDefinitiveTicker(e.target.value);
  };

  const handleSave = async () => {
    // Use definitiveTicker.trim() instead of selectedTicker
    if (!definitiveTicker.trim() || !companyName.trim()) {
      notification.error({
        message: 'Error',
        description: 'Both ticker and company name are required.',
      });
      return;
    }

    try {
      console.log('Sending request with data:', { ticker: definitiveTicker, company: companyName });
      const response = await api.post('/add_Ticker', { ticker: definitiveTicker, company: companyName });
      console.log('Response received:', response);
      notification.success({
        message: 'Success',
        description: response.data.message || 'Ticker added successfully.',
      });
      closeModal(); 
    } catch (error) {
      console.error('Error saving ticker:', error);
      notification.error({
        message: 'Error',
        description: 'Failed to add ticker. Please try again.',
      });
    }
  };

  return (
    <Space direction="vertical">
      <SearchBox2 onSelectTicker={onSelectTicker} />
      <Input
        placeholder="Definitive Ticker"
        value={definitiveTicker}
        onChange={handleDefinitiveTickerChange}
      />
      <Input 
        placeholder="Company Name" 
        value={companyName} 
        onChange={handleCompanyNameChange} 
      />
      <Space>
        <Button type="primary" onClick={handleSave}>Save</Button>
        <Button onClick={closeModal}>Cancel</Button>
      </Space>
    </Space>
  );
};

export default AddTicker;
