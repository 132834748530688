//AlertConfigure.jsx

import React, { useState, useEffect } from 'react';
import { Table, Input, Select, Button, Form, Divider, Row, Col, message, Modal } from 'antd';
import { MinusCircleOutlined } from '@ant-design/icons';
import api from '../functions/api';  // Assuming 'api' is an axios instance set up to interact with your backend.

const { Option } = Select;

const AlertConfigure = () => {
  const [data, setData] = useState([]);
  const [clauses, setClauses] = useState([
    { ID: '', clauseField: '', operator: '', threshold: '', cfSourceTable: '', thresholdField: '', thresholdSourceTable: '', clause_txt: '', isModified: false, idSearch: '' }
  ]);
  const [testResults, setTestResults] = useState([]); // State to store test results
  const [detailedResults, setDetailedResults] = useState([]); // State to store detailed test results
  const [advice, setAdvice] = useState('');
  const [alerttype, setAlerttype] = useState('');
  const [alertsubtype, setAlertsubtype] = useState('');
  const [combinedTestCount, setCombinedTestCount] = useState(null);
  const [idSearch, setIdSearch] = useState(""); // Temp state for input
  const [firstAlert, setFirstAlert] = useState(""); // State to store the first alert message
  const [alertDefinitions, setAlertDefinitions] = useState([]); // State to store alert definitions data
  const [alerts, setAlerts] = useState([]);
  const [isModalVisible, setIsModalVisible] = useState(false); // State to manage modal visibility
  const [isTestModalVisible, setIsTestModalVisible] = useState(false); // State to manage test result modal visibility
  const [currentTestIndex, setCurrentTestIndex] = useState(null); // State to manage current test index
  const [dataChanged, setDataChanged] = useState(false);


  const fetchAlertDefinitions = async () => {
    try {
      const response = await api.get('/alert_load');
      console.log("Alert definitions fetched:", response.data);
      setAlertDefinitions(response.data); // Update state with fetched data
    } catch (error) {
      console.error('Failed to fetch alert definitions:', error);
      message.error('Failed to load alert definitions');
    }
  };

  const fetchAlertClauseData = async () => {
    try {
      const result = await api.get('/alert_clause_definition');
      setData(result.data.map(item => ({ ...item, key: item.ID })));
    } catch (error) {
      console.error('Failed to fetch data:', error);
    }
  };

  useEffect(() => {
    fetchAlertClauseData();
    fetchAlertDefinitions();
    console.log("fetch alert & clausdefs triggered")
  }, [dataChanged]);
  // }, [clauses, alertDefinitions]);
  // }, []);

  const handleAddClause = () => {
    setClauses([...clauses, { ID: '', clauseField: '', operator: '', threshold: '' }]);
  };

  const handleRemoveClause = index => {
    const filteredClauses = clauses.filter((_, i) => i !== index);
    setClauses(filteredClauses);
  };

  const handleClauseChange = (value, index, field) => {
    const newClauses = clauses.map((clause, i) => {
      if (i === index) {
        return { ...clause, [field]: value, isModified: true };
      }
      return clause;
    });
    setClauses(newClauses);
  };

  const handleIDSearch = (id, index) => {
    const updatedClauses = clauses.map((clause, i) => {
      if (i === index) {
          return { ...clause, idSearch: id };
      }
      return clause;
    });

    const found = data.find(item => item.ID.toString() === id.trim());
    if (found) {
        const newClauses = updatedClauses.map((clause, i) => {
            if (i === index) {
                return {
                    ...clause,
                    ID: found.ID,
                    clauseField: found.clause_field || '',
                    cfSourceTable: found.clause_field_sourcetable || '',
                    operator: found.clause_operator || '',
                    threshold: found.threshold || '',
                    thresholdField: found.threshold_field || '',
                    thresholdSourceTable: found.threshold_sourcetable || '',
                    clause_txt: found.clause_txt || '',
                    isModified: clause.isModified
                };
            }
            return clause;
        });
        setClauses(newClauses);
    } else {
        console.error(`No data found with ID ${id}`);
        message.error(`No data found with ID ${id}`);
        setClauses(updatedClauses);
    }
  };

  const handleSearchAlertById = (alertId) => {
    setIdSearch(alertId);
    const foundAlert = alertDefinitions.find(alert => alert.ID.toString() === alertId.trim());
    if (foundAlert) {
      setAlerttype(foundAlert.alert_type || '');
      setAlertsubtype(foundAlert.alert_subtype || '');
      setAdvice(foundAlert.advice_txt || '');
  
      const clauseIds = foundAlert.clauses.split(',').map(id => id.trim());
      const newClauses = clauseIds.map(id => {
        const foundClause = data.find(clause => clause.ID.toString() === id);
        return foundClause ? {
          ID: foundClause.ID,
          clauseField: foundClause.clause_field || '',
          cfSourceTable: foundClause.clause_field_sourcetable || '',
          operator: foundClause.clause_operator || '',
          threshold: foundClause.threshold || '',
          thresholdField: foundClause.threshold_field || '',
          thresholdSourceTable: foundClause.threshold_sourcetable || '',
          clause_txt: foundClause.clause_txt || '',
          isModified: false,
          idSearch: id
        } : { idSearch: id, isModified: false };
      });
  
      setClauses(newClauses);
      message.success('Alert data loaded successfully!');
    } else {
      message.error(`No alert found with ID ${alertId}`);
    }
  };

  const handleDeleteAlert = async (alertId) => {
    try {
      await api.delete(`/alert_delete/${alertId}`);
      message.success('Alert deleted successfully');
      setAlertDefinitions(alertDefinitions.filter(alert => alert.ID !== alertId));
    } catch (error) {
      console.error('Failed to delete alert:', error);
      message.error('Failed to delete alert');
    }
  };

  const handleTestClause = async (index) => {
    const clause = clauses[index];
    try {
      const response = await api.post('/clause_test', { clause });
      const newTestResults = [...testResults];
      newTestResults[index] = response.data.results.true_count;
      setTestResults(newTestResults);
      const newDetailedResults = [...detailedResults];
      newDetailedResults[index] = response.data.results.details;
      setDetailedResults(newDetailedResults);
      console.log("response single clause test", response)
      message.success('Clause tested successfully!');
    } catch (error) {
      console.error('Failed to test clause:', error);
      message.error('Failed to test clause.');
    }
  };

  const validateClauses = (clauses) => {
    let isValid = true;
    let errorMsg = '';

    for (const [index, clause] of clauses.entries()) {
      const { clauseField = '', cfSourceTable = '', operator = '', threshold = '', thresholdField = '', thresholdSourceTable = '' } = clause;

      if (!clauseField.trim() || !cfSourceTable.trim() || !operator.trim()) {
        isValid = false;
        errorMsg = `Missing required fields in Clause ${index + 1}. Clause Field, CF Source Table, and Operator are mandatory.`;
        break;
      }

      const hasThreshold = threshold.trim() !== '';
      const hasThresholdField = thresholdField.trim() !== '';
      const hasThresholdSourceTable = thresholdSourceTable.trim() !== '';

      if (hasThreshold && hasThresholdField || hasThreshold && hasThresholdSourceTable) {
        isValid = false;
        errorMsg = `In Clause ${index + 1}, when Threshold is provided, Threshold Field and Threshold Source Table must be empty.`;
        break;
      } else if (hasThresholdField !== hasThresholdSourceTable) {
        isValid = false;
        errorMsg = `In Clause ${index + 1}, both Threshold Field and Threshold Source Table must be filled, or both must be empty.`;
        break;
      } else if (!hasThreshold && !hasThresholdField && !hasThresholdSourceTable) {
        isValid = false;
        errorMsg = `In Clause ${index + 1}, you must either provide a Threshold or both Threshold Field and Threshold Source Table.`;
        break;
      }
    }

    return { isValid, errorMsg };
  };

  const handleSaveAlert = async () => {
    console.log("Attempting to save the entire alert configuration with advice:", advice);
    
    if (!alerttype.trim() || !advice.trim()) {
      message.error("Cannot save alert: make sure all required fields are filled.");
      return;
    }
  
    const { isValid, errorMsg } = validateClauses(clauses);
  
    if (!isValid) {
      message.error(errorMsg);
      return;
    }
  
    if (!advice.trim()) {
      message.error("Advice cannot be empty.");
      return;
    }
    if (!alerttype.trim()) {
      message.error("Alert Type cannot be empty.");
      return;
    }
  
    const alertDefinition = {
      clauses: clauses,
      advice: advice,
      alerttype: alerttype,
      alertsubtype: alertsubtype
    };
  
    try {
      const response = await api.post('/alert_save', { 
        alert_id: idSearch,
        alert_definition: alertDefinition 
      });
      console.log("Save operation was successful:", response.data);
      message.success('Alert configuration saved successfully!');
      // setAlertDefinitions(prevDefinitions => [...prevDefinitions, alertDefinition]);  // This will trigger the useEffect
    // Trigger data fetching
    setDataChanged(prev => !prev);

    } catch (error) {
      console.error('Failed to save alert configuration:', error);
      message.error('Failed to save alert configuration.');
    }
  };

  const handleTestCombinedClauses = async () => {
    try {
      const payload = {
        id: idSearch,
        clause: clauses,
        advice: advice
      };

      setAlerts([]);

      const response = await api.post('/clause_test', payload);
      console.log("Combined test results:", response.data);
      message.success('Combined clauses tested successfully!');
      setCombinedTestCount(response.data.results.count_alerts);

      if (response.data.results.alerts && response.data.results.alerts.length > 0) {
        setAlerts(response.data.results.alerts);
        setFirstAlert(response.data.results.alerts[0]);
      } else {
        setAlerts([]);
        setFirstAlert(null);
        message.info("No alerts available.");
      }
    } catch (error) {
      console.error('Failed to test combined clauses:', error);
      message.error('Failed to test combined clauses.');
      setCombinedTestCount(null);
      setAlerts([]);
      setFirstAlert(null);
    }
  };

  const handleSaveClause = async (index) => {
    const clause = clauses[index];
    console.log("Saving clause data:", clause);

    try {
      const response = await api.post('/save_clause', { clause });
      console.log("Clause saved successfully:", response.data);
      message.success('Clause saved successfully!');
      
      const newClauses = clauses.map((item, idx) => idx === index ? { ...item, isModified: false } : item);
      setClauses(newClauses);
      // Trigger data fetching
     setDataChanged(prev => !prev);
    } catch (error) {
      console.error('Failed to save clause:', error);
      message.error('Failed to save clause.');
    }
  };

  const handleTestResultClick = (index) => {
    setCurrentTestIndex(index);
    setIsTestModalVisible(true);
  };

  const alertDefinitionsColumns = [
    {
      title: 'ID',
      dataIndex: 'ID',
      key: 'ID',
      width: '10%',
    },
    {
      title: 'Alert Type',
      dataIndex: 'alert_type',
      key: 'alert_type',
      width: '10%',
    },
    {
      title: 'Alert Subtype',
      dataIndex: 'alert_subtype',
      key: 'alert_subtype',
      width: '10%',
    },
    {
      title: 'Advice Text',
      dataIndex: 'advice_txt',
      key: 'advice_txt',
      width: '70%',
    },
    {
      title: 'Clauses',
      dataIndex: 'clauses',
      key: 'clauses',
      width: '20%',
    },
    {
      title: 'Action',
      key: 'action',
      width: '10%',
      render: (_, record) => (
        <Button type="link" onClick={() => handleDeleteAlert(record.ID)}>Delete</Button>
      ),
    },
  ];

  const clauseColumns = [
    {
      title: 'ID',
      dataIndex: 'ID',
      key: 'ID',
      width: '2%',
    },
    {
      title: 'Clause Field',
      dataIndex: 'clause_field',
      key: 'clause_field',
      width: '10%',
    },
    {
      title: 'Source Table',
      dataIndex: 'clause_field_sourcetable',
      key: 'clause_field_sourcetable',
      width: '10%',
    },
    {
      title: 'Operator',
      dataIndex: 'clause_operator',
      key: 'clause_operator',
      width: '4%',
    },
    {
      title: 'Threshold',
      dataIndex: 'threshold',
      editable: true,
      width: '4%',
    },
    {
      title: 'Threshold Field',
      dataIndex: 'threshold_field',
      editable: true,
      width: '10%',
    },
    {
      title: 'Threshold Source Table',
      dataIndex: 'threshold_sourcetable',
      editable: true,
      width: '10%',
    },
    {
      title: 'Clause Text',
      dataIndex: 'clause_txt',
      editable: true,
      width: 'auto'
    },
  ];

  return (
    <div>
      <h2>Alert Configuration</h2>
      <Row>
        <Col span={24}>
          <Form.Item label="Search Alert by ID">
            <Input.Search
              placeholder="Enter Alert ID"
              enterButton="Search"
              onSearch={handleSearchAlertById}
              style={{ width: '100%' }}
              value={idSearch}
              onChange={e => setIdSearch(e.target.value)}
            />
          </Form.Item>
        </Col>
      </Row>

      <Row>
        <Col span={24}>
          <Form.Item label="Alert Type">
            <Input
              value={alerttype}
              onChange={e => setAlerttype(e.target.value)}
              placeholder="Enter alert type"
              style={{ width: '100%' }}
            />
          </Form.Item>
        </Col>
      </Row>
      <Row>
        <Col span={24}>
          <Form.Item label="Alert SubType">
            <Input
              value={alertsubtype}
              onChange={e => setAlertsubtype(e.target.value)}
              placeholder="Enter alert type"
              style={{ width: '100%' }}
            />
          </Form.Item>
        </Col>
      </Row>
      <Row>
        <Col span={24}>
          <Form.Item label="Advice">
            <Input
              value={advice}
              onChange={e => setAdvice(e.target.value)}
              placeholder="Enter advice"
              style={{ width: '100%' }}
            />
          </Form.Item>
        </Col>
      </Row>

      {clauses.map((clause, index) => (
        <Form key={index} style={{ marginBottom: '10px' }}>
          <Row gutter={1}>
            <Col span={3}>
              <Form.Item label="Clause ID Search">
                <Input
                  placeholder="Enter ID"
                  value={clause.idSearch}
                  onChange={e => handleIDSearch(e.target.value, index)}
                />
              </Form.Item>
            </Col>
            <Col span={5}>
              <Form.Item label="Clause Field">
                <Input
                  value={clause.clauseField}
                  onChange={e => handleClauseChange(e.target.value, index, 'clauseField')}
                />
              </Form.Item>
            </Col>
            <Col span={5}>
              <Form.Item label="CF Source Table">
                <Input
                  value={clause.cfSourceTable}
                  onChange={e => handleClauseChange(e.target.value, index, 'cfSourceTable')}
                />
              </Form.Item>
            </Col>
            <Col span={4}>
              <Form.Item label="Operator">
                <Select
                  value={clause.operator}
                  onChange={value => handleClauseChange(value, index, 'operator')}
                  style={{ width: '100%' }}
                >
                  <Option value=">">{'>'}</Option>
                  <Option value="<">{'<'}</Option>
                  <Option value="=">{'='}</Option>
                  <Option value="!=">{'!='}</Option>
                </Select>
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={16}>
            <Col span={4}>
              <Form.Item label="Threshold">
                <Input
                  value={clause.threshold}
                  onChange={e => handleClauseChange(e.target.value, index, 'threshold')}
                />
              </Form.Item>
            </Col>
            <Col span={4}>
              <Form.Item label="Threshold Field">
                <Input
                  value={clause.thresholdField}
                  onChange={e => handleClauseChange(e.target.value, index, 'thresholdField')}
                />
              </Form.Item>
            </Col>
            <Col span={4}>
              <Form.Item label="Threshold Source Table">
                <Input
                  value={clause.thresholdSourceTable}
                  onChange={e => handleClauseChange(e.target.value, index, 'thresholdSourceTable')}
                />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item label="Clause Text">
                <Input.TextArea
                  value={clause.clause_txt}
                  onChange={e => handleClauseChange(e.target.value, index, 'clause_txt')}
                  autoSize={{ minRows: 3, maxRows: 6 }}  // Adjusts height based on content
                  allowClear
                  style={{ width: '100%' }}
                />
              </Form.Item>
            </Col>
          </Row>
          <div style={{ textAlign: 'center', marginTop: '10px' }}>
            <Button onClick={() => handleTestClause(index)} type="primary" style={{ marginRight: '8px' }}>
              Test
            </Button>
            {testResults[index] !== undefined && (
              <a onClick={() => handleTestResultClick(index)} style={{ marginRight: '8px', cursor: 'pointer' }}>
                Test result: {testResults[index]}
              </a>
            )}
            <Button onClick={() => handleSaveClause(index)} type="primary" disabled={!clause.isModified}>
              Save Clause
            </Button>
            <MinusCircleOutlined
              onClick={() => handleRemoveClause(index)}
              style={{ color: 'red', marginLeft: '10px', cursor: 'pointer', fontSize: '16px' }}
            />
          </div>
        </Form>
      ))}

      <Row>
        <Col>
          <Button type="dashed" onClick={handleAddClause}>Add Clause</Button>
        </Col>
        <Col>
          <Button type="primary" onClick={handleTestCombinedClauses} style={{ margin: '0 8px' }}>
            Test Combined Clauses
          </Button>
          {combinedTestCount !== null && (
            <span 
              style={{ marginLeft: '10px', color: 'blue', textDecoration: 'underline', cursor: 'pointer' }} 
              onClick={() => setIsModalVisible(true)}
            >
              True Count: {combinedTestCount}
            </span>
          )}
          {firstAlert && (
            <div>
              <strong>Sample Alert for User ID {firstAlert.user_id}:</strong>
              <p>{firstAlert.alert_text}</p>
            </div>
          )}
        </Col>
      </Row>
      <Button
          type="primary"
          onClick={handleSaveAlert}
          disabled={!alerttype.trim() || !advice.trim() || clauses.length === 0 || clauses.every(clause => !clause.clauseField.trim())}
          style={{ marginTop: '10px', marginBottom: '10px' }}
        >
          Save Alert
        </Button>

      <Divider />

      <div style={{ marginBottom: '10px', height: '300px', overflow: 'auto' }}>
        <Table
          columns={alertDefinitionsColumns}
          dataSource={alertDefinitions}
          pagination={false}
          scroll={{ y: 240 }}
          size="small"
          bordered
        />
      </div>

      <div style={{ height: '200px', overflow: 'auto' }}>
        <Table
          className="small-font-table"
          columns={clauseColumns}
          dataSource={data}
          pagination={false}
          scroll={{ y: 240 }}
          size="small"
        />
      </div>

      <Modal
        title="Test Results"
        visible={isModalVisible}
        onOk={() => setIsModalVisible(false)}
        onCancel={() => setIsModalVisible(false)}
        width={800}
      >
        <Table
          columns={[
            { title: 'Alert ID', dataIndex: 'alert_id', key: 'alert_id' },
            { title: 'User ID', dataIndex: 'user_id', key: 'user_id' },
            { title: 'Ticker', dataIndex: 'ticker', key: 'ticker' },
            { title: 'Alert Text', dataIndex: 'alert_text', key: 'alert_text' },
          ]}
          dataSource={alerts.map((alert, index) => ({ ...alert, key: index }))}
          pagination={false}
        />
      </Modal>

      <Modal
        title="Clause Test Results"
        visible={isTestModalVisible}
        onOk={() => setIsTestModalVisible(false)}
        onCancel={() => setIsTestModalVisible(false)}
        width={800}
      >
        <Table
          columns={[
            { title: 'Ticker', dataIndex: 'ticker', key: 'ticker' },
            { title: 'User ID', dataIndex: 'userid', key: 'userid' },
            { title: 'Clause Field', dataIndex: 'clause_field', key: 'clause_field' },
            { title: 'Clause Value', dataIndex: 'clause_value', key: 'clause_value' },
            { title: 'Operator', dataIndex: 'operator', key: 'operator' },
            { title: 'Configured Threshold', dataIndex: 'configured_threshold', key: 'configured_threshold' },
            { title: 'Evaluated Threshold', dataIndex: 'evaluated_threshold', key: 'evaluated_threshold' },
            { title: 'Threshold Field Value', dataIndex: 'threshold_field_value', key: 'threshold_field_value' },
          ]}
          dataSource={detailedResults[currentTestIndex]?.map((result, index) => ({ ...result, key: index }))}
          pagination={false}
        />
      </Modal>
    </div>
  );
};

export default AlertConfigure;
