import React from 'react';
import { PieChart, Pie, Tooltip, Cell, ResponsiveContainer } from 'recharts';

const PieChartComponent = ({
  data,
  title,
  width = "100%",
  height = 230,
  outerRadius = 80,
  titleFontSize = '16px', // Default title font size
  labelFontSize = '12px', // Default label font size
  colors = ['#0088FE', '#00C49F', '#FFBB28', '#FF8042', '#8884d8'], // Default colors
}) => {
  // Function to get the color based on index
  const getColor = (index) => colors[index % colors.length];

  // Simplified label rendering
  const renderCustomLabel = ({ cx, cy, midAngle, innerRadius, outerRadius, percent, index }) => {
    const RADIAN = Math.PI / 180;
    // Adjust this radius to move labels closer or further away from the pie chart
    const radius = 10 + innerRadius + (outerRadius - innerRadius) / 2; // Adjusted radius
    const x = cx + radius * Math.cos(-midAngle * RADIAN);
    const y = cy + radius * Math.sin(-midAngle * RADIAN);
  
    return (
      <text
        x={x}
        y={y}
        fill="black"
        fontSize={labelFontSize}
        textAnchor={x > cx ? 'start' : 'end'}
        dominantBaseline="central"
      >
        {`${(percent * 100).toFixed(0)}%`}
      </text>
    );
  };
  

  return (
    <div>
      <h2 style={{ textAlign: 'center', fontSize: titleFontSize }}>{title}</h2>
      <ResponsiveContainer width={width} height={height}>
        <PieChart>
          <Pie
            dataKey="value"
            isAnimationActive={false}
            data={data}
            cx="50%"
            cy="50%"
            outerRadius={outerRadius}
            labelLine={false} // Disable the label lines
            label={renderCustomLabel} // Use custom label rendering
          >
            {data.map((entry, index) => (
              <Cell key={`cell-${index}`} fill={getColor(index)} />
            ))}
          </Pie>
          <Tooltip formatter={(value) => `${value}%`} />
        </PieChart>
      </ResponsiveContainer>
    </div>
  );
};

export default PieChartComponent;


// import React from 'react';
// import { PieChart, Pie, Tooltip, Cell, ResponsiveContainer } from 'recharts';

// const PieChartComponent = ({
//   data,
//   title,
//   width = "100%",
//   height = 230,
//   outerRadius = 80,
//   titleFontSize = '16px', // Default title font size
//   labelFontSize = '12px', // Default label font size
// }) => {
//   const COLORS = ['#0088FE', '#00C49F', '#FFBB28', '#FF8042', '#8884d8'];
//   const getColor = (index) => COLORS[index % COLORS.length];

//   const renderCustomLabel = ({ name, percent }) => {
//     return (
//       <text
//         style={{ fontSize: labelFontSize }} // Custom label font size
//         fill="#000"
//         textAnchor="middle"
//         dominantBaseline="central"
//       >
//         {`${name}: ${(percent * 100).toFixed(0)}%`}
//       </text>
//     );
//   };

//   return (
//     <div>
//       <h2 style={{ textAlign: 'center', fontSize: titleFontSize }}>{title}</h2>
//       <ResponsiveContainer width={width} height={height}>
//         <PieChart>
//           <Pie
//             dataKey="value"
//             isAnimationActive={false}
//             data={data}
//             cx="50%"
//             cy="50%"
//             outerRadius={outerRadius}
//             label={renderCustomLabel} // Use custom label rendering
//           >
//             {data.map((entry, index) => (
//               <Cell key={`cell-${index}`} fill={getColor(index)} />
//             ))}
//           </Pie>
//           <Tooltip formatter={(value) => `${value}%`} />
//         </PieChart>
//       </ResponsiveContainer>
//     </div>
//   );
// };

// export default PieChartComponent;


// // import React from 'react';
// // import { PieChart, Pie, Tooltip, Cell, Legend, ResponsiveContainer } from 'recharts';

// // const PieChartComponent = ({
// //   data,
// //   title,
// //   width = "100%",
// //   height = 230,
// //   outerRadius = 80,
// //   titleFontSize = '16px', // Default title font size
// //   labelFontSize = '12px', // Default label font size
// // }) => {
// //   const COLORS = ['#0088FE', '#00C49F', '#FFBB28', '#FF8042', '#8884d8'];
// //   const getColor = (index) => COLORS[index % COLORS.length];

// //   return (
// //     <div>
// //       <h2 style={{ textAlign: 'center', fontSize: titleFontSize }}>{title}</h2>
// //       <ResponsiveContainer width={width} height={height}>
// //         <PieChart>
// //           <Pie
// //             dataKey="value"
// //             isAnimationActive={false}
// //             data={data}
// //             cx="50%"
// //             cy="50%"
// //             outerRadius={outerRadius}
// //             label={({ name, percent }) => `${name}: ${(percent * 100).toFixed(0)}%`} // Use a simple string label
// //           >
// //             {data.map((entry, index) => (
// //               <Cell key={`cell-${index}`} fill={getColor(index)} />
// //             ))}
// //           </Pie>
// //           <Tooltip formatter={(value) => `${value}%`} />
// //           {/* <Legend wrapperStyle={legendStyle} /> */}
// //         </PieChart>
// //       </ResponsiveContainer>
// //     </div>
// //   );
// // };

// // export default PieChartComponent;

// // import React from 'react';
// // import { PieChart, Pie, Tooltip, Cell, Legend, ResponsiveContainer } from 'recharts';

// // const PieChartComponent = ({
// //   data,
// //   title,
// //   width = "100%",
// //   height = 230,
// //   outerRadius = 80,
// //   titleFontSize = '16px', // Default title font size
// //   labelFontSize = '12px', // Default label font size
// // }) => {
// //   const COLORS = ['#0088FE', '#00C49F', '#FFBB28', '#FF8042', '#8884d8'];
// //   const getColor = (index) => COLORS[index % COLORS.length];

// //   const legendStyle = {
// //     fontSize: '10px', // You can adjust the font size here
// //   };

// //   return (
// //     <div>
// //       <h2 style={{ textAlign: 'center', fontSize: titleFontSize }}>{title}</h2>
// //       <ResponsiveContainer width={width} height={height}>
// //         <PieChart>
// //           <Pie
// //             dataKey="value"
// //             isAnimationActive={false}
// //             data={data}
// //             cx="50%"
// //             cy="50%"
// //             outerRadius={outerRadius}
// //             label={({ name, percent }) => (
// //               <text style={{ fontSize: labelFontSize }}>{`${name}: ${(percent * 100).toFixed(0)}%`}</text>
// //             )}
// //           >
// //             {data.map((entry, index) => (
// //               <Cell key={`cell-${index}`} fill={getColor(index)} />
// //             ))}
// //           </Pie>
// //           <Tooltip formatter={(value) => `${value}%`} />
// //           {/* <Legend wrapperStyle={legendStyle} /> */}
// //         </PieChart>
// //       </ResponsiveContainer>
// //     </div>
// //   );
// // };

// // export default PieChartComponent;


// // import React from 'react';
// // import { PieChart, Pie, Tooltip, Cell, Legend, ResponsiveContainer } from 'recharts';

// // const PieChartComponent = ({ data, title, width = "100%", height = 230, outerRadius = 80 }) => {
// //   const COLORS = ['#0088FE', '#00C49F', '#FFBB28', '#FF8042', '#8884d8'];
// //   const getColor = (index) => COLORS[index % COLORS.length];

// //   const legendStyle = {
// //     fontSize: '10px', // You can adjust the font size here
// //   };

// //   return (
// //     <div>
// //       <h2 style={{ textAlign: 'center' }}>{title}</h2>
// //       <ResponsiveContainer width={width} height={height}>
// //         <PieChart>
// //           <Pie
// //             dataKey="value"
// //             isAnimationActive={false}
// //             data={data}
// //             cx="50%"
// //             cy="50%"
// //             outerRadius={outerRadius}
// //             label={({ name, percent }) => `${name}: ${(percent * 100).toFixed(0)}%`}
// //           >
// //             {data.map((entry, index) => (
// //               <Cell key={`cell-${index}`} fill={getColor(index)} />
// //             ))}
// //           </Pie>
// //           <Tooltip formatter={(value) => `${value}%`} />
// //           {/* <Legend wrapperStyle={legendStyle} /> */}
// //         </PieChart>
// //       </ResponsiveContainer>
// //     </div>
// //   );
// // };

// // export default PieChartComponent;

// // import React from 'react';
// // import { PieChart, Pie, Tooltip, Cell, Legend, ResponsiveContainer } from 'recharts';

// // const PieChartComponent = ({ data, title }) => {
// //   const COLORS = ['#0088FE', '#00C49F', '#FFBB28', '#FF8042', '#8884d8'];
// //   const getColor = (index) => COLORS[index % COLORS.length];

// //   const legendStyle = {
// //     fontSize: '10px', // You can adjust the font size here
// //   };

  

// //   return (
// //     <div>
// //       <h2 style={{ textAlign: 'center' }}>{title}</h2>
// //       <ResponsiveContainer width="100%" height={230}>
// //         <PieChart>
// //           <Pie
// //             dataKey="value"
// //             isAnimationActive={false}
// //             data={data}
// //             cx="50%"
// //             cy="50%"
// //             outerRadius={80}
// //             label={({ name, percent }) => `${name}: ${(percent * 100).toFixed(0)}%`}
// //           >
// //             {data.map((entry, index) => (
// //               <Cell key={`cell-${index}`} fill={getColor(index)} />
// //             ))}
// //           </Pie>
// //           <Tooltip formatter={(value) => `${value}%`} />
// //           {/* <Legend wrapperStyle={legendStyle} />  */}
// //         </PieChart>
// //       </ResponsiveContainer>
// //     </div>
// //   );
// // };

// // export default PieChartComponent;
