import React from 'react';
import { Modal, Button } from 'antd';
import ESG_DetailsContent from './ESG_DetailsContent';
import DownloadPDF from './DownloadPDF';

const ESG_Details = ({ visible, onClose, ticker, details }) => {
  console.log("Details in ESG_Details: ", details);

  // Generate the dynamic filename
  const pdfFilename = `ESG data on ${details.company}`;

  return (
    <Modal
      visible={visible}
      title={`ESG Details for ${details.company} (${ticker})`} // Include company name and ticker
      onCancel={onClose}
      width={800} // Set the width of the modal (you can adjust this value)
      footer={[
        details && (
          <DownloadPDF
            key="download"
            contentId="ESG_Details-content"
            filename={pdfFilename}  // Use the dynamic filename here
          />
        ),
        <Button key="back" onClick={onClose}>
          Cancel
        </Button>,
        <Button key="submit" type="primary" onClick={onClose}>
          OK
        </Button>,
      ]}
    >
      <ESG_DetailsContent ticker={ticker} details={details} />
      {/* Add the italic text above the footer buttons */}
      <div style={{ marginTop: '1px', textAlign: 'center' }}>
        <span style={{ fontStyle: 'italic', fontSize: 'small' }}>
          click on values to open browsertab to sourcepage
        </span>
      </div>
    </Modal>
  );
};

export default ESG_Details;
