// Support.js

import React, { useState } from 'react';
import { Button } from 'antd';
// import 'antd/dist/antd.css'; // Import Ant Design styles


const Support = () => {
  const [formData, setFormData] = useState({ name: '', email: '', message: '' });

  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    // Implement submission logic here, such as sending an email
    console.log('Form data:', formData);
    alert('Your message has been submitted!');
    setFormData({ name: '', email: '', message: '' }); // Reset form after submission
  };

  return (
    <div className="support-container">
      <h1>Support Page</h1>
      <p>If you have any questions or need assistance, please contact us at <a href="mailto:monosoft@gmail.com">monosoft@gmail.com</a>.</p>
      
      <h2>Frequently Asked Questions (FAQ)</h2>
      <div className="faq-section">
        <h3>Is this service free to use?</h3>
        <p>Yes, our service is completely free for all users.</p>

        <h3>Do you use cookies?</h3>
        <p>No, we do not use cookies on our platform.</p>

        <h3>Do you share information with third parties?</h3>
        <p>No, we do not share any information with third parties.</p>
      </div>

      <h2>Contact Us</h2>
      <form onSubmit={handleSubmit}>
        <div>
          <label>Name:</label>
          <input type="text" name="name" value={formData.name} onChange={handleChange} required />
        </div>
        <div>
          <label>Email:</label>
          <input type="email" name="email" value={formData.email} onChange={handleChange} required />
        </div>
        <div>
          <label>Message:</label>
          <textarea name="message" value={formData.message} onChange={handleChange} required></textarea>
        </div>
        <Button type="primary" htmlType="submit" style={{ backgroundColor: '#1677ff' }}>
          Submit
        </Button>
      </form>

      <style jsx>{`
                .support-container {
                  padding: 20px;
                  font-family: Arial, sans-serif;
                }
                form {
                  margin-top: 20px;
                }
                label {
                  display: block;
                  margin-bottom: 5px;
                }
                input, textarea {
                  width: 100%;
                  padding: 8px;
                  margin-bottom: 10px;
                }
                button {
                  padding: 10px 15px;
                  background-color: blue;
                  color: white;
                  border: none;
                  cursor: pointer;
                }
      `}</style>
    </div>
  );
};

export default Support;
