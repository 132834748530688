
//Search_Generic.js

///Search_Generic.js

import React, { useState, useRef, useEffect } from 'react';
import { AutoComplete, Input, Space, Button, Modal, message } from 'antd';
import { CloseCircleOutlined } from '@ant-design/icons';
import getUserId from './getUserId';
import api from './api';

const SearchBox2 = ({ onSelectTicker }) => {  // Add the onSelectTicker callback prop
  const [searchValue, setSearchValue] = useState('');
  const [options, setOptions] = useState([]);
  const searchInputRef = useRef(null);
  const token = localStorage.getItem('token');
  const [additionalInfo, setAdditionalInfo] = useState('');

  const handleInputChange = (event) => {
    setAdditionalInfo(event.target.value);
  };

  const handleClear = () => {
    setSearchValue('');
    setOptions([]);
  };

  const handleSearch = async (value) => {
    setSearchValue(value);
    if (!value ) 
    // if (!value || !token) 
    {
      return;
    }
    try {
      const response = await api.get(`/search_suggestions_esg?query=${value}`);
      const autoCompleteOptions = response.data.map((suggestion) => ({ value: suggestion }));
      setOptions(autoCompleteOptions);
      searchInputRef.current.focus();
    } catch (error) {
      console.error('Error fetching search suggestions:', error);
    }
  };

  const handleSelect = (value) => {
    const ticker = value.split(' - ')[0];
    setSearchValue(ticker);
    if (ticker) {
      onSelectTicker(ticker);  // Call the callback function to pass the selected ticker to the parent
    }
  };

  return (
    <Space direction="vertical">
      <AutoComplete
        options={options}
        onSelect={handleSelect}
        onSearch={handleSearch}
        value={searchValue}
      >
        <Input.Search
          ref={searchInputRef}
          placeholder="ticker or stockname"
          enterButton
         
          suffix={
            searchValue && (
              <CloseCircleOutlined
                style={{ cursor: 'pointer' }}
                onClick={handleClear}
              />
            )
          }
        />
      </AutoComplete>
      {/* Removed showMessage and ThreeTablesModal */}
    </Space>
  );
};

export default SearchBox2;

