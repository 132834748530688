// src/components/ESGChecker.jsx

import React, { useEffect, useState } from 'react';
import styles from '../css/BondBot.module.css';
import globeImage from '../images/DALL·E 2024-08-09 12.44.36 - An abstract globe centered in the image, surrounded by minimalist icons representing environmental and social factors. The globe is softly textured, w.webp';
import ESGCheck from './ESGCheck';

const ESGChecker = () => {
  const [isESGCheckVisible, setIsESGCheckVisible] = useState(false);

  useEffect(() => {
    // Hotjar Tracking Code for https://monosoft.online/esgcheck
    (function(h, o, t, j, a, r) {
      h.hj = h.hj || function() { (h.hj.q = h.hj.q || []).push(arguments) };
      h._hjSettings = { hjid: 5123867, hjsv: 6 };
      a = o.getElementsByTagName('head')[0];
      r = o.createElement('script'); r.async = 1;
      r.src = t + h._hjSettings.hjid + j + h._hjSettings.hjsv;
      a.appendChild(r);
    })(window, document, 'https://static.hotjar.com/c/hotjar-', '.js?sv=');

    // Google Analytics Tracking Code for https://monosoft.online/esgcheck
    const gaScript = document.createElement('script');
    gaScript.async = true;
    gaScript.src = 'https://www.googletagmanager.com/gtag/js?id=G-89HYYV814R';
    document.head.appendChild(gaScript);

    gaScript.onload = () => {
      window.dataLayer = window.dataLayer || [];
      function gtag() { window.dataLayer.push(arguments); }
      gtag('js', new Date());
      gtag('config', 'G-89HYYV814R');
    };

    // Cleanup scripts if component unmounts or the page changes
    return () => {
      const hotjarScript = document.querySelector('script[src^="https://static.hotjar.com/c/hotjar-"]');
      if (hotjarScript) {
        hotjarScript.remove();
      }

      const gaScriptTag = document.querySelector('script[src="https://www.googletagmanager.com/gtag/js?id=G-89HYYV814R"]');
      if (gaScriptTag) {
        gaScriptTag.remove();
      }
    };
  }, []); // Run this effect only once when the component mounts

  const showESGCheck = () => {
    setIsESGCheckVisible(true);
  };

  const handleESGCheckClose = () => {
    setIsESGCheckVisible(false);
  };

  return (
    <div className={styles.bondBot}>
      <img
        className={styles.dalle20240809124436A}
        alt="ESG Globe"
        src={globeImage}
      />
      <div className={styles.forYourConsciousInvestmentWrapper}>
        <div className={styles.forYourConscious}>
          Managing sustainability risks and your investment decisions
        </div>
      </div>
      <section className={styles.doYouValueContainer}>
        <p className={styles.doYouValueSociallyResponsi}>
          <span>
            <b>Are you concerned with how sustainability risks are being managed by companies of your interest?</b><br />
          </span>
        </p>
        <p className={styles.blankLine}><span><span>&nbsp;</span></span></p>
        <p className={styles.weDoToo}><span><i>We do too!</i></span></p>
        <p className={styles.blankLine1}>&nbsp;</p>
        <p className={styles.youveProbablyHeard}>You’ve probably heard about ‘ESG’ data. Maybe your bank even shows ESG ratings for different companies.</p>
        <p className={styles.blankLine2}>&nbsp;</p>
        <p className={styles.butDidYou}>But did you know there are multiple rating agencies, each with their own criteria?</p>
        <p className={styles.howDoThese}>How do these ratings compare across different agencies?</p>
        <p className={styles.blankLine3}>&nbsp;</p>
        <p className={styles.andWhatAbout}>And what about Exchange Traded Funds (ETFs)? How do the ESG ratings of the top holdings in those ETFs stack up?</p>
        <p className={styles.blankLine4}>&nbsp;</p>
        <p className={styles.ifYouAlready}>If you already have a diverse portfolio, how can you quickly get an overview without looking up each investment individually?</p>
        <p className={styles.blankLine5}>&nbsp;</p>
        <p className={styles.ourEsgCheckTool}>Our ESG-Check tool is here to help.</p>
        <p className={styles.blankLine6}>&nbsp;</p>
        <p className={styles.itsFreeEasy}>It’s free, easy to use, and doesn’t require registration.</p>
      </section>
      {/* Render ESGCheck directly underneath the text */}
      <div className={styles.esgCheckContainer}>
        <ESGCheck />
      </div>
    </div>
  );
};

export default ESGChecker;
