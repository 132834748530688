// PF_Analysis.jsx
import React from 'react';
import { useSelector } from 'react-redux';
import PieChartComponent from './PieCharts'; // Assuming you'll use this for some data visualization
import '../PF_Analysis.css';
import ToplineTable from './ToplineTable'; // Assuming you might want to use this for a detailed table

const PF_Analysis = () => {
  const portfolioData = useSelector(state => state.portfolio.data);
  const demoMode = useSelector(state => state.demoMode.isDemoMode);

  // Check if portfolioData or portfolio_topline is not available or empty
  if (!portfolioData || !portfolioData.portfolio_topline || portfolioData.portfolio_topline.length === 0) {
    return <div>No data available</div>; // or any other fallback UI
  }

  // Assuming you are interested in the first item of the portfolio_topline array
  const toplineData = portfolioData.portfolio_topline[0];

  // Helper function to apply blur conditionally
  const maybeBlur = (text) => demoMode ? <span className="blur-text">{text}</span> : text;

  return (
    <div className="portfolio-analysis">
      <h2>Portfolio Overview</h2>
      <ul>
        <li>Portfolio Total Value: {maybeBlur(toplineData.portfolio_total_value)}</li>
        <li>Cash (EUR): {maybeBlur(toplineData.cash_eur)}</li>
        <li>Portfolio Result: {maybeBlur(toplineData.portfolio_result)}</li>
        <li>Dividend Income: {maybeBlur(toplineData.div_income)}</li>
        <li>Portfolio Performance (%): {toplineData.portfolio_performance}</li>
        <li>Performance Change (%): {toplineData.performance_change}</li>
        <li>Total Realized Results (Current Positions): {maybeBlur(toplineData.total_realized_results_current_positions)}</li>
        <li>Total Unrealized Profit/Loss (EUR): {maybeBlur(toplineData.total_unrealized_prof_loss_Eur)}</li>
        <li>Cash (EUR) Part of Portfolio (%): {toplineData.cash_eur_part_of_portfolio}</li>
        <li>Total Transactions Cost: {toplineData.total_transactions_cost}</li>
        <li>Total Transactions: {toplineData.total_transactions}</li>
        <li>Positive Performance Records: {toplineData.positive_performance_records}</li>
        <li>Positive Performance Records (%): {toplineData.positive_performance_records_perc}</li>
        <li>Positive Performance Records Unknown: {toplineData.positive_performance_records_unknown}</li>
        <li>Exceeding Benchmark Last Transaction Records: {toplineData.exceeding_benchmark_lasttx_records}</li>
        <li>Exceeding Benchmark Last Transaction Records (%): {toplineData.exceeding_benchmark_lasttx_records_perc}</li>
        <li>Exceeding Benchmark Last Transaction Records Unknown: {toplineData.exceeding_benchmark_lasttx_records_unknown}</li>
        <li>Positive Performance YTD Records: {toplineData.positive_performance_ytd_records}</li>
        <li>Positive Performance YTD Records (%): {toplineData.positive_performance_ytd_records_perc}</li>
        <li>Positive Performance YTD Records Unknown: {toplineData.positive_performance_ytd_records_unknown}</li>
        <li>Exceeding Benchmark YTD Records: {toplineData.exceeding_benchmark_ytd_records}</li>
        <li>Exceeding Benchmark YTD Records (%): {toplineData.exceeding_benchmark_ytd_records_perc}</li>
        <li>Exceeding Benchmark YTD Records Unknown: {toplineData.exceeding_benchmark_ytd_records_unknown}</li>
      </ul>
    </div>
  );
};

export default PF_Analysis;



