import React, { useState, useEffect } from 'react';

import { Modal, Table,  Button, InputNumber, Switch } from 'antd';
import api from '../functions/api';

const CategoryPreferencesModal = ({ isVisible, setIsVisible }) => {
    const [categoryPreferences, setCategoryPreferences] = useState([]);
    const [marketUpdates, setMarketUpdates] = useState([]);
    const [editedPreferences, setEditedPreferences] = useState({});
    const [changesMade, setChangesMade] = useState(false);


    const handleBenchmarkChange = (newValue, benchmarkType, recordId) => {
        console.log(`Changed ${benchmarkType} to ${newValue}`);
        
        // Update the editedPreferences state
        const updated = { ...editedPreferences };
        if (!updated[recordId]) {
            updated[recordId] = {};
        }
        updated[recordId][benchmarkType.toLowerCase()] = newValue;
        setEditedPreferences(updated);
    
        // Indicate a change has been mad
        setChangesMade(true);
    }
    
    
    const handleValueChange = (recordId, column, value) => {
        const updated = { ...editedPreferences };
        if (!updated[recordId]) {
            updated[recordId] = {};
        }
        updated[recordId][column] = value;
        setEditedPreferences(updated);
        setChangesMade(true); // Set to true whenever a change is made
    };
    

    const handleSave = async () => {
        const dataToSend = categoryPreferences.map((category) => {
            let editedData = editedPreferences[category.ID] || {};
            return { ...category, ...editedData };
        });
        
        const storedToken = localStorage.getItem('token');
        try {
            const response = await api.post('/save_category_preferences', dataToSend, {
                headers: { Authorization: `Bearer ${storedToken}` }
            });
    
            if (response.status === 200) {
                console.log("Data saved successfully");
            } else {
                console.error("Error saving data:", response.data.message);
            }
            
            setChangesMade(false);
        } catch (error) {
            console.error('Error saving category preferences:', error);
        }
    };
    
    const renderCategoryDetails = (text, record) => {
        return (
            <div>
                <div style={{ fontSize: '12px' }}>Category: <strong>{text}</strong></div>
                <div style={{ fontSize: '10px' }}>Number of Stocks: {record.number_of_stocks}</div>
                <div style={{ fontSize: '10px' }}>Part of Portfolio: {record.part_of_portfolio}%</div>
                <div style={{ fontSize: '10px' }}>Performance: {record.performance}%</div>
            </div>
        );
    };

const generateColumns = () => {
    if (categoryPreferences && categoryPreferences.length > 0) {
        // const samplePreference = categoryPreferences[0];

        const specialRenderers = {
            latest_tx_type_buy: (text, record) => {
                const currentValue = editedPreferences[record.ID]?.latest_tx_type_buy ?? text;
                return (
                    <Switch
                        checked={currentValue}
                        checkedChildren="Yes"
                        unCheckedChildren="No"
                        onChange={(isChecked) => handleValueChange(record.ID, 'latest_tx_type_buy', isChecked)}
                    />
                );
            },

            benchmark_treshold_for_harvesting: (text, record) => {
                const currentValue = editedPreferences[record.ID]?.benchmark_treshold_for_harvesting ?? text;
                return (
                    <Switch
                        checked={currentValue}
                        checkedChildren="Yes"
                        unCheckedChildren="No"
                        onChange={(isChecked) => handleValueChange(record.ID, 'benchmark_treshold_for_harvesting', isChecked)}
                    />
                );
            },
            


            category: (text, record) => {
                return (
                    <div>
                         <div style={{ fontSize: '12px' }}>Category: <strong>{text}</strong></div>
                            <div style={{ fontSize: '8px' }}>Number of Stocks: {record.number_of_stocks}</div>
                            <div style={{ fontSize: '8px' }}>Part of Portfolio: {record.part_of_portfolio}%</div>
                            <div style={{ fontSize: '8px' }}>Performance: {record.performance}%</div>
                    </div>
                );
            },

            benchmark_minimum_decrease_treshold_for_accumulate: (text, record) => {
                const currentValue = editedPreferences[record.ID]?.benchmark_minimum_decrease_treshold_for_accumulate ?? text ?? 0;
                console.log('Current Value:', currentValue, 'Text:', text, 'Record ID:', record.ID, 'Full Record:', record);
                return (
                    <InputNumber 
                        min={-20} 
                        max={0} 
                        step={0.1} 
                        value={currentValue}
                        formatter={value => `${value}%`}
                        parser={value => value.replace('%', '')}
                        onChange={(value) => handleValueChange(record.ID, 'benchmark_minimum_decrease_treshold_for_accumulate', value)}
                    />
                );
            },

            one_day_perf_accumulate_treshold: (text, record) => {
                const currentValue = editedPreferences[record.ID]?.one_day_perf_accumulate_treshold ?? text ?? 0;
                console.log('Current Value:', currentValue, 'Text:', text, 'Record ID:', record.ID, 'Full Record:', record);
                return (
                    <InputNumber 
                        min={-20} 
                        max={0} 
                        step={0.1} 
                        value={currentValue}
                        formatter={value => `${value}%`}
                        parser={value => value.replace('%', '')}
                        onChange={(value) => handleValueChange(record.ID, 'one_day_perf_accumulate_treshold', value)}
                    />
                );
            },
            
            accumulate_perc: (text, record) => {
                const currentValue = editedPreferences[record.ID]?.accumulate_perc ?? text ?? 0;
                console.log('Current Value:', currentValue, 'Text:', text, 'Record ID:', record.ID, 'Full Record:', record);
                return (
                    <InputNumber 
                        min={0} 
                        max={100} 
                        step={0.1} 
                        value={currentValue}
                        formatter={value => `${value}%`}
                        parser={value => value.replace('%', '')}
                        onChange={(value) => handleValueChange(record.ID, 'accumulate_perc', value)}
                    />
                );
            },
            
            
            

            harvest_alert_treshold: (text, record) => {
                const currentValue = editedPreferences[record.ID]?.harvest_alert_treshold || text;
                return (
                    <InputNumber 
                        min={0} 
                        max={100} 
                        step={0.1} // setting the step to 0.1
                        value={currentValue}
                        formatter={value => `${value}%`}
                        parser={value => value.replace('%', '')}
                        onChange={(value) => handleValueChange(record.ID, 'harvest_alert_treshold', value)}
                    />
                );
            },
            harvest_perc: (text, record) => {
                const currentValue = editedPreferences[record.ID]?.harvest_perc || text;
                return (
                    <InputNumber 
                        min={0} 
                        max={100} 
                        step={0.1} // setting the step to 0.1
                        value={currentValue}
                        formatter={value => `${value}%`}
                        parser={value => value.replace('%', '')}
                        onChange={(value) => handleValueChange(record.ID, 'harvest_perc', value)}
                    />
                );
            },

            PE_threshold: (text, record) => {
                const currentValue = editedPreferences[record.ID]?.PE_threshold || text;
                return (
                    <InputNumber 
                        min={0} 
                        max={300} 
                        step={0.1}
                        value={currentValue}
                        formatter={value => `${value}`}
                        parser={value => value.replace('%', '')}
                        onChange={(value) => handleValueChange(record.ID, 'PE_threshold', value)}
                    />
                );
            },

            

            difference_current_targetMeanprice_threshold: (text, record) => {
                const currentValue = editedPreferences[record.ID]?.difference_current_targetMeanprice_threshold || text;
                return (
                    <InputNumber 
                        min={-100} 
                        max={+100} 
                        step={0.1} // setting the step to 0.1
                        value={currentValue}
                        formatter={value => `${value}%`}
                        parser={value => value.replace('%', '')}
                        onChange={(value) => handleValueChange(record.ID, 'difference_current_targetMeanprice_threshold', value)}
                    />
                );
            },

            difference_52weekhigh_threshold: (text, record) => {
                const currentValue = editedPreferences[record.ID]?.difference_52weekhigh_threshold || text;
                return (
                    <InputNumber 
                        min={-100} 
                        max={+100} 
                        step={0.1} // setting the step to 0.1
                        value={currentValue}
                        formatter={value => `${value}%`}
                        parser={value => value.replace('%', '')}
                        onChange={(value) => handleValueChange(record.ID, 'difference_52weekhigh_threshold', value)}
                    />
                );
            },

            // Combine_thresholds_PE_targetMeanprice_52weekhigh: (text, record) => {
            //     const currentValue = editedPreferences[record.ID]?.Combine_thresholds_PE_targetMeanprice_52weekhigh ?? text;
            //     return (
            //         <Switch
            //             checked={currentValue}
            //             checkedChildren="Yes"
            //             unCheckedChildren="No"
            //             onChange={(isChecked) => handleValueChange(record.ID, 'Combine_thresholds_PE_targetMeanprice_52weekhigh', isChecked)}
            //         />
            //     );
            // },

            
            benchmark1: (text, record) => (
                <select
                    value={editedPreferences[record.ID]?.benchmark1 || text}
 
                    onChange={(e) => handleBenchmarkChange(e.target.value, 'Benchmark1', record.ID)}
                >
                    {marketUpdates.map((market, index) => 
                        <option key={index} value={market[2]}>{market[2]}</option>
                    )}
                </select>
            ),
            benchmark2: (text, record) => (
                <select 
                    value={editedPreferences[record.ID]?.benchmark2 || text}
                    onChange={(e) => handleBenchmarkChange(e.target.value, 'Benchmark2', record.ID)}
                >
                    {marketUpdates.map((market, index) => 
                        <option key={index} value={market[2]}>{market[2]}</option>
                    )}
                </select>
            )
            
        };

        const columnOrder = [
            'category',
            'benchmark1',
            // 'benchmark2',
            'benchmark_minimum_decrease_treshold_for_accumulate',
            'one_day_perf_accumulate_treshold',
            'accumulate_perc',
            'harvest_alert_treshold',
            'latest_tx_type_buy',
            'benchmark_treshold_for_harvesting',
            'harvest_perc',
            'PE_threshold',
            'difference_current_targetMeanprice_threshold',
            'difference_52weekhigh_threshold',
            // 'Combine_thresholds_PE_targetMeanprice_52weekhigh',

            
            // ... (add other keys if needed)
        ];

                // Map over the columnOrder array to generate column definitions
                let dynamicColumns = columnOrder.map(key => {
                    let columnDefinition = {
                        title: <span dangerouslySetInnerHTML={{ __html: formatHeader(key.charAt(0).toUpperCase() + key.slice(1)) }} />,
                        // title: key.charAt(0).toUpperCase() + key.slice(1),
                        dataIndex: key,
                        key: key,
                        // other properties...
                    };
        
                    // Use the renderCategoryDetails function for the 'category' column
                    if (key === 'category') {
                        columnDefinition.render = renderCategoryDetails;
                    } else if (specialRenderers[key]) {
                        columnDefinition.render = specialRenderers[key];
                    }
        
                    return columnDefinition;
                });
        
                return dynamicColumns;
            }
            return [];
        };



    useEffect(() => {
        const fetchCategoryPreferences = async () => {
            const storedToken = localStorage.getItem('token');
            try {
                const response = await api.get('/get_category_preferences', {
                    headers: { Authorization: `Bearer ${storedToken}` }
                });
                setCategoryPreferences(response.data);
                console.log("Category Preferences: ", response.data);
            } catch (error) {
                console.error('Error fetching category preferences:', error);
            }
        };

        const fetchMarketUpdates = async () => {
            const storedToken = localStorage.getItem('token');
            try {
                const response = await api.get('/get_benchmark_list', {
                    headers: { Authorization: `Bearer ${storedToken}` }
                });
                setMarketUpdates(response.data.data);  // assuming data is an array in the response

                console.log("Benchmark list: ", response.data.data);

            } catch (error) {
                console.error('Error fetching market updates:', error);
            }
        };
        


        if (isVisible) {
            fetchCategoryPreferences();
            fetchMarketUpdates();
        }
    }, [isVisible]);


    const formatHeader = (header) => {
        let formattedHeader = '';
        for (let i = 0; i < header.length; i += 10) {
            formattedHeader += header.substring(i, i + 10) + '<br />';
        }
        return formattedHeader;
    };    
    const columns = generateColumns();
    // console.log("Generated Columns:", columns);

    return (
        <Modal
            title="Category Preferences"
            open={isVisible}
            onCancel={() => {
                            setIsVisible(false);
                            setChangesMade(false); // Reset to false when modal is closed
                        }}

            footer={[
                            <Button key="save" type="primary" onClick={handleSave} disabled={!changesMade}>
                                Save
                            </Button>
                        ]}

            width="95%"   // Setting the modal width to 80% of the viewport width
        >
            <Table dataSource={categoryPreferences} columns={columns} rowKey="ID" scroll={{ x: 'max-content' }} />


        </Modal>
    );
}

export default CategoryPreferencesModal;
