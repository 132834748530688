// import React from 'react';
import React, { useState, useEffect } from 'react';

import { Checkbox, Modal, Table, Tooltip, Collapse, Select, Input, Button, message } from 'antd';
import { CloseOutlined } from '@ant-design/icons';
import getUserId from '../functions/getUserId';
import api from '../functions/api';
import ThreeTablesModal from "./SearchResultModal";

const FilterModal = ({ 
    isModalVisible, 
    handleOk, 
    handleCancel, 
    filterData, 
    handleTresholdChange, 
    handleTresholdReset, 
    resetAllTresholds, 
    multiFilterCount, 
    filteredCompanies: initialFilteredCompanies,
    handleAddToWidgets, 
    canAddToWidgets,
    // filteredCompanies,
    }) => {

const { Column } = Table;
const { Panel } = Collapse;
const { Option } = Select;

const [showTablesModal, setShowTablesModal] = useState(false);
const [selectedTicker, setSelectedTicker] = useState({ company: "", ticker: "" });

// const [selectedTicker, setSelectedTicker] = useState('');
const [selectedStockData, setSelectedStockData] = useState([]);
const [metadata, setMetadata] = useState({});
const [selectedTickerSymbol, setSelectedTickerSymbol] = useState("");
const [selectedCompanyName, setSelectedCompanyName] = useState("");

const showThreeTablesModal = () => {
            setIsThreeTablesModalVisible(true);
          };

const [isThreeTablesModalVisible, setIsThreeTablesModalVisible] = useState(false);

const handleSaveFavorites = () => {
        // Implement your logic here
      };
    //   Create a state variable for filteredCompanies and a function to update it
const [filteredCompanies, setFilteredCompanies] = useState(initialFilteredCompanies);

  // Update the filteredCompanies state whenever the checkbox state is changed
  const handleCheckboxChange = async (recordIndex, isChecked) => {
    const userId = getUserId();
    const response = await api.post(`/change_favorites/${userId}`, {
      ticker: filteredCompanies[recordIndex].ticker,
      isFavorite: isChecked,
    });
  
    if (response.status === 200) {
      setFilteredCompanies(prevCompanies => prevCompanies.map((company, index) => {
        if (index === recordIndex) {
          return { ...company, is_favorite: isChecked };
        }
        return company;
      }));
  
      // Display success message
      message.success('Favorites changed', 1);
    }
  }
  

  useEffect(() => {
    setFilteredCompanies(initialFilteredCompanies);
  }, [initialFilteredCompanies]);
  

  const nestedColumns = [
    {
      title: 'DataField',
      dataIndex: 'Label',
      key: 'Label',
      render: (text, record) => (
        <Tooltip title={record.Tooltip} color="#1677ff">
          <span>{text}</span>
        </Tooltip>
      )
    },
    {
      title: 'Negative',
      dataIndex: 'Negative',
      key: 'Negative',
    },
    {
      title: 'Positive',
      dataIndex: 'Positive',
      key: 'Positive',
    },
    {
        title: (
          <div style={{ display: 'flex', alignItems: 'center' }}>
            <span>Treshold</span>
            <Button type="link" onClick={resetAllTresholds}>Reset All</Button>
          </div>
        ),
        dataIndex: 'treshold',
        key: 'treshold',
        render: (text, record) => {
          if (record.Values && record.Values.length) {
            return (
              <Select 
                style={{ width: 200 }}
                onChange={(value) => handleTresholdChange(value, record)}
                onClear={() => handleTresholdReset(record)}
                allowClear
                defaultValue=""
              >
                {record.Values.map((value) => (
                  <Option key={value} value={value}>
                    {value}
                  </Option>
                ))}
              </Select>
            );
          } else {
            return (
              <div style={{ display: 'flex', alignItems: 'center' }}>
                <Input onChange={(e) => handleTresholdChange(e.target.value, record)} />
                {record.treshold && (
                  <Button
                    type="link"
                    icon={<CloseOutlined />}
                    onClick={() => handleTresholdReset(record)}
                  />
                )}
              </div>
            );
          }
        },
        className: 'custom-row',
      },
 
    {
      title: 'Numbers',
      dataIndex: 'count',
    //   key: record => `count-${record.timestamp || ""}`,
      key: 'count',
      render: (text) => (
        <Tooltip title="number of companies in the database adhering to this treshold">
           <span>{text !== undefined ? text : ""}</span>
          {/* <span>{text}</span> */}
        </Tooltip>
      )
    },
  ];

  const columns = [
    {
      title: 'Category',
      dataIndex: 'category',
      key: 'category',
    },
  ];

//   console.log(selectedTickerSymbol);
//   console.log(selectedCompanyName);

  const filteredCompaniesColumns = [
    {
      title: '',
      key: 'checkbox',
      render: (_, record, recordIndex) => (
        <Checkbox 
          checked={record.is_favorite}
          onChange={(e) => {
            e.stopPropagation(); // Prevent click event from propagating to the table row
            handleCheckboxChange(recordIndex, e.target.checked);
          }}
        />
      )
    },
    {
      title: 'Ticker',
      dataIndex: 'ticker',
      key: 'ticker',
    },
    {
      title: 'Company',
      dataIndex: 'company',
      key: 'company',
    },
  ];
  
  return (
    <>
      <Modal 
        title={(
          <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
             
              <span>Filter</span>
              <h3>Filtered companies: {multiFilterCount}</h3>
              <Button type="link" onClick={resetAllTresholds}>Reset All</Button>
              {/* <Button type="primary" onClick={() => handleAddToWidgets()}>Add to Widgets</Button> */}
              <Button 
                type="primary" 
                onClick={handleAddToWidgets}
                disabled={!canAddToWidgets} // Use the prop to control the disabled state
              >
                Add to Widgets
              </Button>
          </div>
      )} 
        width={1000} 
        visible={isModalVisible} // modified 'open' to 'visible' because 'open' prop doesn't exist for antd modal
        onOk={handleOk} 
        onCancel={handleCancel}
      >
        <Collapse>
        <Panel header="Filtered Companies and Tickers">
            <Button type="primary" onClick={handleSaveFavorites}>Save Favorites</Button>
            
            <Table 
  dataSource={filteredCompanies} 
  columns={filteredCompaniesColumns} 
  pagination={false}
  onRow={(record, rowIndex) => ({
    onClick: async (event) => { // mark this function as async
      // Check if the click event is from a checkbox, if so, do nothing
      if (event.target.type === 'checkbox') {
        return;
      }
      // Otherwise, proceed with opening the modal
      console.log('Record:', record);
      setSelectedTicker({ company: record.company, ticker: record.ticker }); 
      const ticker = record.ticker;
      setSelectedTickerSymbol(record.ticker);
      setSelectedCompanyName(record.company);

      try {
        const response = await api.get(`/stock_data?symbol=${ticker}`);
        setSelectedStockData(response.data.tables);
        setMetadata(response.data.metadata);
        setShowTablesModal(true);
      } catch (error) {
        console.error('Error fetching stock data:', error);
      }
    },
  })}
/>

            <Button type="primary" onClick={handleSaveFavorites}>Save Favorites</Button>
          </Panel>
        </Collapse>
        <Table 
          dataSource={filterData} 
          columns={columns} 
          rowClassName={() => 'my-row-style'}
          pagination={false}
          defaultExpandAllRows={true}
          expandedRowRender={record => (
              <Table 
                  dataSource={record.filters} 
                  columns={nestedColumns} 
                  rowClassName={() => 'my-row-style'}
                  pagination={false}
              />
          )}
        />
        <style jsx>{`
          .my-row-style {
            font-size: 12px; 
            line-height: 0.5; 
          }
          .custom-row .ant-table-cell {
            padding: 6px 8px;
            line-height: 1;
          }
        `}</style>
      </Modal>

      <ThreeTablesModal
                tickerValue={selectedTickerSymbol}
                stockValue={selectedCompanyName}
                tablesData={selectedStockData}
                open={showTablesModal}
                onClose={() => setShowTablesModal(false)}
                userId={getUserId()}
                metadata={metadata}
            />
    </>
  );  
};

export default FilterModal;
