// fetch_storeWidgetprefs.js
import api from '../functions/api';
import getUserId from '../functions/getUserId';

export default async function fetchWidgetprefs(userid, token) {
    try {
        // console.log("fetch process attempted for userid", userid);
        const response = await api.get('/widget_content_management', {
            headers: {
                'Authorization': `Bearer ${token}` // Include if token authentication is required
            },
            params: {
                userid: userid // Passing userId as a query parameter
            }
        });
        // console.log("response backend Widgetuserprefs ", response.data);
        return response.data;
    } catch (error) {
        console.error("Error fetching widget preferences: ", error);
        // Handle or throw the error based on your application's needs
    }
}
