//fetchPortfolioData.js
import { useState, useEffect, useCallback } from 'react';
import api from '../functions/api';
import TickerSearch from '../components/TickerSearch';
import getUserId from '../functions/getUserId';
import { debounce, throttle } from 'lodash';
import { Tooltip } from 'antd';
import isEqual from 'lodash/isEqual';
import { useDispatch, useSelector } from 'react-redux';

const usePortfolioData = () => {
  // const state = useSelector((state) => state);
  const token = localStorage.getItem('token'); 
  const userId = getUserId();
  const [portfolioData, setPortfolioData] = useState([]);
  const [tableColumns, setTableColumns] = useState([]);
  const [categories, setCategories] = useState([]);
  const [selectedTickers, setSelectedTickers] = useState({});  
  const [isLoading, setIsLoading] = useState(true);  
  const [portfolioTopline, setPortfolioTopline] = useState(null);
  const tooltipDelay = 1.5;
  const [portfolioTickers, setPortfolioTickers] = useState([]);

  const handleTickerSelect = (ticker, record) => {
    setSelectedTickers(prevState => ({ ...prevState, [record.id]: ticker }));
  }

  const stored_portfolioData = useSelector((state) => state.portfolio.data);

  const fetchPortfolioData = useCallback(throttle(async (storedData, fieldMetadata) => {
    const response = { data: storedData };
    const token = localStorage.getItem('token');
    const userId = getUserId();
        
    if (!userId) {
        setIsLoading(true);
        return;
    }
  
    setIsLoading(false);

    try {
          
      const tickers = response.data.portfolio.map(item => item.Ticker);
      if (!isEqual(tickers, portfolioTickers)) {
        setPortfolioTickers(tickers); 
      }

      const tooltipData = response.data.portfolio_view_metadata;
      const tooltipMap = {};
      tooltipData.forEach(entry => {
        tooltipMap[entry.field_name] = entry.tooltip_text;
      });
      
      let data = response.data.portfolio.map(item => ({...item, isTickerSelected: !!item.Ticker}));
      const metadata = response.data.field_metadata;
   
      const fixedColumns = [
        {
          title: 'Ticker',
          dataIndex: 'Ticker',
          key: 'Ticker',
          width: '10%', 
          render: (text, record) => (
            <>
              {record.isTickerSelected ? text : <TickerSearch onTickerSelect={(ticker) => handleTickerSelect(ticker, record)} />}
            </>
          )
        },
        { 
          title: 'Company', 
          dataIndex: 'Company', 
          key: 'Company', 
          isDynamic: false, 
          width: '10%', 
          render: text => (
            <Tooltip title={text} mouseEnterDelay={1.5} color='#1677ff' placement="lt">
              <div 
                style={{
                  width: '100px', 
                  overflow: 'hidden', 
                  textOverflow: 'ellipsis',
                  fontFamily: '"Roboto", sans-serif',
                  fontSize: '40px',
                  color: '#333',     
                  lineHeight: '1.2',
                  height: 'auto'
                }}
              >
                {text && text.length > 8 ? text.substring(0, 8) + '..' : text}
              </div>
            </Tooltip>
          )
        },
        {
          title: 'Position',
          dataIndex: 'Position',
          key: 'Position',
          width: '110px',
          isDynamic: false,
          render: (text, record) => (
            <div style={{ display: 'flex', flexDirection: 'column', gap: '12px', fontSize: '10px' }}>
              {record.Position && 
                <Tooltip title={tooltipMap['Position'] || ''} mouseEnterDelay={tooltipDelay}>
                  <div className="hover-text-blue" style={{ display: 'flex', justifyContent: 'space-between' }}>
                    <div>Position:</div>
                    <div style={{ textAlign: 'right' }}>{record.Position}</div>
                  </div>
                </Tooltip>}
              {record.Close && 
                <Tooltip title={tooltipMap['Close'] || ''} mouseEnterDelay={tooltipDelay}>
                  <div className="hover-text-blue" style={{ display: 'flex', justifyContent: 'space-between' }}>
                    <div>Close:</div>
                    <div style={{ textAlign: 'right' }}>{record.Close} {record.currency}</div>
                  </div>
                </Tooltip>}
              {record.Value_eur && 
                <Tooltip title={tooltipMap['Value_eur'] || ''} mouseEnterDelay={tooltipDelay}>
                  <div className="hover-text-blue" style={{ display: 'flex', justifyContent: 'space-between' }}>
                    <div>Value in Eur:</div>
                    <div style={{ textAlign: 'right' }}>{record.Value_eur} €</div>
                  </div>
                </Tooltip>}
              {record.latest_tx_date && 
                <Tooltip title={tooltipMap['Value_eur'] || ''} mouseEnterDelay={tooltipDelay}>
                  <div className="hover-text-blue" style={{ display: 'flex', justifyContent: 'space-between' }}>
                    <div>Latest transaction:</div>
                    <div style={{ textAlign: 'right' }}>{record.latest_tx_date} {record.latest_tx_type}</div>
                  </div>
                </Tooltip>}
            </div>
          ),
        },
        {
          title: 'Performance',
          dataIndex: 'Performance',
          key: 'Performance',
          width: '75px',
          isDynamic: false,
          render: (text, record) => (
            <div style={{ display: 'flex', flexDirection: 'column', gap: '12px', fontSize: '10px' }}>
              {record.unrealized_prof_loss_Eur_perc && 
                <Tooltip title={tooltipMap['unrealized_prof_loss_Eur_perc'] || ''} mouseEnterDelay={tooltipDelay}>
                  <div className="hover-text-blue" style={{ display: 'flex', justifyContent: 'space-between' }}>
                    <div>Unrealized P/L:</div>
                    <div style={{ textAlign: 'right', color: record.unrealized_prof_loss_Eur_perc < 0 ? 'red' : 'green' }}>
                      {record.unrealized_prof_loss_Eur_perc}%
                    </div>
                  </div>
                </Tooltip>}
              {record.realized_results_current_positions_performance && 
                <Tooltip title={tooltipMap['realized_results_current_positions_performance'] || ''} mouseEnterDelay={tooltipDelay}>
                  <div className="hover-text-blue" style={{ display: 'flex', justifyContent: 'space-between' }}>
                    <div>Realized Results:</div>
                    <div style={{ textAlign: 'right' }}>{record.realized_results_current_positions_performance}%</div>
                  </div>
                </Tooltip>}
              {record.performance_since_last_tx && record.performance_since_last_tx !== '0' && record.performance_since_last_tx !== '0.00' &&
                <Tooltip title={tooltipMap['performance_since_last_tx'] || ''} mouseEnterDelay={tooltipDelay}>
                  <div className="hover-text-blue" style={{ display: 'flex', justifyContent: 'space-between' }}>
                    <div>Performance Since Last tx:</div>
                    <div style={{ textAlign: 'right', color: record.performance_since_last_tx < record.benchmark_perf_since_last_tx ? 'red' : 'green' }}>
                      {record.performance_since_last_tx}%
                    </div>
                  </div>
                </Tooltip>}
              {record.benchmark_perf_since_last_tx && record.benchmark_perf_since_last_tx !== '0' && record.benchmark_perf_since_last_tx !== '0.00' &&
                <Tooltip title={tooltipMap['benchmark_perf_since_last_tx'] || ''} mouseEnterDelay={tooltipDelay}>
                  <div className="hover-text-blue" style={{ display: 'flex', justifyContent: 'space-between' }}>
                    <div>{record.benchmark} performance since last tx:</div>
                    <div style={{ textAlign: 'right' }}>{record.benchmark_perf_since_last_tx}%</div>
                  </div>
                </Tooltip>}
            </div>
          ),
        },
      ];

      const createColumns = (metadata, existingColumns) => {
        const existingColumnKeys = existingColumns.map(column => column.key);
        
        const dynamicColumns = Object.keys(metadata).map((key) => {
          if (!existingColumnKeys.includes(key)) {
            return {
              title: metadata[key].Label,
              dataIndex: key,
              key: key,
              isDynamic: true,
              width: '10%',
              render: text => (
                <Tooltip title={tooltipMap[key] || 'No tooltip available'}>
                  {text}
                </Tooltip>
              )
            };
          }
          return null;
        }).filter(column => column !== null);
        return dynamicColumns;
      };
      
      const updatedCategoryRecords = response.data.updated_category_records;
      if (!isEqual(response.data.category_options, categories)) {
        setCategories(response.data.category_options);
      }

      if (!isEqual(data, portfolioData)) {
        setPortfolioData(data);
      }
      setPortfolioData(data);
      const dynamicColumns = createColumns(metadata, fixedColumns);
      const newColumns = [...fixedColumns, ...dynamicColumns];
      if (!isEqual(newColumns, tableColumns)) {
        setTableColumns(newColumns);
      }
      if (!isEqual(response.data.portfolio_topline, portfolioTopline)) {
        setPortfolioTopline(response.data.portfolio_topline);
      }
      
      return data;
    } catch (error) {
      console.error("Error fetching data:", error);
      return [];
    }
  }, 5000), []);

  useEffect(() => {
    fetchPortfolioData(stored_portfolioData);
  }, [stored_portfolioData, fetchPortfolioData]);
  
  return {
    portfolioData,
    tableColumns,
    categories,
    portfolioTopline,
    fetchPortfolioData,
    isLoading,
    portfolioTickers 
  };
};

export default usePortfolioData;
