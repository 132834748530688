//PF_Exposure.jsx

import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { Table, Collapse } from 'antd';
import ThreeTablesModal from "./SearchResultModal";
import getUserId from '../functions/getUserId';

const { Panel } = Collapse;


const PF_Exposure = () => {
    const [isTableExpanded, setIsTableExpanded] = useState(true); // State to track table collapse
    const [isModalVisible, setIsModalVisible] = useState(false); // State to control modal visibility
    const [selectedTicker, setSelectedTicker] = useState(null); // State to store selected ticker
    
    const portfolioData = useSelector(state => state.portfolio.data);
    const userId = getUserId();
    const demoMode = useSelector(state => state.demoMode.isDemoMode);
    const maybeBlur = (text) => demoMode ? <span className="blur-text">{text}</span> : text;

  
    if (!portfolioData || !portfolioData.exposures || portfolioData.exposures.length === 0) {
      return <div>No data available</div>;
    }

    const safeParseFloat = (str) => {
        const value = parseFloat(str);
        return isNaN(value) ? 0 : value; // Treat non-numeric/invalid values as 0
      };

    const columns = [
      { 
        title: 'Ticker', 
        dataIndex: 'ticker', 
        key: 'ticker',
        render: (text, record) => (
          <a onClick={() => {
            setSelectedTicker(record.ticker); // Store the clicked ticker
            setIsModalVisible(true); // Show the modal
          }}>
            {text}
          </a>
        ),
      },
      { 
        title: 'Part of Total Portfolio', 
        dataIndex: 'total_amount_exposure_perc', 
        key: 'total_amount_exposure_perc',
        sorter: (a, b) => parseFloat(a.total_amount_exposure_perc) - parseFloat(b.total_amount_exposure_perc),
        render: (text) => `${parseFloat(text).toFixed(2)}%`
        // render: (text) => maybeBlur(text)
      },
      { 
        title: 'Total Amount Exposure', 
        dataIndex: 'total_amount_exposure', 
        key: 'total_amount_exposure',
        sorter: (a, b) => parseFloat(a.total_amount_exposure) - parseFloat(b.total_amount_exposure),
        render: (text) => maybeBlur(text)
      },
      {
        title: 'Tracker Exposure Amount',
        dataIndex: 'tracker_exposure_amount',
        key: 'tracker_exposure_amount',
        sorter: (a, b) => parseFloat(a.tracker_exposure_amount) - parseFloat(b.tracker_exposure_amount),
        render: (text, record) => {
          const details = record.tracker_exposure ? record.tracker_exposure.split(', ').map((detail, index) => (
            <div key={index}>{maybeBlur(detail)}</div>  // Apply blur to each detail
          )) : null;
      
          return (
            <div style={{ display: 'flex', justifyContent: 'space-between' }}>
              <div>{maybeBlur(record.tracker_exposure_amount)}</div>  
              <div style={{ display: 'flex', flexDirection: 'column', textAlign: 'right', fontSize: 10 }}>
                {details}
              </div>
            </div>
          );
        },
      },
      
      { 
        title: 'Stock Exposure', 
        dataIndex: 'stock_exposure', 
        key: 'stock_exposure',
        sorter: (a, b) => safeParseFloat(a.stock_exposure) - safeParseFloat(b.stock_exposure),
        render: (text) => maybeBlur(text)
      },
    ];

    const dataSource = portfolioData.exposures.map((exposure, index) => ({
      key: index,
      ticker: exposure.ticker,
      total_amount_exposure: exposure.total_amount_exposure,
      tracker_exposure_amount: exposure.tracker_exposure_amount,
      tracker_exposure: exposure.tracker_exposure,
      stock_exposure: exposure.stock_exposure,
      total_amount_exposure_perc: exposure.total_amount_exposure_perc


    }));

    return (
      <>
        <Collapse defaultActiveKey={['1']} onChange={() => setIsTableExpanded(!isTableExpanded)}>
          <Panel header="Exposure Details" key="1">
            <Table
              columns={columns}
              dataSource={dataSource}
              pagination={{ pageSize: 10 }} // Adjust as needed
            />
          </Panel>
        </Collapse>
        {isModalVisible && (
          <ThreeTablesModal 
            tickerValue={selectedTicker}
            open={isModalVisible}
            onClose={() => setIsModalVisible(false)}
            userId={userId}
          />
        )}
      </>
    );
};

export default PF_Exposure;
