//TickerSearch.jsx

import React, { useState } from 'react';
import { AutoComplete, Input } from 'antd';
import api from '../functions/api';
import AddRequest from './AddRequest';

const TickerSearch = ({ onTickerSelect }) => {
  const [searchValue, setSearchValue] = useState('');
  const [options, setOptions] = useState([]);
  const [isAddRequestVisible, setIsAddRequestVisible] = useState(false);

  const handleSearch = async (value) => {
    setSearchValue(value);
    if (!value) return;

    // Now using api instead of axios
    const response = await api.get(`/search_suggestions?query=${value}`);
    const autoCompleteOptions = response.data.map((suggestion) => ({
      value: suggestion,
      style: { width: 'auto', minWidth: '200px' }
    }));
    setOptions(autoCompleteOptions);
  };

  const handleSelect = (value) => {
    const isSelectedValueInOptions = options.some(option => option.value === value);
    if (isSelectedValueInOptions) {
      const ticker = value.split(' - ')[0];
      onTickerSelect(ticker);
      setSearchValue('');  // Reset the search field after selection
    }
  };

  const handleBlur = () => {
    const isSelectedValueInOptions = options.some(option => option.value === searchValue);
    if (!isSelectedValueInOptions) {
      setIsAddRequestVisible(true); // Open the AddRequest modal
    }
  };

  const handleKeyDown = (event) => {
    if (event.key === 'Enter') {
      handleBlur();
    }
  };

  const handleClearMessage = () => {
    setIsAddRequestVisible(false); // Close the AddRequest modal
    setSearchValue('');  // Reset the search field
  };

  return (
    <>
      <AutoComplete
        options={options}
        onSelect={handleSelect}
        onSearch={handleSearch}
        onBlur={handleBlur}
        onKeyDown={handleKeyDown} // add this line
        value={searchValue}
        dropdownStyle={{ minWidth: '200px' }}
      >
        <Input placeholder="ticker or stockname" />
      </AutoComplete>
      {isAddRequestVisible && (
        <AddRequest 
          searchValue={searchValue} 
          handleClearMessage={handleClearMessage}
          visible={isAddRequestVisible}
        />
      )}
    </>
  );
};

export default TickerSearch;
