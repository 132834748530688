
//User.jsx

import React from 'react';
import { Modal, Form, Input, Button, Tabs } from 'antd';
import api from '../functions/api';
import { useDispatch } from 'react-redux';  // Import useDispatch
import { logIn, logOut } from '../redux/actions';  // Import action creators

const User = ({ setUserLoggedIn, defaultTab = "2", visible, setVisible, refetchUserDetails, setToken }) => {
  const dispatch = useDispatch(); 

  const token = localStorage.getItem('token');
  const { TabPane } = Tabs;
  const [activeTab, setActiveTab] = React.useState(defaultTab); 

  const handleCancel = () => {

    setVisible(false); // Notify the parent component to hide the modal
  };

  const handleRegisterSubmit = async (values) => {
    // console.log('Submitting registration form', values);
    try {
      await api.post('/register', values); 
   
      // console.log(response.data);
  
      setVisible(false);
    } catch (error) {
      if (error.response) {
          console.error('Error registering user:', error.response.data);
          alert(error.response.data.error); // Display the error message to the user
      } else if (error.request) {
          console.error('No response received:', error.request);
      } else {
          console.error('Error', error.message);
      }
    }
  };
  
  const handleLoginSubmit = async (values) => {
    try {
      // console.log('Submitting login form', values);
      const response = await api.post('/login', values);
      const newToken = response.data.access_token;  // Define newToken here
      // console.log("data from /login", response.data);
      localStorage.setItem('token', newToken); // Use newToken here
      const userData = {
        ID: response.data.user_id
        // ,
        // userName: response.data.user.first_name // replace with actual keys
      };

      setToken(newToken);
      dispatch(logIn(userData));
      setTimeout(() => {
        // setUserLoggedIn(true);
        // refetchUserDetails();  // Call the function after a successful login
      }, 500);

      setVisible(false); // Hide the modal
    } catch (error) {
      if (error.response) {
        console.error('Error logging in:', error.response.data);
        alert(error.response.data.error); // Display the error message to the user
      } else if (error.request) {
        console.error('No response received:', error.request);
      } else {
        console.error('Error', error.message);
      }
    }
  };
  

  React.useEffect(() => {
   
    setActiveTab(defaultTab);  // Update activeTab when defaultTab changes
  }, [token, visible, defaultTab]);  // Add defaultTab to dependencies

  return (
    <div>
      <Modal
        title="Register/Login"
        open={visible}
      
        onCancel={handleCancel}
        footer={null}
      >
      <Tabs activeKey={activeTab} onChange={setActiveTab}>

        <TabPane tab="Register" key="1">
            <Form onFinish={handleRegisterSubmit}>
                <Form.Item
                name="email"
                label="Email"
                rules={[
                    {
                    type: 'email',
                    message: 'The input is not valid E-mail!',
                    },
                    {
                    required: true,
                    message: 'Please input your E-mail!',
                    },
                ]}
                >
                <Input />
                </Form.Item>

                <Form.Item
                name="userid"
                label="User ID"
                rules={[
                    {
                    required: true,
                    message: 'Please input your User ID!',
                    },
                ]}
                >
                <Input />
                </Form.Item>

                <Form.Item
                name="password"
                label="Password"
                rules={[
                    {
                    required: true,
                    message: 'Please input your password!',
                    },
                ]}
                hasFeedback
                >
                <Input.Password />
                </Form.Item>

                <Form.Item
                name="first_name"
                label="First Name"
                rules={[
                    {
                    required: true,
                    message: 'Please input your First Name!',
                    },
                ]}
                >
                <Input />
                </Form.Item>

                <Form.Item
                name="last_name"
                label="Last Name"
                rules={[
                    {
                    required: true,
                    message: 'Please input your Last Name!',
                    },
                ]}
                >
                <Input />
                </Form.Item>

                <Form.Item>
                <Button type="primary" htmlType="submit">
                    Register
                </Button>
                </Form.Item>
            </Form>
            </TabPane>

          <TabPane tab="Login" key="2">
            <Form onFinish={handleLoginSubmit}>
             
              <Form.Item
                name="userid"
                label="User ID"
                rules={[
                  {
                    required: true,
                    message: 'Please input your User ID!',
                  },
                ]}
              >
                <Input />
              </Form.Item>

              <Form.Item
                name="password"
                label="Password"
                rules={[
                  {
                    required: true,
                    message: 'Please input your password!',
                  },
                ]}
                hasFeedback
              >
                <Input.Password />
              </Form.Item>

              <Form.Item>
                <Button type="primary" htmlType="submit">
                  Login
                </Button>
              </Form.Item>
            </Form>
          </TabPane>
        </Tabs>
      </Modal>
    </div>
  );
};

export default User;

