
import React, { useState } from 'react';
import { Table } from 'antd';

const MotleyFoolAlertsPane = ({ alerts }) => {
  const [isExpanded, setIsExpanded] = useState(false);

  const toggleExpand = () => {
    setIsExpanded(!isExpanded);
  };

  // Extract and sort the MF_Data_analysis data
  const mfDataAnalysis = alerts.MF_Data_analysis || [];
  const latestDataAnalysis = mfDataAnalysis.sort((a, b) => b.ID - a.ID)[0] || {};

  // Extract and sort the MF_Data data
  const mfData = alerts.MF_Data || [];
  const sortedMFData = mfData.sort((a, b) => b.epoch - a.epoch); // Sort by epoch, latest first

  // Columns for the MF_Data table
  const columnsMFData = [
    {
      title: 'URL',
      dataIndex: 'url',
      key: 'url',
    },
    {
      title: 'Ticker',
      dataIndex: 'ticker',
      key: 'ticker',
    },
    {
      title: 'Sentiment',
      dataIndex: 'sentiment',
      key: 'sentiment',
    },
    {
      title: '1D Performance',
      dataIndex: 'perf_1d',
      key: 'perf_1d',
    },
    {
      title: '7D Performance',
      dataIndex: 'perf_7d',
      key: 'perf_7d',
    },
  ];

  // Data rows for the collapsible MF_Data_analysis table
  const dataRowsAnalysis = isExpanded
    ? Object.entries(latestDataAnalysis).map(([key, value]) => {
        if (key !== 'ID') {
          return (
            <tr key={key}>
              <td>{key}</td>
              <td>{value}</td>
            </tr>
          );
        }
        return null;
      })
    : null;

  return (
    <div>
      <div onClick={toggleExpand} style={{ display: 'flex', alignItems: 'center', cursor: 'pointer', marginBottom: '10px' }}>
        <span style={{ fontSize: '16px', fontWeight: 'bold' }}>
          {isExpanded ? '-' : '+'} Motley's Fool Data Analysis
        </span>
      </div>
      {isExpanded && (
        <table style={{ width: '100%' }}>
          <tbody>{dataRowsAnalysis}</tbody>
        </table>
      )}

      {/* Second table for MF_Data */}
      <Table
        rowKey="epoch" // Assuming 'epoch' is unique
        dataSource={sortedMFData}
        columns={columnsMFData}
        pagination={true}
        title={() => <div style={{ fontWeight: 'bold' }}>Motley Fool Alerts</div>}
        />
    </div>
  );
};

export default MotleyFoolAlertsPane;


// import React, { useState } from 'react';
// import { Table } from 'antd';

// const MotleyFoolAlertsPane = ({ alerts }) => {
//   const [isExpanded, setIsExpanded] = useState(false);

//   const toggleExpand = () => {
//     setIsExpanded(!isExpanded);
//   };

//   // Assuming alerts.MF_Data_analysis contains the data
//   const mfDataAnalysis = alerts.MF_Data_analysis || [];
//   console.log("mfData: ", mfDataAnalysis)
//   // Sort by ID and get the latest entry
//   const latestData = mfDataAnalysis.sort((a, b) => b.ID - a.ID)[0] || {};

//   // Extract the data for rows excluding the 'ID'
//   const dataRows = isExpanded
//     ? Object.entries(latestData).map(([key, value]) => {
//         if (key !== 'ID') {
//           return (
//             <tr key={key}>
//               <td>{key}</td>
//               <td>{value}</td>
//             </tr>
//           );
//         }
//         return null;
//       })
//     : null;

//   return (
//     <div>
//       <div onClick={toggleExpand} style={{ display: 'flex', alignItems: 'center', cursor: 'pointer', marginBottom: '10px' }}>
//         <span style={{ fontSize: '16px', fontWeight: 'bold' }}>
//           {isExpanded ? '-' : '+'} Motley's Fool Data Analysis
//         </span>
//       </div>
//       {isExpanded && (
//         <table style={{ width: '100%' }}>
//           <tbody>{dataRows}</tbody>
//         </table>
//       )}
//     </div>
//   );
// };

// export default MotleyFoolAlertsPane;
