import React from 'react';
import { Table } from 'antd';
import { useSelector } from 'react-redux';

const ESG_Check_Explainer_Rating_Agencies = () => {
  // Get the esg_rating_agencies_dataset_json from the Redux store
  const ratingAgenciesData = useSelector(state => state.portfolio?.parametersData?.esg_rating_agencies_dataset_json);

  // Define columns for the table
  const columns = [
    { title: 'Name', dataIndex: 'name', key: 'name' },
    { title: 'Approach', dataIndex: 'approach', key: 'approach' },
    { title: 'Method', dataIndex: 'method', key: 'method' },
    { title: 'Coverage', dataIndex: 'coverage', key: 'coverage' },
    { title: 'Availability', dataIndex: 'availability', key: 'availability' },
    { title: 'Rating Objective', dataIndex: 'rating_objective', key: 'rating_objective' },
    { title: 'Update Frequency', dataIndex: 'update_frequency', key: 'update_frequency' },
    { title: 'Rating', dataIndex: 'rating', key: 'rating' },
  ];

  return (
    <Table
      dataSource={ratingAgenciesData}
      columns={columns}
      rowKey="id"
      pagination={false}
    />
  );
};

export default ESG_Check_Explainer_Rating_Agencies;
