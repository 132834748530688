import React, { useState, useEffect } from 'react';
import { Table, Input } from 'antd';
import api from '../functions/api';

const MaintainRatingAgencies = () => {
  const [data, setData] = useState([]);
  const [editingCell, setEditingCell] = useState({ rowKey: null, columnKey: null });
  const [editingValue, setEditingValue] = useState('');

  useEffect(() => {
    fetchData();
  }, []);

  const fetchData = async () => {
    try {
      const response = await api.post('/esg_rating_agencies_change', { data: [] });
      const originalData = response.data.data;

      // Transform data: Columns become rows, include the `id` and original index
      const transformedData = Object.keys(originalData[0]).map((key, rowIndex) => ({
        key: rowIndex.toString(),
        attribute: key.replace(/_/g, ' ').replace(/\b\w/g, char => char.toUpperCase()),
        ...originalData.reduce((acc, item, colIndex) => {
          acc[`row_${colIndex}`] = { value: item[key], id: item.id }; // Store both value and ID
          return acc;
        }, {})
      }));

      setData(transformedData);
    } catch (error) {
      console.error('Error fetching data:', error);
    }
  };

  const handleEdit = (rowKey, columnKey, value) => {
    setEditingCell({ rowKey, columnKey });
    setEditingValue(value || ""); // Set current value or empty string for the input field
  };

  const handleSave = async (rowKey, columnKey) => {
    try {
      const updatedData = [...data];
      const index = updatedData.findIndex(item => item.key === rowKey);
      if (index > -1) {
        const item = updatedData[index];
        const cellData = item[columnKey];
        item[columnKey] = { ...cellData, value: editingValue }; // Update local data immediately
        setData(updatedData);

        // Use the correct `id` from the cellData
        const originalKey = item.attribute.replace(/ /g, '_').toLowerCase();
        const originalData = {
          id: cellData.id, // Use the correct ID from the transformed data
          key: originalKey,
          value: editingValue
        };

        await api.post('/esg_rating_agencies_change', {
          data: [originalData]
        });
      }
      setEditingCell({ rowKey: null, columnKey: null });
      setEditingValue('');
    } catch (error) {
      console.error('Error saving data:', error);
    }
  };

  // Create columns based on the number of original rows
  const columns = data.length > 0 ? [
    { title: 'Attribute', dataIndex: 'attribute', key: 'attribute' },
    ...Object.keys(data[0]).filter(key => key.startsWith('row_')).map(key => ({
      title: `Row ${key.split('_')[1]}`,
      dataIndex: key,
      key: key,
      render: (cellData, record) => {
        const isEditing = editingCell.rowKey === record.key && editingCell.columnKey === key;
        const value = cellData.value; // Extract the value for display
        return isEditing ? (
          <Input
            value={editingValue}
            onChange={e => setEditingValue(e.target.value)}
            onBlur={() => handleSave(record.key, key)} // Save on blur
            onPressEnter={() => handleSave(record.key, key)} // Save on Enter
            autoFocus
          />
        ) : (
          <div
            style={{ cursor: "pointer" }}
            onClick={() => handleEdit(record.key, key, value)} // Edit when clicked
          >
            {value || 'Click to add value'}
          </div>
        );
      }
    }))
  ] : [];

  return (
    <Table
      size="small"
      dataSource={data}
      columns={columns}
      rowKey="key"
      pagination={false} // Remove pagination for testing
    />
  );
};

export default MaintainRatingAgencies;


// import React, { useState, useEffect } from 'react';
// import { Table, Button, Input } from 'antd';
// import api from '../functions/api';

// const Maintain_RatingAgencies = () => {
//   const [transformedData, setTransformedData] = useState([]);
//   const [columns, setColumns] = useState([]);
//   const [editingKey, setEditingKey] = useState({ rowId: null, columnKey: null });
//   const [editingValue, setEditingValue] = useState('');

//   const handleClickCell = (rowId, columnKey) => {
//   setEditingKey({ rowId, columnKey });
//   setEditingValue('');  // Clear any previous value
// };

//   // Fetch all data from the backend when the component mounts
//   const fetchData = () => {
//     api.post('/esg_rating_agencies_change', { data: [] })  // Calling the API with an empty data array to fetch all data
//       .then(response => {
//         const esgRatingAgenciesDataset = response.data.data;  // Accessing the data returned by the API
//         if (esgRatingAgenciesDataset && esgRatingAgenciesDataset.length > 0) {
//           const firstItemKeys = Object.keys(esgRatingAgenciesDataset[0]);

//           // Create columns based on the ids or names in the data
//           const newColumns = esgRatingAgenciesDataset.map(item => ({
//             title: item.name || `ID ${item.id}`,
//             dataIndex: item.id.toString(),
//             key: item.id.toString(),
//             render: (text, record) => {
//               const isEditing = editingKey.rowId === item.id && editingKey.columnKey === record.key;
//               return isEditing ? (
//                 <div>
//                   <Input
//                     value={editingValue}
//                     onChange={(e) => setEditingValue(e.target.value)}
//                   />
//                   <Button
//                     type="primary"
//                     onClick={() => {
//                       handleSave(record.key, item.id.toString(), editingValue);
//                       setEditingKey({ rowId: null, columnKey: null });
//                     }}
//                   >
//                     Save
//                   </Button>
//                 </div>
//               ) : (
//                 <div
//                   style={{ cursor: "pointer" }}
//                   onClick={() => {
//                     setEditingKey({ rowId: item.id, columnKey: record.key });
//                     setEditingValue(text || "");
//                   }}
//                 >
//                   {text || "Click to add value"}
//                 </div>
//               );
//             },
//           }));

//           // Transform the data so that each row is now a field, and the columns are the different agencies
//           const newData = firstItemKeys.map(key => ({
//             key: key,
//             name: key.replace(/_/g, ' ').replace(/\b\w/g, char => char.toUpperCase()),
//             ...esgRatingAgenciesDataset.reduce((acc, item) => {
//               acc[item.id] = item[key];
//               return acc;
//             }, {})
//           }));

//           setTransformedData(newData);
//           setColumns([{ title: 'Field', dataIndex: 'name', key: 'name' }, ...newColumns]);
//         }
//       })
//       .catch(error => {
//         console.error('Error fetching data:', error);
//       });
//   };

//   useEffect(() => {
//     fetchData();  // Fetch data on mount
//   }, []);

//   const handleSave = (rowKey, id, value) => {
//     const changedData = { id, key: rowKey, value };

//     // Make the API call to save the data using the imported `api` module
//     api.post('/esg_rating_agencies_change', {
//       data: [changedData]
//     })
//     .then(response => {
//       console.log('Save response:', response.data);
//       // Update local data with the response data
//       const updatedData = response.data.data;
//       if (updatedData && updatedData.length > 0) {
//         const firstItemKeys = Object.keys(updatedData[0]);
        
//         // Transform the data so that each row is now a field, and the columns are the different agencies
//         const newData = firstItemKeys.map(key => ({
//           key: key,
//           name: key.replace(/_/g, ' ').replace(/\b\w/g, char => char.toUpperCase()),
//           ...updatedData.reduce((acc, item) => {
//             acc[item.id] = item[key];
//             return acc;
//           }, {})
//         }));
        
//         setTransformedData(newData);
//       }
//     })
//     .catch(error => {
//       console.error('Error saving data:', error);
//     });
//   };

//   return (
//     <div>
//       <Table
//         size="small"
//         dataSource={transformedData}
//         columns={columns}
//         rowKey="key"
//       />
//     </div>
//   );
// };

// export default Maintain_RatingAgencies;
