//Datafield_Treshold.jsx
import React, { useState, useEffect } from 'react';
import { Modal, InputNumber, Select } from 'antd';

const DatafieldThreshold = ({
  visible,
  onClose,
  onSave,
  currentThreshold,
  selectedDataFieldId,
  currentCategory,
  parametersData
}) => {
  const [threshold, setThreshold] = useState(currentThreshold);
  const [minValue, setMinValue] = useState(null);
  const [maxValue, setMaxValue] = useState(null);
  const [possibleValues, setPossibleValues] = useState([]);
  const [isNumeric, setIsNumeric] = useState(false);

  useEffect(() => {
    const dataField = parametersData.DP_dataset_json.find(p => p.ID === selectedDataFieldId);
    
    if (dataField) {
      if (dataField.Values) {
        setPossibleValues(dataField.Values.split(', '));
        setIsNumeric(false);
      } else if (!isNaN(dataField.Negative) && !isNaN(dataField.Positive)) {
        setMinValue(Math.min(dataField.Negative, dataField.Positive));
        setMaxValue(Math.max(dataField.Negative, dataField.Positive));
        setIsNumeric(true);
      }
    }
  }, [selectedDataFieldId, parametersData]);

  const handleSave = () => {
    // If the data field expects numeric values, ensure the input is numeric.
    if (isNumeric) {
      const numericThreshold = parseFloat(threshold);
      if (isNaN(numericThreshold)) {
        alert("Please enter a numeric value.");
        return;
      }
      if (numericThreshold < minValue || numericThreshold > maxValue) {
        alert(`Please enter a value between ${minValue} and ${maxValue}.`);
        return;
      }
      onSave(currentCategory, numericThreshold);
    } else {
      // For non-numeric fields, just save the selected value.
      onSave(currentCategory, threshold);
    }
    onClose();
  };
  

  return (
    <Modal
      title="Set Threshold"
      visible={visible}
      onOk={handleSave}
      onCancel={onClose}
    >
      <div>
        {isNumeric ? (
          <>
            <p>Possible values: in range between {minValue} and {maxValue}</p>
            <InputNumber
              min={minValue}
              max={maxValue}
              value={threshold}
              onChange={setThreshold}
              style={{ width: '100%' }}
            />
          </>
        ) : (
          <>
            <p>Possible values: {possibleValues.join(', ')}</p>
            <Select
              placeholder="Select a threshold value"
              value={threshold}
              onChange={setThreshold}
              style={{ width: '100%' }}
            >
              {possibleValues.map(value => (
                <Select.Option key={value} value={value}>
                  {value}
                </Select.Option>
              ))}
            </Select>
          </>
        )}
      </div>
    </Modal>
  );
};

export default DatafieldThreshold;
