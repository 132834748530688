//ToplineTable.jsx

import React, { useEffect, useState, useContext } from 'react';
import { Collapse, Table } from 'antd';
import { useSelector } from 'react-redux';
import api from '../functions/api';
import getUserId from '../functions/getUserId';

const { Panel } = Collapse;

const ToplineTable = ({ updatedCategoryRecords }) => {
  const [showSaxo, setShowSaxo] = useState(false);
  const [showDeGiro, setShowDeGiro] = useState(false);
  // const [showOther, setShowOther] = useState(false);
  const userId = getUserId();
  const demoMode = useSelector(state => state.demoMode.isDemoMode);
  
 
  useEffect(() => {
    // Make API call
    api.post('/Broker_checkifauthorisationavailable', { userId })
      .then(response => {
        console.log("check if DG or Saxo auth is available response.data:",response.data);
        setShowSaxo(response.data.saxo);
        setShowDeGiro(response.data.degiro);
      })
      .catch(error => {
        // Handle error
      });
      console.log('Other data:', otherData);
  }, []); 

  const overallTopline = useSelector(state => state.portfolio && state.portfolio.data ? state.portfolio.data.portfolio_topline : null);
  const saxoTopline = useSelector(state => state.portfolio && state.portfolio.data ? state.portfolio.data.saxo_topline : null);
  const dgTopline = useSelector(state => state.portfolio && state.portfolio.data ? state.portfolio.data.dg_portfolio_topline : null);
  const other =useSelector(state => state.portfolio && state.portfolio.data ? state.portfolio.data.Topline_Other: null);

  // Determine if the 'Other' section should be shown
  const showOther = other && other.length > 0;
  const otherName = showOther ? other[0].name : 'Other';

  

  const overallToplineData = (overallTopline && overallTopline[0]) || {};
  // console.log("overalltoplinedata:",overallToplineData);
  const saxoToplineData = (saxoTopline && saxoTopline[0]) || {};
  // console.log("saxotoplinedata:",saxoToplineData);
  const dgToplineData = (dgTopline && dgTopline[0]) || {};
  // console.log("dgtoplinedata:",dgToplineData);
  const otherData = (other && other[0]) || {};

  const renderIndicatorLabel = (text) => {
    if (text === 'Portfolio value') {
        return (
            <div>
                {text}
                <div style={{ fontSize: 'smaller', marginTop: '4px', fontStyle: 'italic' }}>cash</div>
            </div>
        );
    } else if (text === 'Overall return') {
        return (
            <div>
                {text}
                <div style={{ fontSize: 'smaller', marginTop: '4px', fontStyle: 'italic' }}>performance</div>
            </div>
        );
    }
    return text;
};

const renderCashAvailable = (text, record, column) => {
  let secondaryValue = '';
  let secondaryStyle = { fontSize: 'smaller', marginTop: '4px', fontStyle: 'italic' };

  switch(column) {
    case 'overall':
      if (record.indicator === 'Portfolio value') secondaryValue = record.overallCash;
      if (record.indicator === 'Overall return') secondaryValue = record.overallPerformance;
      break;
    case 'saxo':
      if (record.indicator === 'Portfolio value') secondaryValue = record.saxoCash;
      if (record.indicator === 'Overall return') secondaryValue = record.saxoPerformance;
      break;
    case 'degiro':
      if (record.indicator === 'Portfolio value') secondaryValue = record.degiroCash;
      if (record.indicator === 'Overall return') secondaryValue = record.degiroPerformance;
      break;
    case 'other':
      if (record.indicator === 'Portfolio value') secondaryValue = record.otherCash;
      if (record.indicator === 'Overall return') secondaryValue = record.otherPerformance;
      break;
  }

  // Ensure secondaryValue is a string before calling includes()
  const primaryDisplay = demoMode && typeof text === 'string' && text.includes('€') ? <span className="blur-text">{text}</span> : text;
  const secondaryDisplay = demoMode && typeof secondaryValue === 'string' && secondaryValue.includes('€') ? <span className="blur-text">{secondaryValue}</span> : secondaryValue;

  // const primaryDisplay = demoMode && text.includes('€') ? <span className="blur-text">{text}</span> : text;
  // const secondaryDisplay = demoMode && secondaryValue.includes('€') ? <span className="blur-text">{secondaryValue}</span> : secondaryValue;

  return (
    <div>
      {primaryDisplay}
      <div style={secondaryStyle}>{secondaryDisplay}</div>
    </div>
  );
};


  const dataSource = [
    {
      key: '1',
      indicator: 'Portfolio value',
      overall: overallToplineData.portfolio_total_value ? `€ ${overallToplineData.portfolio_total_value}` : '€ 0',
      overallCash: overallToplineData.cash_eur ? `€ ${overallToplineData.cash_eur}` : '€ 0',
      saxo: saxoToplineData.Saxo_portfolio_total_value ? `€ ${saxoToplineData.Saxo_portfolio_total_value}` : '€ 0',
      saxoCash: saxoToplineData.Saxo_cash_eur ? `€ ${saxoToplineData.Saxo_cash_eur}` : '€ 0',
      degiro: dgToplineData.portfolio_total_value ? `€ ${dgToplineData.portfolio_total_value}` : '€ 0',
      degiroCash: dgToplineData.cash_eur_value ? `€ ${dgToplineData.cash_eur_value}` : '€ 0',
      other: otherData.value ? `€ ${otherData.value}` : '€ 0',
      // otherCash: otherData.cashinenout ? `€ ${otherData.cashinenout}` : '€ 0'
    },
    
    {
      key: '2',
      indicator: 'Overall return',
      overall: overallToplineData.portfolio_result ? `€ ${overallToplineData.portfolio_result}` : '€ 0',
      overallPerformance: overallToplineData.portfolio_performance ? `${overallToplineData.portfolio_performance}%` : 'N/A',
      saxo: saxoToplineData.Saxo_portfolio_result ? `€ ${saxoToplineData.Saxo_portfolio_result}` : '€ 0',
      saxoPerformance: saxoToplineData.Saxo_portfolio_performance ? `${saxoToplineData.Saxo_portfolio_performance}%` : 'N/A',
      degiro: dgToplineData.portfolio_result ? `€ ${dgToplineData.portfolio_result}` : '€ 0',
      degiroPerformance: dgToplineData.portfolio_performance ? `${dgToplineData.portfolio_performance}%` : 'N/A',
      other: otherData.result_value ? `€ ${otherData.result_value}` : '€ 0',
      otherPerformance: otherData.performance ? ` ${otherData.performance}%` : 'N/A',
      
    },
  ];
  
  const baseColumns = [
   {
        title: '',
        dataIndex: 'indicator',
        key: 'indicator',
        render: renderIndicatorLabel // Use this custom render function for the indicator column
    },
    {
        title: 'Overall',
        dataIndex: 'overall',
        key: 'overall',
        render: (text, record) => renderCashAvailable(text, record, 'overall')
    }
  ];
  
  if (showSaxo) {
    baseColumns.push({
      title: 'Saxo',
      dataIndex: 'saxo',
      render: (text, record) => renderCashAvailable(text, record, 'saxo')
    });
  }
  
  if (showDeGiro) {
    baseColumns.push({
      title: 'DeGiro',
      dataIndex: 'degiro',
      key: 'degiro',
      render: (text, record) => renderCashAvailable(text, record, 'degiro')
    });
  }
  
  if (showOther) {
    baseColumns.push({
      title: otherName,
      // title: 'Other',
      dataIndex: 'other',
      key: 'other',
      render: (text, record) => renderCashAvailable(text, record, 'other') // Ensure this calls the renderCashAvailable function
      // render: renderCashAvailable // No need to adjust for 'other' as it's 'N/A'
    });
  }


  
  const portfolioData = overallTopline ? overallTopline[0] : {};

 // Conditional styling based on demo mode
 const headerStyle = demoMode ? "blur-text" : "";
 const resultText = portfolioData.portfolio_result ? `€${portfolioData.portfolio_result}` : 'N/A';
 const performanceText = portfolioData.portfolio_performance ? `${portfolioData.portfolio_performance}%` : 'N/A';

 const headerContent = (
   <div>
     YTD Result: <span className={headerStyle}>{resultText}</span>, YTD Performance: {performanceText}
   </div>
 );


  return (
    <Collapse defaultActiveKey={['1']}>
      <Panel 
      header={headerContent}
        // header={`YTD Result: €${overallToplineData.portfolio_result || 'N/A'}, YTD Performance: ${overallToplineData.portfolio_performance || 'N/A'}%`} 
        key="1"
      >
        <Table dataSource={dataSource} columns={baseColumns} pagination={false} />
      </Panel>
    </Collapse>
  );
};

export default ToplineTable;
