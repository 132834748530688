import React, { useState } from 'react';
import { Modal, Button, Spin, Input, message, Table, Tooltip } from 'antd';
import ThreeTablesModal from "./SearchResultModal";
import api from '../functions/api';
import getUserId from '../functions/getUserId';
import { useSelector, useDispatch } from 'react-redux';


const Ask = ({ disabled }) => {
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [questions, setQuestions] = useState([]);
  const [userQuestion, setUserQuestion] = useState('');
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [response, setResponse] = useState(null);
  const [threeTablesModalVisible, setThreeTablesModalVisible] = useState(false);
  const [selectedTicker, setSelectedTicker] = useState('');
  const dataparameters = useSelector(state => state.portfolio.parametersData);
  const [sqlValue, setSqlValue] = useState('');
  const [submissionAttempted, setSubmissionAttempted] = useState(false);
  const [isAddToWidgetOptionsDisabled, setIsAddToWidgetOptionsDisabled] = useState(true); // New state for button activity
  

  // Add this function inside your Ask component
const addTraining = async () => {
    try {
      const userId = getUserId(); // Assuming getUserId() fetches the current user's ID
      const question = userQuestion; // The question entered by the user
      const sql = sqlValue; // The SQL query obtained from the previous response
  
      // Make sure your API endpoint and method match your server configuration
      const response = await api.post('/ask', {
        question,
        userId,
        sql
      });
  
      // Check response status or data as needed to confirm success
      if (response.status === 200) {
        message.success('Training added successfully');
      } else {
        // Handle any error or unsuccessful status codes appropriately
        message.error('Failed to add training');
      }
    } catch (error) {
      console.error('Error adding training:', error);
      message.error('Error adding training');
    }
  };
  

  // Add this function inside your Ask component
  const addToWidgetOptions = async () => {
    try {
      const userid = getUserId();
      const type = 'table';
      const subtype = 'vanna';
      const description = userQuestion;
      const title = userQuestion;
  
      const response = await api.post('/ask_widget', {
        userid,
        type,
        subtype,
        description,
        title
      });
  
      if (response.status === 200) {
        message.success('Widget option added successfully');
        setIsAddToWidgetOptionsDisabled(true); // Disable the button upon successful processing
      } else {
        message.error('Failed to add widget option');
      }
    } catch (error) {
      console.error('Error adding to widget options:', error);
      message.error('Error adding to widget options');
    }
  };
  


  // Handle clicks on ticker column cells
  const handleTickerClick = (tickerValue) => {
    setSelectedTicker(tickerValue);
    setThreeTablesModalVisible(true);
  };

  const showModal = () => {
    setIsModalVisible(true);
    fetchQuestions();
  };

  const handleOk = async () => {
    if (userQuestion) {
      setSubmissionAttempted(true);
      setIsSubmitting(true);
      try {
        const userId = getUserId();
        const response = await api.get('/ask', { params: { question: userQuestion, userId: userId } });
        
        if (response.data) {
          console.log("Response from ask", response.data)
          setResponse(response.data);
          setIsAddToWidgetOptionsDisabled(false); // Enable the button again for a new response
          setSqlValue(response.data.sql);
          message.success('Question submitted successfully');
        } else {
          setResponse(null);
          message.error('Error submitting question - no response data found');
        }
      } catch (error) {
        console.error('Error submitting question', error);
        message.error('Error submitting question');
        setResponse(null);
      } finally {
        setIsSubmitting(false);
      }
    } else {
      message.warn('Please enter a question before submitting.');
    }
  };

  const handleCancel = () => {
    setIsModalVisible(false);
    setUserQuestion(''); // Clear the input box when the modal is manually closed.
    setResponse(null); // Optionally reset the response state as well.
    setSubmissionAttempted(false);
  };
  

  const fetchQuestions = async () => {
    setIsLoading(true);
    try {
      const response = await api.get('/ask');
      if (response.data) {
        setQuestions(response.data);
      }
    } catch (error) {
      console.error('Error fetching questions', error);
    } finally {
      setIsLoading(false);
    }
  };

  const renderResponse = () => {
    console.log("RRResponse:", response);
  
    if (response && response.headers && response.rows) {
      // Define the renderHeader method here
      const renderHeader = (value, { tooltip }) => (
        tooltip ? <Tooltip title={tooltip}>{value}</Tooltip> : value
      );
  
      // Initial mappings for labels and tooltips from parametersData
      const dataFieldToLabelMap = dataparameters.DP_dataset_json.reduce((acc, cur) => {
        acc[cur.DataField] = { label: cur.Label, tooltip: cur.Tooltip };
        return acc;
      }, {});
  
      // Pre-sort headers to ensure 'ticker' and 'company' are in the correct order if present
      const sortedHeaders = ['ticker', 'company'].filter(header =>
        response.headers.find(h => h.toLowerCase() === header)
      ).concat(response.headers.filter(header =>
        !['ticker', 'company'].includes(header.toLowerCase())
      ));
  
      // Generate column definitions
      const columns = sortedHeaders.map((header) => {
        const isTickerColumn = header.toLowerCase() === 'ticker';
        const { label, tooltip } = dataFieldToLabelMap[header] || {};
  
        return {
          title: renderHeader(label || header.charAt(0).toUpperCase() + header.slice(1), { tooltip }),
          dataIndex: header,
          key: header,
          onCell: record => ({
            onClick: () => {
              if (isTickerColumn) {
                handleTickerClick(record[header]);
              }
            },
            style: isTickerColumn ? { cursor: 'pointer' } : undefined,
          }),
          render: text => isTickerColumn ? (
            <Tooltip title="Click for details">
              <span>{text}</span>
            </Tooltip>
          ) : text,
        };
      });
  
      const dataSource = response.rows.map((row, index) => ({
        key: index,
        ...row.reduce((obj, val, idx) => ({
          ...obj,
          [response.headers[idx]]: val
        }), {}),
      }));
  
      return (
        <>
          <p>{response.num_records} records</p>
          <Table
            dataSource={dataSource}
            columns={columns}
            pagination={{ pageSize: 10 }}
            scroll={{ x: 'max-content' }}
          />
          {threeTablesModalVisible && (
            <ThreeTablesModal
              userId={getUserId()}
              tickerValue={selectedTicker}
              open={threeTablesModalVisible}
              onClose={() => setThreeTablesModalVisible(false)}
            />
          )}
        </>
      );
    } else if (response && response !== null && typeof response !== 'undefined' && response.data !== null) {
      return <p>The response is: {response.toString()}</p>;
    }else if (response && response.status !== 200){
      console.log("SORRY");
      return <p>Sorry, your question was not understood well enough.</p>;
    }
  };
  

return (
    <>
      <Button type="primary" onClick={showModal} disabled={disabled}>
        Ask
      </Button>
      <Modal
        title="Ask"
        visible={isModalVisible}
        onOk={handleOk}
        onCancel={handleCancel}
        width={1200}
        footer={[
          <Button key="back" onClick={handleCancel}>
            Cancel
          </Button>,
          <Button
            key="submit"
            type="primary"
            loading={isSubmitting}
            onClick={handleOk}
            disabled={!userQuestion.trim()} // Disable button when userQuestion is empty or only contains whitespace
          >
            Submit
          </Button>,
        ]}
      >
        <Input
          placeholder="Your question pls"
          value={userQuestion}
          onChange={e => {
            setUserQuestion(e.target.value);
            setSubmissionAttempted(false); // Reset submission attempt flag when typing
          }}
          style={{ marginTop: 16 }}
        />
        {/* Display the response directly under the input box */}
        {response ? (
          <div style={{ marginTop: 16 }}>
            <p><strong>Response:</strong></p>
            {renderResponse()}
            <div style={{ marginTop: 16 }}>
              <Button
                    onClick={addToWidgetOptions}
                    disabled={isAddToWidgetOptionsDisabled} // Use the new state here
                >
                    Add to your widget options
                </Button>
              {getUserId() === 2 && (
                <Button
                style={{ marginLeft: 8 }} // Add some spacing between buttons
                onClick={addTraining} // Call the addTraining function when the button is clicked
              >
                Add Training
              </Button>
              
              )}
            </div>
          </div>
        ) 
        : submissionAttempted && !response && userQuestion.trim() ? (
          null 
          // <p style={{ marginTop: 16 }}>Sorry!!, your question was not understood well enough.</p>
        ) : null
        }
        
        {/* Show loading spinner or questions below the response */}
        {isLoading ? (
          <Spin tip="Some example questions are being retrieved...">
            <div style={{ height: 150 }} />
          </Spin>
        ) : (
          <div style={{ marginTop: 16 }}>
            <strong>Some sample questions</strong>
            {questions.map((question, index) => <p key={index}>{question}</p>)}
          </div>
        )}
      </Modal>
    </>
  );
  
  
};

export default Ask;
