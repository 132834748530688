import React from 'react';
import { LineChart, Line, XAxis, Tooltip, ResponsiveContainer } from 'recharts';
import '../css/MiniGraph.css';


const MiniGraph = ({ data }) => {
    // console.log("MiniGraph data:", data);
    
    return (
        <div className="graph-container" style={{ width: '100%', height: '300px'}}>
            <ResponsiveContainer width="100%" height="100%">
                <LineChart data={data} margin={{ top: 0, right: 0, bottom: 5, left: 0 }}>
                    <XAxis dataKey="date" hide={true} />
                    <Tooltip />
                    <Line type="monotone" dataKey="price" stroke="#8884d8" dot={false} />
                </LineChart>
            </ResponsiveContainer>
        </div>
    );
};


export default MiniGraph;
