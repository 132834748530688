// src/redux/actions/etfActions.js
import api from '../../functions/api';

export const FETCH_ETF_SUCCESS = 'FETCH_ETF_SUCCESS';
export const FETCH_ETF_FAILURE = 'FETCH_ETF_FAILURE';

export const fetchEtfSuccess = (etfData, distinctCurrencies, distinctCategories, distinctIssuers) => ({
  type: FETCH_ETF_SUCCESS,
  payload: { etfData, distinctCurrencies, distinctCategories, distinctIssuers },
});

export const fetchEtfFailure = (error) => ({
  type: FETCH_ETF_FAILURE,
  payload: error,
});

export const fetchEtfData = () => async (dispatch) => {
  try {
    const response = await api.get('/etf_load');
    console.log('Response keys:', Object.keys(response.data));

    if (response && response.data && response.data.etf_symbols_and_names) {
      const etfData = response.data.etf_symbols_and_names;

      // Extract distinct values
      const distinctCurrencies = [...new Set(etfData.map(etf => etf.currency).filter(Boolean))];
      const distinctCategories = [...new Set(etfData.map(etf => etf.category).filter(Boolean))];
      const distinctIssuers = [...new Set(etfData.map(etf => etf.issuer).filter(Boolean))];

      console.log('etf_symbols_and_names length:', etfData.length);
      dispatch(fetchEtfSuccess(etfData, distinctCurrencies, distinctCategories, distinctIssuers));
    } else {
      dispatch(fetchEtfFailure('Unexpected API response structure'));
    }
  } catch (error) {
    console.error('Error:', error);
    dispatch(fetchEtfFailure(error.message));
  }
};

// src/redux/actions/etfActions.js
export const CLEAR_ETF_DATA = 'CLEAR_ETF_DATA';

export const clearEtfData = () => ({
  type: CLEAR_ETF_DATA,
});

