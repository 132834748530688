import React from 'react';
import { Modal, Button } from 'antd';
import ESG_ETFDetailsContent from './ESG_ETFDetailsContent'; // Import the content component
import DownloadPDF from './DownloadPDF';

function ESG_ETFDetails({ visible, onClose, etfData, etfEsgHighlights }) {
  console.log("ESG_ETFDetails triggered and data received ", etfData);
  console.log("ESG_ETFDetails triggered and highlights received ", etfEsgHighlights);

  // Generate the dynamic filename
  const pdfFilename = `ESG overview for ETF ${etfData.etfName}`;

  return (
    <Modal
      title={`ESG Details for  ${etfData.etfName} (${etfData.ticker})`}
      visible={visible}
      onCancel={onClose}
      width={1200}
      footer={[
        etfData && (
          <DownloadPDF
            key="download"
            contentId="ESG_ETFDetailsContent"
            filename={pdfFilename}  // Use the dynamic filename here
          />
        ),
        <Button key="back" onClick={onClose}>
          Cancel
        </Button>,
        <Button key="submit" type="primary" onClick={onClose}>
          OK
        </Button>,
      ]}
    >
      <ESG_ETFDetailsContent 
        etfData={etfData} 
        etfEsgHighlights={etfEsgHighlights}
      />
      {/* Add the italic text above the footer buttons */}
      <div style={{ marginTop: '20px', textAlign: 'center' }}>
        <span style={{ fontStyle: 'italic', fontSize: 'small' }}>
          click on company records to view more details
        </span>
      </div>
    </Modal>
  );
}

export default ESG_ETFDetails;
