const initialState = {
  isDebugMode: JSON.parse(localStorage.getItem('isDebugMode')) || false
};

const debugModeReducer = (state = initialState, action) => {
  switch (action.type) {
    case 'TOGGLE_DEBUG_MODE':
      // Store the updated state in local storage
      localStorage.setItem('isDebugMode', JSON.stringify(action.payload));
      return {
        ...state,
        isDebugMode: action.payload
      };
    default:
      return state;
  }
};

export default debugModeReducer;
