// ESG_ColorForValue.jsx

/**
 * Function to determine the color for a given value based on ESG parameters.
 * @param {string} dataField - The data field name to be checked.
 * @param {string|number} value - The value to be checked against thresholds.
 * @param {object} dataFieldMapping - The mapping of data fields to their corresponding thresholds.
 * @returns {string} The color representing the ESG status for the value.
 */
export const getColorForValue = (dataField, value, dataFieldMapping) => {

    // console.log("IN COLORS     Data Field Mapping:", dataFieldMapping);

    // console.log(`Checking color for field: ${dataField}, value: ${value}`);
    
  
    const params = dataFieldMapping[dataField];
    let color = 'default';
  
    if (params && value !== null && value !== undefined) {
      const { Green, Lightgreen, Yellow, Orange, Red } = params;
  
      const checkValue = (colorDef) => {
        if (!colorDef) return false;
  
        const valuesOrRanges = colorDef.split(',').map((v) => v.trim());
  
        return valuesOrRanges.some((item) => {
          if (item.includes('-')) {
            const [min, max] = item.split('-');
            // Handle numeric ranges
            if (!isNaN(min) && !isNaN(max)) {
              const numValue = Number(value);
              return numValue >= parseFloat(min) && numValue <= parseFloat(max);
            }
            // Handle letter ranges (e.g., "AAA-AA")
            const rangeValues = [min, max].sort();
            return value >= rangeValues[0] && value <= rangeValues[1];
          }
          return item === value.toString(); // Check exact match for strings
        });
      };
  
      if (checkValue(Green)) color = 'green';
      if (checkValue(Lightgreen)) color = 'lightgreen';
      if (checkValue(Yellow)) color = 'yellow';
      if (checkValue(Orange)) color = 'orange';
      if (checkValue(Red)) color = 'red';
    }
  
    // console.log("color ", color, "for value : ", value);
    return color;
  };
  