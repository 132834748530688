///PreferencesModal.jsx

import React, { useEffect, useState, useRef } from 'react';
import { Modal, Checkbox, Button, Tabs, Tooltip, message } from 'antd';
import api from '../functions/api';
import { groupBy } from 'lodash';
import { useSelector} from 'react-redux';
import DataAlertPreferences from './DataAlertPreferences'; // Import the new component

const { TabPane } = Tabs;



const PreferencesModal = ({ userId, onClose }) => {
  console.log('PreferencesModal is being rendered with userId:', userId);
  const [loading, setLoading] = useState(true);
  const [preferences, setPreferences] = useState([]);
  const [preferencesByCategory, setPreferencesByCategory] = useState({}); // Set the state for preferencesByCategory
  // Access data from Redux store
  const tableDatafieldPrefs = useSelector(state => state.portfolio?.data?.table_datafield_prefs);

  // const tableDatafieldPrefs = useSelector((state) => state.portfolio.data.table_datafield_prefs);
  const [tablePreferences, setTablePreferences] = useState([]);
  // const [selectedPreferences, setSelectedPreferences] = useState({});
  // State to track the active tab
  const dataAlertPreferencesRef = useRef();
  const [activeTab, setActiveTab] = useState('1');
  
  // const allState = useSelector(state => state);
// 

useEffect(() => {
  if (tableDatafieldPrefs && tableDatafieldPrefs.length > 0) {
      const transformedPrefs = tableDatafieldPrefs.map((item) => ({
          dataFieldId: item.DataFieldID,
          dataField: item.Label,
          tooltip: item.Tooltip,
          isSelected: item.IsPreferred,
          category: item.Category,
      }));
      setPreferences(transformedPrefs);
      setPreferencesByCategory(groupBy(transformedPrefs, 'category'));
  }
  setLoading(false);
}, [tableDatafieldPrefs]);

  useEffect(() => {
    setPreferencesByCategory(groupBy(preferences, 'category')); 
  }, [preferences]);
  

  const handleCheckboxChange = (event) => {
    const name = event.target.name;
    const checked = event.target.checked;
  
    console.log(`Name: ${name}, Checked: ${checked}`); // add this line
    
    setPreferences((currentPreferences) =>
      currentPreferences.map((preference) =>
        preference.dataFieldId.toString() === name ? { ...preference, isSelected: checked } : preference
      )
    );
  };
  
 
  const savePreferences = async () => {
    if (activeTab === '1') {
      console.log('Saving table data preferences:', preferences);
      try {
        const response = await api.post(`/save_field_prefs/${userId}`, preferences);
  
        if (response.status !== 200) {
          throw new Error('Failed to save preferences');
        }
  
        message.success('Preferences saved successfully');
      } catch (error) {
        console.error('Error saving preferences:', error);
        message.error('Failed to save preferences');
      }
    } else if (activeTab === '2' && dataAlertPreferencesRef.current) {
      dataAlertPreferencesRef.current.savePPreferences();
    }
  };
  
  

  // Function to render preferences with checkboxes
  const renderPreferences = (withCheckboxes) => {
    return Object.entries(preferencesByCategory).map(([category, categoryPreferences]) => (
        <div key={category}>
            <h2>{category}</h2>
            <div className="checkbox-grid">
                {categoryPreferences.map((preference) => (
                    <Tooltip title={preference.tooltip} key={preference.dataFieldId} mouseEnterDelay={1.5}>
                        {withCheckboxes ? (
                            <Checkbox
                                name={preference.dataFieldId.toString()}
                                checked={preference.isSelected || false}
                                onChange={handleCheckboxChange}
                                className="preferences-checkbox"
                            >
                                {preference.dataField}
                            </Checkbox>
                        ) : (
                            <span>{preference.dataField}</span>
                        )}
                    </Tooltip>
                ))}
            </div>
        </div>
    ));
};

 
  return (
    <Modal
    title="Set Datafield Preferences"
    open={true}
    onCancel={onClose}
    footer={[<Button onClick={onClose} type="default" className="ok-button">OK</Button>]}
    // footer={[renderFooter()]}
    loading={loading}
    width={1400}
    >
      <Tabs defaultActiveKey="1">
        <TabPane tab="TableDataPreferences" key="1">
          {!loading && renderPreferences(true)}
          <Button onClick={savePreferences} type="primary" className="save-button">
           Save
          </Button>
          </TabPane>
        <TabPane tab="DataAlertPreferences" key="2">
          <DataAlertPreferences 
            ref={dataAlertPreferencesRef}
            preferencesByCategory={preferencesByCategory}
            userId={userId} />
        </TabPane>

      
      </Tabs>


    </Modal>
  );
};


export default PreferencesModal;


