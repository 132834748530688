// // // ESG_Ticker_List.jsx
// ESG_Ticker_List.jsx

import React, { useState } from 'react';
import { Table } from 'antd';
import { useSelector } from 'react-redux';
import { getColorForValue } from '../functions/ESG_ColorForValue'; // Import the utility function
import '../css/ESG_MainTable.css'; // Import custom CSS
import ESG_Details from './ESG_Details'; // Import ESG_Details for modal

const ESG_Ticker_List = ({ tickersData, notFoundTickersData, unknownData }) => {
  console.log("TTTTT tickerlist triggered");
  console.log("tickersData", tickersData);
  console.log("notFoundTickersData", notFoundTickersData);

  // State to manage modal visibility and selected ticker data
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [selectedDetails, setSelectedDetails] = useState(null);

  // Retrieve DP_dataset_json from Redux store
  const dataparametersSet = useSelector(
    (state) => state.portfolio?.parametersData?.DP_dataset_json || []
  );

  // Create a mapping for quick look-up for color parameters
  const dataFieldMapping = {};

  dataparametersSet.forEach((param) => {
    dataFieldMapping[param.DataField] = {
      ...param,
      source: param.source || null, // Add source to the mapping
    };
  });

  // Extract data fields with esg_summary_order
  const highlightFields = dataparametersSet
    .filter(
      (param) =>
        param.esg_summary_order !== null &&
        param.esg_summary_order !== "0.00" &&
        param.esg_summary_order !== 0
    )
    .sort((a, b) => parseFloat(a.esg_summary_order) - parseFloat(b.esg_summary_order));

  console.log("!!!!!highlightFields:", highlightFields);

  // Define columns for the main table based on highlightFields
  const columns = [
    {
      title: 'Ticker',
      dataIndex: 'ticker',
      key: 'ticker',
      fixed: 'left', // Keep ticker fixed for easier scrolling
      render: (text, record) => (
        <div>
          <div>{text}</div>
          <div style={{ fontStyle: 'italic', fontSize: '12px', color: '#888' }}>
            {record.holdingPercent ? `${(record.holdingPercent * 100).toFixed(2)}%` : ''}
          </div>
        </div>
      ),
    },
    {
      title: 'Company',
      dataIndex: 'company',
      key: 'company',
      fixed: 'left',
    },
    ...highlightFields.map((field) => ({
      title: (
        <div>
          <span>{field.Label || field.DataField}</span>
          {dataFieldMapping[field.DataField]?.source && (
            <div style={{ fontStyle: 'italic', fontSize: '12px', color: '#888' }}>
              {dataFieldMapping[field.DataField].source}
            </div>
          )}
        </div>
      ),
      dataIndex: field.DataField,
      key: field.DataField,
      render: (value) => {
        // If the value is 0 or 0.00, change it to "N/A"
        const displayValue = value === 0 || value === "0.00" ? "N/A" : value;
        const color = getColorForValue(field.DataField, displayValue, dataFieldMapping);
        const className = `cell-${color}`; // Use CSS class for styling
        return (
          <span className={className}>
            {displayValue}
          </span>
        );
      },
    })),
  ];

  // Prepare data for the main table, ensuring only relevant fields are included
  const dataSource = tickersData.map((tickerData, index) => {
    // Create a new object that only includes fields present in highlightFields
    const filteredData = {
      key: index,
      ticker: tickerData.ticker,
      company: tickerData.company,
      holdingPercent: tickerData.holdingPercent, // Add holdingPercent to data source
    };

    highlightFields.forEach((field) => {
      filteredData[field.DataField] = tickerData[field.DataField];
    });

    return filteredData;
  });

  // Define columns for the ETFsNotFound table
  const notFoundColumns = [
    {
      title: 'ETF Symbol',
      dataIndex: 'symbol',
      key: 'symbol',
      render: (text, record) => (
        <div>
          <div>{text}</div>
          <div style={{ fontStyle: 'italic', fontSize: '12px', color: '#888' }}>
            {record.holdingPercent ? `${(record.holdingPercent * 100).toFixed(2)}%` : ''}
          </div>
        </div>
      ),
    },
    {
      title: 'ETF Name',
      dataIndex: 'name',
      key: 'name',
    },
  ];

  // Prepare data for the ETFsNotFound table
  const notFoundDataSource = notFoundTickersData?.map((etfData, index) => ({
    key: index,
    symbol: etfData.symbol,
    name: etfData.name,
    holdingPercent: etfData.holdingPercent,
  }));

  // Handle row click to open modal
  const handleRowClick = (record) => {
    // Find the full record in tickersData based on the ticker
    const fullRecord = tickersData.find((tickerData) => tickerData.ticker === record.ticker);
    setSelectedDetails(fullRecord); // Set the selected ticker data with full details
    setIsModalVisible(true); // Open the modal
  };

  // Calculate total percentage of ETFs in holdings
const totalHoldingPercent = notFoundDataSource
? notFoundDataSource.reduce((total, etf) => total + (etf.holdingPercent || 0), 0)
: 0;

// Define columns for the UnknownThingsFound table
const unknownColumns = [
  {
    title: 'Ticker',
    dataIndex: 'ticker',
    key: 'ticker',
    render: (text, record) => (
      <div>
        <div>{text}</div>
        <div style={{ fontStyle: 'italic', fontSize: '12px', color: '#888' }}>
          {record.holdingPercent ? `${(record.holdingPercent * 100).toFixed(2)}%` : ''}
        </div>
      </div>
    ),
  },
  {
    title: 'Holding Name',
    dataIndex: 'holdingName',
    key: 'holdingName',
  },
];

// Prepare data for the UnknownThingsFound table
const unknownDataSource = unknownData?.map((item, index) => ({
  key: index,
  ticker: item.ticker,
  holdingName: item.holdingName,
  holdingPercent: item.holdingPercent,
}));



  return (
    <>
     {dataSource && dataSource.length > 0 && (
  <>
    <h4>ESG highlights of Holdings in stock</h4>
    <Table
      columns={columns}
      dataSource={dataSource}
      pagination={false}
      scroll={{ x: 'max-content' }} // Enable horizontal scrolling
      className="esgmaintable-custom-font" // Apply custom font style
      onRow={(record) => ({
        onClick: () => handleRowClick(record), // Click event for row
      })}
    />
  </>
)}


      
      {/* Render the ETFsNotFound table if data is present */}
      {notFoundDataSource && notFoundDataSource.length > 0 && (
  <>
    <h4>ETFs in holdings (total of {(totalHoldingPercent * 100).toFixed(2)}%)</h4>
    <Table
      columns={notFoundColumns}
      dataSource={notFoundDataSource}
      pagination={false}
      scroll={{ x: 'max-content' }} // Enable horizontal scrolling
      className="esgmaintable-custom-font" // Apply custom font style
    />
  </>
)}

{/* Render the UnknownThingsFound table if data is present */}
{unknownDataSource && unknownDataSource.length > 0 && (
        <>
          <h4>Unknown Holding assets:</h4>
          <Table
            columns={unknownColumns}
            dataSource={unknownDataSource}
            pagination={false}
            scroll={{ x: 'max-content' }} // Enable horizontal scrolling
            className="esgmaintable-custom-font" // Apply custom font style
          />
        </>
      )}

      {selectedDetails && (
        <ESG_Details
          visible={isModalVisible}
          onClose={() => setIsModalVisible(false)}
          ticker={selectedDetails.ticker}
          details={selectedDetails} // Pass only the selected ticker details
        />
      )}
    </>
  );
};

export default ESG_Ticker_List;

// import React, { useState } from 'react';
// import { Table } from 'antd';
// import { useSelector } from 'react-redux';
// import { getColorForValue } from '../functions/ESG_ColorForValue'; // Import the utility function
// import '../css/ESG_MainTable.css'; // Import custom CSS
// import ESG_Details from './ESG_Details'; // Import ESG_Details for modal

// const ESG_Ticker_List = ({ tickersData }) => {
//   console.log("TTTTT tickerlist triggered")
//   console.log("tickersData", tickersData);

//   // State to manage modal visibility and selected ticker data
//   const [isModalVisible, setIsModalVisible] = useState(false);
//   const [selectedDetails, setSelectedDetails] = useState(null);

//   // Retrieve DP_dataset_json from Redux store
//   const dataparametersSet = useSelector(
//     (state) => state.portfolio?.parametersData?.DP_dataset_json || []
//   );

//   // Create a mapping for quick look-up for color parameters
//   const dataFieldMapping = {};

//   dataparametersSet.forEach((param) => {
//   dataFieldMapping[param.DataField] = {
//     ...param,
//     source: param.source || null, // Add source to the mapping
//   };
// });

//   // Extract data fields with esg_summary_order
//   const highlightFields = dataparametersSet
//     .filter(
//       (param) =>
//         param.esg_summary_order !== null &&
//         param.esg_summary_order !== "0.00" &&
//         param.esg_summary_order !== 0
//     )
//     .sort((a, b) => parseFloat(a.esg_summary_order) - parseFloat(b.esg_summary_order));

//     console.log("!!!!!highlightFields:", highlightFields);

//   // Define columns for the table based on highlightFields
//   const columns = [
//     {
//       title: 'Ticker',
//       dataIndex: 'ticker',
//       key: 'ticker',
//       fixed: 'left', // Keep ticker fixed for easier scrolling
//       render: (text, record) => (
//         <div>
//           <div>{text}</div>
//           <div style={{ fontStyle: 'italic', fontSize: '12px', color: '#888' }}>
//             {record.holdingPercent ? `${(record.holdingPercent * 100).toFixed(2)}%` : ''}
//           </div>
//         </div>
//       ),
//     },
//     {
//       title: 'Company',
//       dataIndex: 'company',
//       key: 'company',
//       fixed: 'left',
//     },
//     ...highlightFields.map((field) => ({
//       title: (
//         <div>
//           <span>{field.Label || field.DataField}</span>
//           {dataFieldMapping[field.DataField]?.source && (
//             <div style={{ fontStyle: 'italic', fontSize: '12px', color: '#888' }}>
//               {dataFieldMapping[field.DataField].source}
//             </div>
//           )}
//         </div>
//       ),
//       dataIndex: field.DataField,
//       key: field.DataField,
//       render: (value) => {
//         // If the value is 0 or 0.00, change it to "N/A"
//         const displayValue = (value === 0 || value === "0.00") ? "N/A" : value;
//         const color = getColorForValue(field.DataField, displayValue, dataFieldMapping);
//         const className = `cell-${color}`; // Use CSS class for styling
//         return (
//           <span className={className}>
//             {displayValue}
//           </span>
//         );
//       },
      
//     })),
//   ];
  
//   // Prepare data for the table, ensuring only relevant fields are included
//   const dataSource = tickersData.map((tickerData, index) => {
//     // Create a new object that only includes fields present in highlightFields
//     const filteredData = {
//       key: index,
//       ticker: tickerData.ticker,
//       company: tickerData.company,
//       holdingPercent: tickerData.holdingPercent, // Add holdingPercent to data source
//     };

//     highlightFields.forEach((field) => {
//       filteredData[field.DataField] = tickerData[field.DataField];
//     });

//     // console.log("Filtered Data: ", filteredData);
//     return filteredData;
//   });

//   // Handle row click to open modal
//   const handleRowClick = (record) => {
//     // Find the full record in tickersData based on the ticker
//     const fullRecord = tickersData.find((tickerData) => tickerData.ticker === record.ticker);
//     setSelectedDetails(fullRecord); // Set the selected ticker data with full details
//     setIsModalVisible(true); // Open the modal
//   };

//   return (
//     <>
//       <Table
//         columns={columns}
//         dataSource={dataSource}
//         pagination={false}
//         scroll={{ x: 'max-content' }} // Enable horizontal scrolling
//         className="esgmaintable-custom-font" // Apply custom font style
//         onRow={(record) => ({
//           onClick: () => handleRowClick(record), // Click event for row
//         })}
//       />
//       {selectedDetails && (
//         <ESG_Details
//           visible={isModalVisible}
//           onClose={() => setIsModalVisible(false)}
//           ticker={selectedDetails.ticker}
//           details={selectedDetails} // Pass only the selected ticker details
//         />
//       )}
//     </>
//   );
// };

// export default ESG_Ticker_List;


