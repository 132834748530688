import React, { useState, useEffect } from 'react';
import { Modal, Button, Table, Input, DatePicker, InputNumber, message, Select, Popconfirm } from 'antd';
import moment from 'moment';  // Import moment
import api from '../functions/api';  // Adjust the path based on your folder structure
import getUserId from '../functions/getUserId';  // Import your getUserId function

const { Option } = Select;

const InvestmentGoals = ({ disabled }) => {
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [investmentGoalsData, setInvestmentGoalsData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [newRecord, setNewRecord] = useState({ description: '', date: null, amount: null, Priority: null });
  const [isSaveEnabled, setIsSaveEnabled] = useState(false);
  const [isAddingNew, setIsAddingNew] = useState(false); // To toggle adding new row
  const [editingRecord, setEditingRecord] = useState(null); // To handle editing records

  const showModal = () => {
    setIsModalVisible(true);
    fetchInvestmentGoals();
  };

  const handleOk = () => {
    setIsModalVisible(false);
    setIsAddingNew(false);
    setEditingRecord(null);
    setNewRecord({ description: '', date: null, amount: null, Priority: null });
  };

  const handleCancel = () => {
    setIsModalVisible(false);
    setIsAddingNew(false);
    setEditingRecord(null);
    setNewRecord({ description: '', date: null, amount: null, Priority: null });
  };

  const fetchInvestmentGoals = async () => {
    setLoading(true);
    try {
      const userId = getUserId();
      const response = await api.get('/Investment_goals', {
        params: { userid: userId },
      });
      // Sort by status first (active at the top), then by Priority (ascending order)
      const sortedData = response.data.sort((a, b) => {
        if (a.status === b.status) {
          return a.Priority - b.Priority;  // Sort by Priority if status is the same
        }
        return b.status - a.status;  // Sort by status, active (1) first, inactive (0) last
      });
      setInvestmentGoalsData(sortedData);
      setLoading(false);
    } catch (error) {
      message.error('Error fetching investment goals data');
      setLoading(false);
    }
  };
  
  

  const handleNewRecordChange = (key, value) => {
    const updatedRecord = { ...newRecord, [key]: value };
    setNewRecord(updatedRecord);
    // Enable the save button if all fields are filled
    const isFilled = updatedRecord.description && updatedRecord.date && updatedRecord.amount && updatedRecord.Priority;
    setIsSaveEnabled(isFilled);
  };

  const handleEditRecordChange = (key, value) => {
    const updatedRecord = { ...editingRecord, [key]: value };
    setEditingRecord(updatedRecord);
  };

  const saveNewRecord = async () => {
    const userId = getUserId();
    try {
      await api.post('/Investment_goals', {
        userid: userId,
        description: newRecord.description,
        date: newRecord.date.format('YYYY-MM-DD'), // Use moment.js to format the date
        amount: newRecord.amount,
        Priority: newRecord.Priority,
        status: 1, // Set status to 'Active'
      });
      message.success('New record saved successfully!');
      fetchInvestmentGoals();  // Fetch updated data after saving
      setIsAddingNew(false);
      setNewRecord({ description: '', date: null, amount: null, Priority: null });
    } catch (error) {
      message.error('Error saving new record');
    }
  };

  const saveEditedRecord = async () => {
    const userId = getUserId();
    try {
      await api.put(`/Investment_goals/${editingRecord.ID}`, {
        userid: userId,
        description: editingRecord.description,
        date: editingRecord.date.format('YYYY-MM-DD'),
        amount: editingRecord.amount,
        Priority: editingRecord.Priority,
        status: editingRecord.status,
      });
      message.success('Record updated successfully!');
      fetchInvestmentGoals();
      setEditingRecord(null);
    } catch (error) {
      message.error('Error updating record');
    }
  };

  const startEditing = (record) => {
    setEditingRecord({ ...record, date: moment(record.date) }); // Use moment.js to set the selected record to editing
  };

  const deleteRecord = async (recordID) => {
    const userId = getUserId();
    try {
      await api.delete(`/Investment_goals/${recordID}`, {
        params: { userid: userId },
      });
      message.success('Record deleted successfully!');
      fetchInvestmentGoals(); // Fetch updated data after deletion
    } catch (error) {
      message.error('Error deleting record');
    }
  };

  const columns = [
    // {
    //   title: 'ID',
    //   dataIndex: 'ID',
    //   key: 'ID',
    // },
    {
      title: 'Priority',
      dataIndex: 'Priority',
      key: 'Priority',
    },
    {
      title: 'Description',
      dataIndex: 'description',
      key: 'description',
    },
    {
      title: 'Date',
      dataIndex: 'date',
      key: 'date',
    },
    {
      title: 'Amount Goal',
      dataIndex: 'amount',
      key: 'amount',
    },
    {
      title: 'Available in Cash',
      dataIndex: 'Available_cash',
      key: 'Available_cash',
    },
    {
        title: 'Available in Result',
        dataIndex: 'Available_result',
        key: 'Available_result',
      },
    {
      title: 'Status',
      dataIndex: 'status',
      key: 'status',
      render: (status) => (status ? 'Active' : 'Inactive'),
    },
    {
      title: 'Actions',
      key: 'actions',
      render: (_, record) => (
        <>
          <Button type="link" onClick={() => startEditing(record)}>
            Edit
          </Button>
          <Popconfirm
            title="Are you sure to delete this goal?"
            onConfirm={() => deleteRecord(record.ID)}
            okText="Yes"
            cancelText="No"
          >
            <Button type="link" danger>
              Delete
            </Button>
          </Popconfirm>
        </>
      ),
    },
  ];

  return (
    <>
      <Button type="primary" onClick={showModal} disabled={disabled}>
        Investment Goals
      </Button>
      <Modal
        title="Investment Goals"
        visible={isModalVisible}
        onOk={handleOk}
        onCancel={handleCancel}
        okText="OK"
        cancelText="Cancel"
        width={800}
      >
        <Button
          type="dashed"
          onClick={() => setIsAddingNew(true)}
          style={{ marginBottom: 16 }}
        >
          + Add New Goal
        </Button>

        {isAddingNew && (
          <div style={{ marginBottom: 16 }}>
            <Input
              placeholder="Description"
              value={newRecord.description}
              onChange={(e) => handleNewRecordChange('description', e.target.value)}
              style={{ marginBottom: 8 }}
            />
            <DatePicker
              placeholder="Select Date"
              value={newRecord.date}
              onChange={(date) => handleNewRecordChange('date', date)}
              style={{ marginBottom: 8 }}
            />
            <InputNumber
              placeholder="Amount"
              value={newRecord.amount}
              onChange={(value) => handleNewRecordChange('amount', value)}
              style={{ marginBottom: 8, width: '100%' }}
            />
            <InputNumber
              placeholder="Priority"
              value={newRecord.Priority}
              onChange={(value) => handleNewRecordChange('Priority', value)}
              style={{ marginBottom: 8, width: '100%' }}
            />
            <Button
              type="primary"
              onClick={saveNewRecord}
              disabled={!isSaveEnabled}
              style={{ marginTop: 8 }}
            >
              Save
            </Button>
          </div>
        )}

        {editingRecord && (
          <div style={{ marginBottom: 16 }}>
            <h3>Editing Record: {editingRecord.ID}</h3>
            <Input
              placeholder="Description"
              value={editingRecord.description}
              onChange={(e) => handleEditRecordChange('description', e.target.value)}
              style={{ marginBottom: 8 }}
            />
            <DatePicker
              placeholder="Select Date"
              value={editingRecord.date}
              onChange={(date) => handleEditRecordChange('date', date)}
              style={{ marginBottom: 8 }}
            />
            <InputNumber
              placeholder="Amount"
              value={editingRecord.amount}
              onChange={(value) => handleEditRecordChange('amount', value)}
              style={{ marginBottom: 8, width: '100%' }}
            />
            <InputNumber
              placeholder="Priority"
              value={editingRecord.Priority}
              onChange={(value) => handleEditRecordChange('Priority', value)}
              style={{ marginBottom: 8, width: '100%' }}
            />
            <Select
              value={editingRecord.status}
              onChange={(value) => handleEditRecordChange('status', value)}
              style={{ marginBottom: 8, width: '100%' }}
            >
              <Option value={1}>Active</Option>
              <Option value={0}>Inactive</Option>
            </Select>
            <Button
              type="primary"
              onClick={saveEditedRecord}
              style={{ marginTop: 8 }}
            >
              Save Changes
            </Button>
          </div>
        )}

        <Table
          dataSource={investmentGoalsData}
          columns={columns}
          rowKey="ID"
          loading={loading}
          pagination={false}
        />
      </Modal>
    </>
  );
};

export default InvestmentGoals;
