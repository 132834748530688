
///PortfolioModal.jsx
import React, { useEffect, useState, useContext, useCallback } from 'react';
import { Collapse, message, Modal, Table, Button, Tooltip, Select, Space, Spin, Tabs} from 'antd';
import api from '../functions/api';
import '../PortfolioModal.css';
import getUserId from '../functions/getUserId';
import { CloseOutlined } from '@ant-design/icons';
import ThreeTablesModal from "./SearchResultModal";
import { groupBy } from 'lodash';
import ToplineTable from './ToplineTable';
import TickerSearch from './TickerSearch';
import PreferencesModal from './PreferencesModal.jsx';
import usePortfolioData from '../functions/fetchPortfolioData';
import ESG_MainTable from './ESG_MainTable';
import useLocalStorage from '../functions/useLocalStorage';
import { isEqual } from 'lodash';
import { useSelector, useDispatch } from 'react-redux';
// import { getDataParameters } from '../redux/portfolioReducer';  // Adjust this import to your actual path
import { getPortfolioData } from '../redux/reducers/portfolioReducer';
import PF_Piecharts_Analysis from './PF_Piecharts_Analysis.jsx';
import PF_Analysis from './PF_Analysis.jsx';
import PF_Exposure from './PF_Exposure.jsx';
import Benchmark_YTD from './Benchmark_YTD'; 
import PF_Alerts from './PF_Alerts';  
import Portfolio_Performance from './Portfolio_Performance.jsx';


// Panel function from antd.
const { Panel } = Collapse;
const { Option } = Select;


const { TabPane } = Tabs;

const PortfolioModal = ({ portvisible, onClose }) => {
const userId = getUserId();
const { 
  portfolioData = [], 
  tableColumns = [], 
  categories: categoryOptions = [], 
  fetchPortfolioData,
  portfolioTopline = [],
  portfolioTickers = []
} = usePortfolioData(userId);

const dispatch = useDispatch();
const doesCategoryHaveAlert = (records) => {
  return records.some(record => tickerAlerts?.includes(record.Ticker));

};
const [isTableReady, setIsTableReady] = useState(false);

const fieldMetadata = useSelector(state => state?.portfolio?.data?.field_metadata);
const updatedCategoryRecords = useSelector(state =>state?.portfolio?.data?.updated_category_records);
const [esgData, setEsgData] = useState(null);
const dataparameters = useSelector(state => state.portfolio.parametersData);
// const allState = useSelector(state => state);
// console.log("All State: ", allState);
const harvestAlerts = useSelector(state => state?.portfolio?.data?.pf_alert);
const tickerAlerts = harvestAlerts?.map(alert => alert.ticker);
const pfDataAlerts = useSelector(state => state?.portfolio?.data?.pf_data_alert); // Replace 'selectPfDataAlerts' with your actual selector


// console.log("PORTFOLIOMODAL harvestAlerts: ", harvestAlerts)

const [token, setToken] = useLocalStorage('token', null);

const [isRefreshing, setIsRefreshing] = useState(false);
const [isDataFetched, setDataFetched] = useState(false);
// const [isLoading, setIsLoading] = useState(false);
const [completionCounter, setCompletionCounter] = useState(0);


const handleRefreshClick = async () => {
  console.log("Starting refresh...");
  setIsRefreshing(true); // Start spinner
  setDataFetched(true);
  const token = localStorage.getItem('token');

  try {
    await fetchAndUpdatePortfolioData();
    await dispatch(getPortfolioData(token)).unwrap();
    console.log("Dispatched getPortfolioData");
  } catch (error) {
    console.error("An error occurred:", error);
  } finally {
    // Note: Not stopping the spinner here anymore
  }
};

const fetchAndUpdatePortfolioData = useCallback(async () => {
  // setIsLoading(true); // Show spinner
  await fetchPortfolioData(stored_portfolioData, fieldMetadata);

  // setIsLoading(false); // Hide spinner after fetching data
  setCompletionCounter(0);
  setIsTableReady(true); // Set table ready to true after data is loaded
  console.log("Data loading complete, setIsTableReady(true)");
}, [fetchPortfolioData, portfolioTickers]);


// useEffect for updating data
useEffect(() => {
  if (portfolioData) {
    // console.log("Data updated, incrementing counter.");
    setCompletionCounter(prevCount => prevCount + 1);
    setDataFetched(false); // Reset for next refresh
  }
}, [portfolioData]);

// useEffect for stopping the spinner
useEffect(() => {
  if (completionCounter === 1) {
    // console.log("Counter is 1, stopping spinner.");
    setIsRefreshing(false);
    setCompletionCounter(0); // Reset counter for next refresh
  }
}, [completionCounter]);

useEffect(() => {

  if (isTableReady && !isRefreshing) {
    setIsRefreshing(false);
    console.log("Table is ready, stopping the spinner.");
  }
// }, [isTableReady]);
}, [isTableReady, isRefreshing]);
const [aggregatedData, setAggregatedData] = useState([]);



const stored_portfolioData = useSelector((state) => state.portfolio.data);
const fetchedEsgData = useSelector((state) => state.portfolio.esgData);
if (!isEqual(fetchedEsgData, esgData)) {
    setEsgData(fetchedEsgData);
    // console.log('fetchedEsgData:')
      }

const handleTickerSelect = (ticker, record) => {
  // Assuming that record contains all the necessary information
  const data = {
    UserID: userId, // Replace with the actual user ID
    Ticker: ticker,
    Company: record.Company,
    Position: record.Position,
    Close: record.Close,
    Value_eur: record.Value_eur,
  };
  api.post("/portfolio_view_update", data)
    .then((response) => {
      // console.log("Portfolio View Updated Successfully:", response.data);
      // console.log('Harvest Alerts:', harvestAlerts);
      message.success(`Ticker ${ticker} assigned to ${record.Company}`);
       })
    .catch((error) => {
      console.error("There was an error updating the portfolio view:", error);
    });
};



//for threeTablesModal
const [isModalVisible, setIsModalVisible] = useState(false);
const [selectedTicker, setSelectedTicker] = useState(null);

//preferencesModal
const [isPreferencesModalVisible, setIsPreferencesModalVisible] = useState(false);

const onRowClick = async (record) => {
  try {
    setSelectedTicker(record.Ticker); // save the ticker of the clicked row
    console.log("Selected row data", record)
    setIsModalVisible(true);
  } catch (error) {
    console.error("Failed to fetch data", error);
  }
}

const [editingCategoryId, setEditingCategoryId] = useState(null);
const [editingCategoryValue, setEditingCategoryValue] = useState(null);
// state to keep track of the currently editing record id.
const [editingRecordId, setEditingRecordId] = useState(null);
// state to temporarily store the new category value selected by the user.
const [newCategoryValue, setNewCategoryValue] = useState(null);

// Convert epoch to date and time
const epochToDate = (epoch) => {
  const date = new Date(epoch * 1000); // Convert to milliseconds
  return date.toLocaleString(); // Convert to local date and time string
}

// Get the latest date and time from portfolioData
const getLatestDate = (data) => {
  if (!data || !Array.isArray(data) || data.length === 0) return null;
  const latestEpoch = Math.max(...data.map(item => item.Epoch));
  return epochToDate(latestEpoch);
  }

const updateCategory = async (recordId, newCategoryValue) => {
  const userId = getUserId();
  // Get the record from portfolioData by recordId
  const record = portfolioData.find(item => item.id === recordId);
    if (!record) {
      console.error('Failed to find the record by recordId');
      return;}
    try {
      console.log('Updating category...', record);
      console.log('new category value...', newCategoryValue);
      await api.put('/category_update', { userId, ticker: record.Ticker, category: newCategoryValue, company: record.Company });

      message.success('Category updated successfully');
    } catch (error) {
      message.error('Failed to update category');
      console.error(error);
    }
  };

useEffect(() => {
  if (!portfolioData) return;
  
  const dataByCategories = groupBy(portfolioData, (item) => item.Category || 'Undefined');
  const data = Object.entries(dataByCategories).map(([category, records]) => ({
    category,
    records,
  }));

  if (!isEqual(data, aggregatedData)) {
    setAggregatedData(data);
    // console.log('Aggregated Data SET!');
  }

  setAggregatedData(data);
}, [portfolioData]);

useEffect(() => {
  if (editingCategoryId === null) return;

  // Change the category value of the record that has the editingCategoryId.
  setAggregatedData(prevAggregatedData => 
    prevAggregatedData.map(({ category, records }) => ({
      category,
      records: records.map(record =>
        record.id === editingCategoryId
          ? { ...record, Category: editingCategoryValue }
          : record
      ),
    }))
  );
  // Reset the editing states.
  setEditingCategoryId(null);
  setEditingCategoryValue(null);
}, [editingCategoryId, editingCategoryValue]);

return (
  <>
  <Modal
  open={portvisible}
  title={
    // <Spin spinning={isRefreshing}>
      <Space align="center" style={{ width: '100%', justifyContent: 'space-between' }}>
        Your Portfolio Status
        <Space>
          {isRefreshing ? (
            <Spin />
          ) : (
            <Button key="refresh" type="secondary" onClick={handleRefreshClick}>
              Refresh
            </Button>
          )}
          <CloseOutlined onClick={onClose} style={{ cursor: 'pointer' }} />
        </Space>

      </Space>
    // {/* </Spin> */}
  }
  onCancel={onClose}
  width={'80%'}
  closeIcon={null} // Disable the default close icon
  footer={[
      <Button key="submit" type="primary" onClick={onClose}>
        OK
      </Button>,
      isRefreshing ? <Spin /> : <Button key="refresh" type="secondary" onClick={handleRefreshClick}>Refresh</Button>,
    ]}

>
  <Tabs defaultActiveKey="1">
    <TabPane tab="Generic Performance" key="1">

    <h2>{getLatestDate(portfolioData)}</h2>
        <ToplineTable 
          tableLayout="fixed"
          portfolioData={portfolioData} 
          updatedCategoryRecords={updatedCategoryRecords} 
          portfolioTopline={Array.isArray(portfolioTopline) ? portfolioTopline : []} 
         />

        <Benchmark_YTD />

        <Portfolio_Performance />

    </TabPane>

    <TabPane tab="Category Performance" key="2">
    
    <h2>Performance per Category</h2>

        <Collapse defaultActiveKey={[]}>
                  {aggregatedData.map(({ category, records }, index) => {
                    
                    const categoryRecord = updatedCategoryRecords?.find(record => record.category.toLowerCase() === category.toLowerCase());       
                    const headerContent = categoryRecord 
                          ? 
                          (
                              <>
                               {doesCategoryHaveAlert(records) && <span style={{color: 'red', fontSize: '24px'}}>• </span>}
          
                                <b>{category}</b>  {categoryRecord.number_of_stocks} items, {categoryRecord.part_of_portfolio} % of portfolio, 
                                Performance: {categoryRecord.performance}%, 
                                {/* Yearly Dividend: € {categoryRecord.yearly_div},  */}
                                Benchmark:{categoryRecord.benchmark1}
                              </>
                            )
                          : <b>{category}</b>;
               
                return (
                  <Panel header={headerContent} key={index}>
                  <div style={{ position: 'relative', textAlign: 'left',overflowX: 'auto', maxWidth: '100%' }}>
                  <Table 
                            dataSource={records} 
                            columns={tableColumns.map(column => {
                              if (fieldMetadata && (column.dataIndex in fieldMetadata)) {
                                return {
                                  ...column,
                                  render: (value, record) => (
                                    <Tooltip 
                                    title={fieldMetadata[column.dataIndex].Tooltip} 
                                    mouseEnterDelay={1.5}>
                                      {value} 
                                    </Tooltip>
                                  ),
                                }
                              } 
                              
                              else if (column.dataIndex === 'Ticker') {
                                            return { 
                                              ...column,
                                              align: 'left',
                                              width: '10px',
                                              render: (value, record) => (
                                                <Tooltip title={record.Company} mouseEnterDelay={2.5} color='#1677ff' placement="lt">
                                                  <div style={{ position: 'relative', textAlign: 'left' }}>
                                                    <div style={{ zIndex: 0, position: 'relative', textAlign: 'left' }}>
                                                      {/* Indicator for ticker alerts */}
                                                      {tickerAlerts?.includes(value) && <span style={{color: 'red', fontSize: '24px', textAlign: 'left'}}>• </span>}
                                                      {pfDataAlerts?.some(alert => alert[2] === value) && <span style={{color: 'blue', fontSize: '24px', textAlign: 'left'}}>• </span>}
                                                      {value}
                                                      {/* Category Selection or Display */}
                                                      <p style={{ fontSize: 8, marginTop: '15px' }} onClick={e => e.stopPropagation()}>
                                                        {editingRecordId === record.id
                                                          ? (
                                                            // console.log('Category Options:', categoryOptions),
                                                            <Select
                                                                  defaultValue={record.Category}
                                                                  onChange={(selectedValue) => {
                                                                    console.log('Selected category value:', selectedValue); // Log to check the selected value
                                                                    setNewCategoryValue(selectedValue);
                                                                    setEditingCategoryId(record.id);
                                                                    setEditingCategoryValue(selectedValue);
                                                                    updateCategory(record.id, selectedValue); 
                                                                    setEditingRecordId(null);
                                                                    document.activeElement.blur(); 
                                                                  }}
                                                                  style={{ width: 120 }} 
                                                                  size="small"
                                                                >
                                                                  {categoryOptions.map((option, index) => (
                                                                    <Option value={option[0]} key={index}>{option[0]}</Option> // Use the first element of the array as the value
                                                                  ))}
                                                                </Select>

                                                          )
                                                          : !record.Ticker
                                                            ? (
                                                              <>
                                                                <TickerSearch 
                                                                  dropdownStyle={{ minWidth: '200px' }}
                                                                  onTickerSelect={(ticker) => handleTickerSelect(ticker, record)}
                                                                />
                                                                <span style={{ display: 'block', marginTop: '5px', cursor: 'pointer' }} onClick={() => setEditingRecordId(record.id)}>
                                                                  {record.Category}
                                                                </span>
                                                              </>
                                                            )
                                                            : (
                                                              <span style={{ cursor: 'pointer' }} onClick={() => setEditingRecordId(record.id)}>
                                                                {record.Category}
                                                              </span>
                                                            )
                                                        }
                                                      </p>
                                                    </div>
                                                  </div>
                                                </Tooltip>
                                              ),
                                            }
                                          }

                                else if (column.dataIndex === 'Company') {
                                  return { 
                                    ...column,
                                    width: '75px', 
                                    render: (value, record) => (
                                      <Tooltip title={value} mouseEnterDelay={1.5} color='#1677ff' placement="lt">
                                      <div style={{ position: 'relative', textAlign: 'left' }}>
                                        <div style={{ fontSize: 10, zIndex: 0, position: 'relative', textAlign: 'left' }}>
                                          {value && value.length > 12 ? value.substring(0, 30) + '..' : value}

                                          {/* Display default Account Value */}
                                          <p style={{ fontSize: 8, marginTop: '15px' }} onClick={e => e.stopPropagation()}>
                                            {record.account}
                                          </p>
                                        </div>
                                      </div>
                                      </Tooltip>
                                    ),
                                  }
                                }


                              else {
                                return column;
                              }
                            })}
                            rowKey="id" 
                            pagination={false}
                            rowClassName="tableRow"
                            expandable={{
                                expandedRowRender: (record) => {
                                  // Check for a true harvest alert for the current ticker
                                  if (harvestAlerts.some(alert => alert.ticker === record.Ticker)) {
                                    return (
                                      <p style={{ color: 'red' }}>
                                        {harvestAlerts.find(alert => alert.ticker === record.Ticker).pf_alert_txt}
                                      </p>
                                    );
                                  }
                                  return null;
                                },
                                rowExpandable: (record) => harvestAlerts?.some(alert => alert.ticker === record.Ticker)

                              }}
                            
                            onRow={(record) => ({onClick: () => onRowClick(record), })}
                            onHeaderRow={column => {
                              return {
                                onClick: () => { 
                                  console.log('Table header clicked!');
                                  setIsPreferencesModalVisible(true);
                                },
                              };
                            }}         
                          />
                          {isModalVisible && (
                          <ThreeTablesModal 
                            tickerValue={selectedTicker} 
                            open={isModalVisible} 
                            onClose={() => setIsModalVisible(false)} 
                            userId={userId} 
                          />
                        )}
                        </div>
                  </Panel>
                )
              })}
              </Collapse>
            </TabPane>

        <TabPane tab="Alerts" key="3">
        <PF_Alerts /> 
          </TabPane>

          <TabPane tab="ESG" key="4">
          {esgData && 
        <ESG_MainTable
          dataSet={esgData.data|| []}
          metadata={esgData.metadata|| []}
          dataparametersSet={dataparameters|| []}
          
        />}
          </TabPane>
          <TabPane tab="Pie Charts" key="5">
          <PF_Piecharts_Analysis/>
          </TabPane>
          <TabPane tab="Analysis" key="6">
          <PF_Analysis 
          />
          </TabPane>
          <TabPane tab="Exposure" key="7">
          <PF_Exposure 
          />
          </TabPane>
        </Tabs>
  </Modal>
  {isPreferencesModalVisible && (
      <PreferencesModal
        userId={userId}
        onClose={() => setIsPreferencesModalVisible(false)}
      />
    )}
  </>
);}

export default PortfolioModal;